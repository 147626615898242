import React from 'react';
import MuiButton from '@mui/material/Button';
import { COLOR_TEXT } from '../General/Variables';

const FloqButton = ({ children, onClick, extraStyle, variant = 'text' }) => {
    return (
        <MuiButton
            type="button"
            variant={variant}
            onClick={onClick}
            onKeyDown={e => {
                if (e.key === 'Enter') {
                    onClick();
                }
            }}
            sx={{
                minHeight: 40,
                textTransform: 'none',
                fontFamily: 'Roboto',
                fontSize: 15,
                fontWeight: 500,
                color: COLOR_TEXT,
                borderRadius: 6,
                backgroundColor: 'rgba(239,239,239,1)',
                '&:hover': {
                    backgroundColor: 'rgba(239,239,239,1)',
                    filter: 'brightness(90%)',
                },
                ...extraStyle,
            }}
        >
            {children}
        </MuiButton>
    );
};

export default FloqButton;
