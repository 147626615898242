import React from 'react';
import { styled as muiStyled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import MuiDialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components';

const StyledDialogTitle = muiStyled(MuiDialogTitle)(({ theme }) => ({
    height: 69,
    margin: 0,
    paddingTop: 24,
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 0,
}));

const closeButtonStyles = {
    position: 'absolute',
    right: 12,
    top: 12,
    color: theme => theme.palette.grey[700],
};

// To Do: use Material UI Typography with theme
const StyledTitle = styled.p`
    font-size: 21px;
    color: black;
    font-weight: bold;
    font-family: Cabin;
    margin-right: 24px;
    line-height: 24px;
`;

const DialogTitle = ({ children, onClose }) => {
    return (
        <StyledDialogTitle>
            <StyledTitle>{children}</StyledTitle>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    size="large"
                    style={closeButtonStyles}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </StyledDialogTitle>
    );
};

export default DialogTitle;
