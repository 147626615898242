import * as palette from './Variables';
import { lighten } from '@mui/material';

export const getBlackOrWhiteCalculated = color => {
    // Variables for red, green, blue values
    var r, g, b, hsp;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

        r = color[1];
        g = color[2];
        b = color[3];
    } else {
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
    }

    hsp = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    if (hsp > 0.5) {
        return palette.BLACK_87;
    } else {
        return palette.COLOR_WHITE;
    }
};

/*
Dark bg:
Default - 16% white
OnHover - 32% white
Active - 87% white bg, Icon color & text: Primary

Light bg:
Default - 8% black
OnHover - 16% black
Active - 24% black bg, Icon color & text: Black 87%

White bg: (if light)
Default - 8% black
OnHover - 4% black
Active - Primary + White 82% bg, Icon color & text: Primary
*/

export const getBgAndFontColorForAllDifferentStates = (theme, light) => {
    const contrastBlackOrWhite = getBlackOrWhiteCalculated(theme.primary);
    let defaultBg, hoverBg, activeBg, fontColor;

    if (contrastBlackOrWhite === palette.COLOR_WHITE) {
        defaultBg = 'rgba(255, 255, 255, 0.16)';
        hoverBg = 'rgba(255, 255, 255, 0.32)';
        activeBg = 'rgba(255, 255, 255, 0.87)';
        fontColor = theme.primary;
    } else {
        defaultBg = 'rgba(0, 0, 0, 0.08)';
        hoverBg = 'rgba(0, 0, 0, 0.16)';
        activeBg = 'rgba(0, 0, 0, 0.24)';
        fontColor = contrastBlackOrWhite;
    }

    if (light) {
        defaultBg = 'rgba(0, 0, 0, 0.08)';
        hoverBg = 'rgba(0, 0, 0, 0.04)';
        activeBg = lighten(theme.primary, 0.82);
        fontColor = theme.primary;
    }

    return { contrastBlackOrWhite, defaultBg, hoverBg, activeBg, fontColor };
};

export const convertHexToRGBA = (hexCode, opacity) => {
    let hex = hexCode.replace('#', '');

    if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity})`;
};
