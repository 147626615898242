import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import styled, { css } from 'styled-components';
import Button from '@mui/material/Button';

export const VideoSwitchWrapper = styled.div`
    color: white;
    width: 40px;
    height: 40px;
    position: absolute;
    left: 8px;
    top: 8px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.1s linear;
    background-color: ${props => props.backgroundColor};
    z-index: 1;
    :hover {
        background-color: ${props => props.backgroundColorHover};
    }
    ${props =>
        props.disabled &&
        css`
            cursor: not-allowed;
        `}
`;

export const VideoSwitchOutlinedWrapper = styled.div`
    color: ${props => props.backgroundColor};
    width: 40px;
    height: 40px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.1s linear;
    background-color: transparent;
    z-index: 1;
    :hover {
        background-color: ${props => props.backgroundColorHover};
        color: ${props => props.colorHover};
    }
    ${props =>
        props.disabled &&
        css`
            cursor: not-allowed;
        `}
`;

export const RedStyledChip = styled.p`
    color: #fff !important;
    font-family: Cabin, sans-serif;
    line-height: 1.2 !important;
    font-size: 10px !important;
    font-weight: bold !important;
    letter-spacing: 0.5px !important;
    background-color: #d50000;
    padding: 2px 0;
    margin: 0 0 4px 0 !important;
    text-align: center;
    border-radius: 8px;
    width: 80px;
`;

export const LightTooltip = ({ children, ...props }) => (
    <Tooltip
        {...props}
        sx={{
            '& .MuiTooltip-tooltip': {
                padding: '6px 8px',
                borderRadius: '4px',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                fontFamily: 'Roboto, sans-serif',
                fontSize: '10px',
                lineHeight: '1.2',
                textAlign: 'center',
                color: '#ffffff',
                wordWrap: 'break-word',
            },
        }}
    >
        {children}
    </Tooltip>
);

export const ShadowOverlay = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
    border-radius: 8px 8px 0 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(48, 48, 48, 0) 100%);
    transform: matrix(1, 0, 0, -1, 0, 0);
    z-index: 1;
`;

export const VirtualUserTitle = styled.span`
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin: 0 0 0 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const VirtualUserInfoWrapper = styled.div`
    display: flex;
    align-items: start;
    height: fit-content;
    position: absolute;
    left: 8px;
    bottom: 8px;
    width: 100%;
    z-index: 2;
`;

export const ContentModalContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px !important;
    font-family: Roboto, sans-serif !important;
    line-height: 24px !important;
    font-weight: 400 !important;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 8px;
    text-align: center;

    svg {
        border-radius: 50%;
        cursor: pointer;
        padding: 8px;
        font-size: 40px !important;
        font-weight: bold;
        background: #f1f1f3;
        margin-right: 16px;
    }
`;

export const StyledTextArea = styled.textarea`
    width: 100%;
    height: 104px;
    background-color: #efefef;
    border-radius: 6px;
    border: none;
    padding: 8px 12px;
    resize: none;
    margin: 24px 0 -24px 0;
    color: rgba(0, 0, 0, 0.87);
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
`;

const baseButtonStyles = ({ width, noTextCapitalize }) => ({
    borderRadius: '6px',
    width: width ? `${width}px` : '184px',
    height: '40px',
    textTransform: noTextCapitalize ? 'none' : 'capitalize',
    whiteSpace: 'nowrap',
    fontWeight: 500,
    lineHeight: '24px',
    fontSize: '15px',
});

export const CancelModalButton = ({ width, noTextCapitalize, ...props }) => (
    <Button
        {...props}
        sx={{
            ...baseButtonStyles({ width, noTextCapitalize }),
            backgroundColor: '#f0f1f3',
            color: '#000000',
            '&:hover': {
                backgroundColor: '#e0e1e3',
            },
        }}
    />
);

export const DestructiveModalButton = ({ width, noTextCapitalize, ...props }) => (
    <Button
        {...props}
        sx={{
            ...baseButtonStyles({ width, noTextCapitalize }),
            backgroundColor: '#ee1045',
            color: '#fff',
            '&:hover': {
                backgroundColor: '#d10035',
            },
        }}
    />
);

export const SaveModalButton = ({ width, noTextCapitalize, backgroundcolor, ...props }) => (
    <Button
        {...props}
        sx={{
            ...baseButtonStyles({ width, noTextCapitalize }),
            backgroundColor: backgroundcolor || '#00a480',
            color: '#fff',
            '&:hover': {
                backgroundColor: backgroundcolor || '#009470',
            },
        }}
    />
);
