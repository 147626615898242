import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import styled from 'styled-components';
import * as palette from './Variables';
import { getImageSourceAsync } from '../../services/api/db';
import { useMediaQuery } from '@mui/material';
import debounce from 'lodash/debounce';
import { useLayoutStore } from '../../stores/LayoutStore';
import { programmeViewTypes } from '../../scenes/Programme/constants';
const Content = styled.div`
    margin-top: ${props => (props.margintop ? props.margintop : 56)}px;
    overflow: hidden;
    ${props =>
        !props.notList &&
        `
            max-width: 1200px;
        `}
    width: 100%;
    ${props =>
        props.moveContentContainerUp &&
        `
        margin-top: ${
            props.hasTitle && props.hasSubtitle ? '-156px' : props.hasTitle ? '-122px' : '-64px'
        };
    `}
    border-radius: 8px;
    position: relative;
    @media only screen and (max-width: ${palette.MAX_PHONE}) {
        overflow: scroll;
    }
    ${props =>
        !props.notList &&
        `
    @media only screen and (min-width: 1360px) {
        overflow: scroll;
        left: 50%;
        transform: translateX(-50%);
        }
    `}
`;

const BackgroundImageWrapper = styled.div`
    position: relative;
    transition: opacity 0.3s;
    z-index: -1;
    width: 100%;
    aspect-ratio: 5 / 1;
    @media only screen and(max-width: ${palette.MAX_PHONE}) {
        display: none;
    }
`;

const BackgroundImagePlaceholder = styled.div`
    background-color: #e5e5e5;
`;

const BackgroundImage = styled.img`
    width: 100%;
    height: 100%;
`;

const TitleSection = styled.div`
    z-index: 1;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    margin-left: 16px;

    div:first-of-type {
        font-family: Cabin, sans-serif;
        font-size: 56px;
        font-weight: bold;
        line-height: 1;
    }

    div:nth-of-type(2) {
        font-family: Roboto, sans-serif;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.6;
        margin-top: 8px;
    }
`;

const ContentContainer = styled.div`
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 8px 0 rgba(0, 0, 0, 0.05),
        0 0 1px 0 rgba(0, 0, 0, 0.15);
    height: 100%;
    margin: 16px;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
`;

const BrandingBanner = ({
    notList,
    children,
    banner,
    viewType,
    navigationType,
    title,
    subtitle,
    disableBanner,
    withDynamicMarginTop = false,
    showInContentContainer = false,
    hideOnScroll = false,
}) => {
    const setBannerImageDisplayed = useLayoutStore(state => state.setBannerImageDisplayed);
    const setHasBrandingBanner = useLayoutStore(state => state.setHasBrandingBanner);
    const isPhone = useMediaQuery('(max-width:480px)');
    const backgroundImageRef = useRef(null);
    const wrapperRef = useRef(null);
    const [imageHeight, setImageHeight] = useState(null);
    const [backgroundUrl, setBackgroundUrl] = useState('');
    const [imageLoaded, setImageLoaded] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const marginTop = isPhone ? 0 : (imageHeight || 280) / 5;
    const containerTopOffset = withDynamicMarginTop && imageHeight;

    const scrollPositionWithMargin = scrollPosition + 50;
    const opacity = !hideOnScroll
        ? 1
        : scrollPositionWithMargin > 50
        ? 1 - Math.min(1, scrollPositionWithMargin / imageHeight)
        : 1;
    const imageIsDisplayed = opacity > 0;

    useEffect(() => {
        if (imageIsDisplayed) {
            setBannerImageDisplayed(true);
        } else {
            setBannerImageDisplayed(false);
        }
    }, [imageIsDisplayed]);

    useEffect(() => {
        if (wrapperRef.current) {
            const h = wrapperRef.current.getBoundingClientRect().width;
            if (h) {
                setImageHeight(wrapperRef.current.getBoundingClientRect().width / 5);
            }
        }
    }, [wrapperRef.current]);

    useEffect(() => {
        if (backgroundImageRef.current) {
            const h = backgroundImageRef?.current?.offsetHeight;
            if (h) {
                setImageHeight(backgroundImageRef?.current?.offsetHeight);
            }
        }
    }, [backgroundImageRef.current]);

    useEffect(() => {
        const loadImage = async () => {
            let img = '';
            try {
                img = await getImageSourceAsync(banner);
            } catch (e) {
                img = '';
            }

            if (img) {
                setBackgroundUrl(img);
                setHasBrandingBanner(true);
            }
        };
        if (banner) {
            loadImage();
        }
    }, [banner]);

    const renderTitle = useCallback(() => {
        return title ? (
            <TitleSection>
                {title && <div>{title}</div>}
                {subtitle && <div>{subtitle}</div>}
            </TitleSection>
        ) : null;
    }, [title, subtitle]);

    const contentProps = useMemo(() => {
        return {
            id: 'header-content-container',
            margintop: isPhone ? 16 : withDynamicMarginTop ? -marginTop : 16,
            imageheight: imageHeight,
            notList,
            navigationType,
            viewtype: viewType,
            moveContentContainerUp: !disableBanner && banner,
            hasTitle: title ? true : false,
            hasSubtitle: subtitle ? true : false,
            scrollOffset:
                containerTopOffset > marginTop + 16
                    ? 0
                    : imageHeight
                    ? imageHeight - marginTop - 16
                    : 0,
        };
    }, [
        isPhone,
        withDynamicMarginTop,
        marginTop,
        imageHeight,
        notList,
        navigationType,
        viewType,
        containerTopOffset,
        title,
        subtitle,
        disableBanner,
        banner,
    ]);

    const handleScroll = debounce(event => {
        const scrollPosition = event.target.scrollTop;

        setScrollPosition(scrollPosition);
    }, 10);

    const renderContent = useCallback(() => {
        return (
            <Content {...contentProps}>
                {renderTitle()}
                <ContentContainer style={{ paddingTop: title ? 16 : undefined }}>
                    {children}
                </ContentContainer>
            </Content>
        );
    }, [contentProps, renderTitle, children]);

    if (!banner || disableBanner) {
        if (showInContentContainer) {
            return renderContent();
        }
        return children;
    }

    return (
        <div
            ref={wrapperRef}
            style={{
                position: 'relative',
                height: 'calc(100vh - 64px)',
                overflowY: 'scroll',
            }}
            onScroll={handleScroll}
        >
            {!isPhone && (
                <BackgroundImageWrapper id={'header-image-container'} style={{ opacity: opacity }}>
                    {!imageLoaded && (
                        <BackgroundImagePlaceholder style={{ height: `${imageHeight}px` }} />
                    )}
                    {backgroundUrl.length > 0 && (
                        <BackgroundImage
                            src={backgroundUrl}
                            alt="Banner"
                            innerRef={backgroundImageRef}
                            onLoad={() => {
                                setImageLoaded(true);
                            }}
                            style={{ height: !imageLoaded ? '0px' : undefined }}
                        />
                    )}
                </BackgroundImageWrapper>
            )}
            {renderContent()}
        </div>
    );
};

export default BrandingBanner;
