import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import ScheduleIcon from '@mui/icons-material/Schedule';

import { useVMState } from '../../containers/main';

const SessionInfoTitle = styled.p`
    font-family: Cabin, sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 0;
`;

const SessionInfoWrapper = styled.div`
    padding: 10px 0;
    flex: 1;
    overflow: hidden;
`;

const SessionInfoChildWrapper = styled.div`
    padding: 10px 0;
`;

const SessionInfoChildDateTimeWrapper = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #43ba8f;
    flex-shrink: 0;
    display: flex;
    align-items: center;
`;

const SessionInfoChildDateTimeTextWrapper = styled.p`
    margin: 0 0 0 5px;
`;

const SessionInfoChildTittleWrapper = styled.p`
    font-family: Cabin, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    margin: 5px 0 0 0;
`;

const SessionInfoSubtitleTittleWrapper = styled.p`
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.54);
    margin: 10px 0 0 0;
`;

const HOUR_MINUTE_FORMAT = 'HH:mm';

const SessionInfo = () => {
    const [sessionInfo, setSessionInfo] = useState(null);
    const stateCtx = useVMState();

    useEffect(() => {
        (async () => {
            const sessionInfo = localStorage.getItem(
                `session:${stateCtx.externalObject.data.reference}`,
            );
            setSessionInfo(JSON.parse(sessionInfo));
        })();
    }, []);

    if (!sessionInfo) {
        return null;
    }

    return (
        <SessionInfoWrapper>
            <SessionInfoTitle>{sessionInfo.childrenTitle}</SessionInfoTitle>
            {sessionInfo.children &&
                sessionInfo.children.map((sessionInfoChild, index) => {
                    const momentStart = moment(sessionInfoChild.start);
                    const momentEnd = moment(sessionInfoChild.end);
                    return (
                        <SessionInfoChildWrapper key={index}>
                            <SessionInfoChildDateTimeWrapper>
                                <ScheduleIcon fontSize="small" />
                                <SessionInfoChildDateTimeTextWrapper>
                                    {`${momentStart.format('ddd')}, ${momentStart.format(
                                        HOUR_MINUTE_FORMAT,
                                    )} ${momentStart.format('A')} - ${momentEnd.format(
                                        HOUR_MINUTE_FORMAT,
                                    )} ${momentEnd.format('A')}`}
                                </SessionInfoChildDateTimeTextWrapper>
                            </SessionInfoChildDateTimeWrapper>
                            <SessionInfoChildTittleWrapper>
                                {sessionInfoChild.name}
                            </SessionInfoChildTittleWrapper>
                            <SessionInfoSubtitleTittleWrapper>
                                {sessionInfoChild.subNameList}
                            </SessionInfoSubtitleTittleWrapper>
                        </SessionInfoChildWrapper>
                    );
                })}
        </SessionInfoWrapper>
    );
};

export default SessionInfo;
