import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Question from '../components/Question';
import { useVMMutation, useVMState } from '../../virtualFeature/containers/main';
import QuestionModal from '../components/QuestionModal';
import Dropdown from '../components/Dropdown';
import ConfirmationDialog from '../../virtualFeature/components/common/ConfirmatonDialog';
import {
    createPoll,
    deletePoll,
    getPollById,
    getPollSetByExternalObject,
    reorderPollsInSet,
} from '../services/PollingService';

import { Button, FontIcon } from 'react-md';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import AddIcon from '@mui/icons-material/Add';
import ProgressBar from '../components/ProgressBar';
import VotingResults from '../components/VotingResults';
import VotingPreview from '../components/VotingPreview';
import {
    CancelModalButton,
    ContentModalContainer,
    DestructiveModalButton,
    SaveModalButton,
} from '../../virtualFeature/components/moderator/common/styles';
import Loader from '../../../../../components/General/Loader';
import { useTheme } from '../../../../../components/Theme/ThemeContext';

const Wrapper = styled.div``;

const FlexRow = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const SectionTitle = styled.div`
    color: rgba(0, 0, 0, 0.87) !important;
    width: 127px;
    height: 24px;
    font-family: Cabin, sans-serif;
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.43px;
    margin: 16px;
`;

const StyledIcon = styled(FontIcon)`
    color: #000 !important;
    font-size: 24px !important;
    cursor: pointer;
`;

const ButtonContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const PlayIcon = styled(PlayArrowIcon)`
    color: #fff;
    margin-right: 8px;
`;

const StyledStopIcon = styled(StopIcon)`
    color: #fff;
    margin-right: 8px;
`;

const StyledButton = styled(Button)`
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    color: #fff !important;
    height: 40px !important;
    width: 133px !important;
    background-color: ${props =>
        props.backgroundColor ? props.backgroundColor : '#1fa294'} !important;
    border: none;
    font-family: Cabin, sans-serif;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    border-radius: 6px !important;
    font-size: 14px !important;
    text-transform: none !important;
    padding: 0 0 0 10px !important;
    margin-right: 8px;
`;

const GreyStyledButton = styled(Button)`
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    color: rgba(0, 0, 0, 0.87) !important;
    height: 40px !important;
    width: 112px !important;
    background-color: #f1f1f3 !important;
    border: none;
    font-family: Cabin, sans-serif;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    border-radius: 6px !important;
    font-size: 14px !important;
    text-transform: none !important;
    padding: 0 0 0 10px !important;
`;

const ExtraStyledModalContentContainer = styled(ContentModalContainer)`
    padding: 0 25px;
    margin-bottom: -3px;
`;

const EmptyQuestionsWrapper = styled.div`
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-content: center;
`;

const EmptyQuestionsContainer = styled.div`
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const EmptyQuestionsTitle = styled.p`
    font-family: Cabin;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
`;

const EmptyQuestionsText = styled.p`
    font-family: Roboto;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 16px;
    margin-top: 8px;
`;

const Votes = () => {
    const [pollSet, setPollSet] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showTimer, setShowTimer] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [showQuestionModal, setShowQuestionModal] = useState(false);
    const [currentQuestionId, setCurrentQuestionId] = useState(null);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [showResetConfirmationModal, setShowResetConfirmationModal] = useState(false);
    const [warningMessage, setWarningMessage] = useState(null);
    const [showResults, setShowResults] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const mutationCtx = useVMMutation();
    const stateCtx = useVMState();
    const { theme } = useTheme();
    const { socket, sessionId, user, activePoll: currentlyActivePoll, externalObject } = stateCtx;
    const isVoteButtonOn = pollSet && pollSet.state === 'open' && currentlyActivePoll;

    const fetchPollSet = async () => {
        setIsLoading(true);
        const pollSet = await getPollSetByExternalObject(externalObject.data.id);

        setPollSet(pollSet);
        setIsLoading(false);
    };

    useEffect(() => {
        (async () => {
            await fetchPollSet();

            socket.emit('joinPolls', { objectId: externalObject.data.id });
            socket.on(`updatePollSet_${sessionId}`, fetchPollSet);
            socket.on(`pollAnswer_${sessionId}`, fetchPollSet);
            socket.on(`pollRefresh_${sessionId}`, fetchPollSet);
        })();
        return () => {
            if (socket && socket.removeAllListeners) {
                socket.removeAllListeners(`pollAnswer_${sessionId}`);
                socket.removeAllListeners(`pollRefresh_${sessionId}`);
                socket.removeAllListeners(`updatePollSet_${sessionId}`);
            }
        };
    }, []);

    const mapPolls = polls =>
        polls.map(poll => {
            const { votes } = pollSet;
            const { PollOptions: pollOptions } = poll;
            const options = pollOptions
                .sort((o1, o2) => o1.order - o2.order)
                .map(pollOption => pollOption.text);

            const totalVotes = pollOptions.reduce(
                (prevValue, option) => prevValue + votes[option.id],
                0,
            );

            return {
                id: poll.id,
                text: poll.title,
                pollSetId: poll.PollSetId,
                options,
                isExpanded: false,
                totalVotes,
                active: poll.active,
            };
        });

    useEffect(() => {
        if (!pollSet) {
            return;
        }

        const { Polls: polls } = pollSet;

        const activePoll = polls.find(poll => poll.active);
        mutationCtx.setActivePoll(activePoll);

        setQuestions(mapPolls(polls));

        setShowTimer(pollSet.showTimer);
        setLoading(false);
    }, [pollSet]);

    const isActiveQuestion = () => !!questions.find(question => question.active);

    const toggleVoteButton = () => {
        if (!isVoteButtonOn && !isActiveQuestion()) {
            setWarningMessage('Please select a question in order to start a vote.');
            return;
        }

        stateCtx.socket.emit('updatePollSet', {
            objectId: sessionId,
            externalObjectId: externalObject.data.id,
            state: isVoteButtonOn ? 'closed' : 'open',
        });

        socket.emit('setPollSet', {
            objectId: externalObject.data.id,
        });
    };

    const reorderPolls = async questions => {
        const payload = questions.map((question, index) => ({
            id: question.id,
            order: index,
        }));

        await reorderPollsInSet(payload);
    };

    const moveQuestionUp = async index => {
        if (index === 0) {
            return;
        }

        const questionsClone = [...questions];
        [questionsClone[index - 1], questionsClone[index]] = [
            questionsClone[index],
            questionsClone[index - 1],
        ];
        setQuestions(questionsClone);
        await reorderPolls(questionsClone);

        stateCtx.socket.emit('updatePollSet', {
            objectId: sessionId,
            externalObjectId: stateCtx.externalObject.data.id,
        });
    };

    const moveQuestionDown = async index => {
        if (index === questions.length - 1) {
            return;
        }

        const questionsClone = [...questions];
        [questionsClone[index + 1], questionsClone[index]] = [
            questionsClone[index],
            questionsClone[index + 1],
        ];
        setQuestions(questionsClone);
        await reorderPolls(questionsClone);

        stateCtx.socket.emit('updatePollSet', {
            objectId: sessionId,
            externalObjectId: stateCtx.externalObject.data.id,
        });
    };

    const setQuestionToDelete = id => {
        setCurrentQuestionId(id);
        setShowDeleteConfirmationModal(true);
    };

    const cancelDelete = () => {
        setCurrentQuestionId(null);
        setShowDeleteConfirmationModal(false);
    };

    const doDeletePoll = async () => {
        try {
            await deletePoll(currentQuestionId);
        } catch (e) {
            console.error(e);
        }

        setShowDeleteConfirmationModal(false);
        setCurrentQuestionId(null);
        stateCtx.socket.emit('updatePollSet', {
            objectId: sessionId,
            externalObjectId: stateCtx.externalObject.data.id,
        });
    };

    const setQuestionToEdit = questionId => {
        setCurrentQuestionId(questionId);
        setShowQuestionModal(true);
    };

    const closeQuestionForm = () => {
        setShowQuestionModal(false);
        setCurrentQuestionId(null);

        socket.emit('pollRefresh', { objectId: sessionId });
    };

    const setQuestionToResetVotes = id => {
        setCurrentQuestionId(id);
        setShowResetConfirmationModal(true);
    };

    const cancelResetVotes = () => {
        setCurrentQuestionId(null);
        setShowResetConfirmationModal(false);
    };

    const doResetVotes = async () => {
        const pollIndex = questions.findIndex(question => question.id === currentQuestionId);

        // Attendees have stored in local storage the ids of polls that they have already voted
        // Create new identical poll and destroy the old one, so the attendees can vote again
        const poll = await getPollById(currentQuestionId);

        const newPoll = await createPoll({
            title: poll.title,
            PollSetId: poll.PollSetId,
            seconds: poll.seconds,
            imageUrl: poll.imageUrl,
            useTimer: poll.useTimer,
            PollOptions: poll.PollOptions.map((option, index) => ({
                text: option.text,
                order: index,
                correct: option?.correct || false,
            })),
        });
        await deletePoll(currentQuestionId);

        const questionsClone = [...questions];
        questionsClone[pollIndex] = {
            active: false,
            id: newPoll.id,
            isExpanded: false,
            options: poll.PollOptions.map((option, index) => ({
                text: option.text,
                order: index,
                correct: option.correct,
            })),
            pollSetId: poll.PollSetId,
            text: poll.title,
        };

        await reorderPolls(questionsClone);
        setShowResetConfirmationModal(false);
        setCurrentQuestionId(null);

        let socketMessage = {
            objectId: sessionId,
            externalObjectId: stateCtx.externalObject.data.id,
        };

        if (currentlyActivePoll && currentlyActivePoll.id === poll.id) {
            socketMessage = {
                ...socketMessage,
                pollChange: {
                    id: newPoll.id,
                    active: true,
                },
            };
        }

        stateCtx.socket.emit('updatePollSet', socketMessage);
        socket.emit('setPollSet', {
            objectId: externalObject.data.id,
        });
    };

    const toggleActivity = async (id, noVoteResultsChange) => {
        if (isVoteButtonOn) {
            setWarningMessage(
                'You cannot mark this question as active because there is already a vote running.',
            );
            return;
        }

        const { Polls: polls } = pollSet;
        const poll = polls.find(p => p.id === id);
        const newActive = !poll.active;

        const newQuestionIndex = questions.findIndex(p => p.id === id);
        const previousActiveQuestionIndex = questions.findIndex(
            p => currentlyActivePoll && p.id === currentlyActivePoll.id,
        );
        const questionsClone = [...questions];

        if (previousActiveQuestionIndex !== -1) {
            const previousActiveQuestion = questions[previousActiveQuestionIndex];
            questionsClone.splice(previousActiveQuestionIndex, 1, {
                ...previousActiveQuestion,
                active: false,
            });
        }

        if (newQuestionIndex !== -1) {
            const newActiveQuestion = questions[newQuestionIndex];
            questionsClone.splice(newQuestionIndex, 1, {
                ...newActiveQuestion,
                active: true,
            });
            setQuestions(questionsClone);
        }

        stateCtx.socket.emit('updatePollSet', {
            objectId: sessionId,
            externalObjectId: externalObject.data.id,
            pollChange: {
                id: poll.id,
                active: newActive,
            },
        });

        socket.emit('setPollSet', {
            objectId: externalObject.data.id,
        });

        setShowResults(false);
    };

    const onClose = () => {
        const pollId = currentlyActivePoll.id;

        stateCtx.socket.emit('updatePollSet', {
            objectId: sessionId,
            externalObjectId: externalObject.data.id,
            state: 'closed',
            pollChange: {
                id: pollId,
                active: false,
            },
        });

        socket.emit('setPollSet', {
            objectId: externalObject.data.id,
        });

        setShowResults(false);
    };

    const viewResults = async questionId => {
        if (
            !currentlyActivePoll ||
            (currentlyActivePoll && currentlyActivePoll.id !== questionId)
        ) {
            await toggleActivity(questionId, true);
            setShowResults(true);
        } else {
            setShowResults(!showResults);
        }
    };

    const stopVoting = () => {
        setShowResults(false);

        stateCtx.socket.emit('updatePollSet', {
            objectId: sessionId,
            externalObjectId: externalObject.data.id,
            state: 'closed',
        });

        socket.emit('setPollSet', {
            objectId: externalObject.data.id,
        });
    };

    const renderButtons = () => {
        if (currentlyActivePoll) {
            return (
                <ButtonContainer>
                    <StyledButton
                        flat
                        onClick={
                            isVoteButtonOn
                                ? () => stopVoting(currentlyActivePoll.id)
                                : toggleVoteButton
                        }
                        backgroundColor={theme.primary}
                    >
                        {isVoteButtonOn ? <StyledStopIcon /> : <PlayIcon />}
                        {isVoteButtonOn ? 'Stop voting' : 'Start voting'}
                    </StyledButton>
                    {!isVoteButtonOn && (
                        <GreyStyledButton onClick={onClose}>Close vote</GreyStyledButton>
                    )}
                </ButtonContainer>
            );
        }

        return null;
    };

    const isPollSetOpen = pollSet?.state === 'open';
    const showVotingResults =
        (currentlyActivePoll && isPollSetOpen) || (currentlyActivePoll && showResults);
    const showVotingPreview = currentlyActivePoll && !isPollSetOpen && !showResults;
    if (loading) {
        return null;
    }
    return (
        <Wrapper className="eureka-react">
            {!isLoading ? (
                <>
                    {currentlyActivePoll && isPollSetOpen && currentlyActivePoll.useTimer && (
                        <ProgressBar
                            pollSet={pollSet}
                            activePoll={currentlyActivePoll}
                            activeColor={theme.primary ?? '#1fa294'}
                            stopVoting={stopVoting}
                        />
                    )}
                    {renderButtons()}
                    {showVotingPreview && (
                        <VotingPreview
                            imageUrl={currentlyActivePoll ? currentlyActivePoll.imageUrl : ''}
                            activePoll={currentlyActivePoll}
                            pollSet={pollSet}
                        />
                    )}
                    {showVotingResults && (
                        <VotingResults
                            activePoll={currentlyActivePoll}
                            imageUrl={currentlyActivePoll ? currentlyActivePoll.imageUrl : ''}
                            isCurrentUserActive
                            pollSet={pollSet}
                            nonVirtual
                        />
                    )}
                </>
            ) : (
                <Loader />
            )}

            <FlexRow>
                <SectionTitle>Questions</SectionTitle>
                <StyledIcon onClick={() => setShowQuestionModal(true)}>add</StyledIcon>
            </FlexRow>
            {questions.map((question, index) => (
                <Question
                    index={index}
                    key={`question-${index}`}
                    question={question}
                    onClick={() => toggleActivity(question.id)}
                    actionComponent={
                        <Dropdown
                            resultsNumber={question.totalVotes}
                            id={`question-${index}`}
                            key={`question-dropdown-${index}`}
                            active={question.active}
                            index={index}
                            isLastInList={index === questions.length - 1}
                            onMoveUp={() => moveQuestionUp(index)}
                            onMoveDown={() => moveQuestionDown(index)}
                            onViewResults={() => viewResults(question.id)}
                            onResetVotes={() => setQuestionToResetVotes(question.id)}
                            onEdit={() => setQuestionToEdit(question.id)}
                            onDelete={() => setQuestionToDelete(question.id)}
                        />
                    }
                />
            ))}
            {questions.length === 0 && (
                <EmptyQuestionsWrapper>
                    <EmptyQuestionsContainer>
                        <EmptyQuestionsTitle>No questions created</EmptyQuestionsTitle>
                        <EmptyQuestionsText>
                            Add a new question by clicking on the “Add” icon in the top right or
                            click the button below
                        </EmptyQuestionsText>
                        <StyledButton flat onClick={() => setShowQuestionModal(true)}>
                            <AddIcon />
                            &nbsp; Add question
                        </StyledButton>
                    </EmptyQuestionsContainer>
                </EmptyQuestionsWrapper>
            )}
            <QuestionModal
                open={showQuestionModal}
                handleClose={closeQuestionForm}
                pollSetId={pollSet ? pollSet.id : null}
                questionId={currentQuestionId}
                hideCheckmarkBox
            />
            <ConfirmationDialog
                open={showDeleteConfirmationModal}
                title="Delete poll"
                titleIcon="delete_forever"
                iconColor="rgba(0, 0, 0, 0.87)"
                iconWrapperColor="#EFEFEF"
                withCloseButton
                content={
                    <ExtraStyledModalContentContainer>
                        Are you sure you want to delete this poll?
                    </ExtraStyledModalContentContainer>
                }
                onClose={cancelDelete}
                buttons={[
                    <DestructiveModalButton key="DPCD" flat onClick={doDeletePoll}>
                        Delete
                    </DestructiveModalButton>,
                    <CancelModalButton key="DPCC" flat onClick={cancelDelete}>
                        Cancel
                    </CancelModalButton>,
                ]}
            />
            <ConfirmationDialog
                open={showResetConfirmationModal}
                title="Clear votes"
                titleIcon="restart_alt"
                iconColor="rgba(0, 0, 0, 0.87)"
                iconWrapperColor="#EFEFEF"
                withCloseButton
                content={
                    <ExtraStyledModalContentContainer>
                        Are you sure you want to clear the answers? All votes will be deleted.
                    </ExtraStyledModalContentContainer>
                }
                onClose={cancelResetVotes}
                onNo={cancelResetVotes}
                onYes={doResetVotes}
                buttons={[
                    <SaveModalButton key="CAAC" flat onClick={doResetVotes}>
                        Yes
                    </SaveModalButton>,
                    <CancelModalButton key="CAACN" flat onClick={cancelResetVotes}>
                        Cancel
                    </CancelModalButton>,
                ]}
            />
            <ConfirmationDialog
                open={!!warningMessage}
                title="Warning"
                iconColor="rgba(0, 0, 0, 0.87)"
                iconWrapperColor="#EFEFEF"
                withCloseButton
                onClose={() => setWarningMessage(null)}
                content={
                    <ExtraStyledModalContentContainer>
                        {warningMessage}
                    </ExtraStyledModalContentContainer>
                }
                onOk={() => setWarningMessage(null)}
            />
        </Wrapper>
    );
};

export default Votes;
