import React, { useState, useEffect } from 'react';
import Expanded from './Expanded';
import useTagFilters from '../../hooks/useTagFilters';

const Filter = ({ selectedFilters, action, filterTypes, closeFilter }) => {
    const [selectedFilterState, setSelectedFilterState] = useState(selectedFilters?.filters || {});
    const [filterText, setFilterText] = useState(selectedFilters?.text || '');
    const [myProgram, setMyProgram] = useState(selectedFilters?.myProgram || 'all');

    const filters = useTagFilters(filterTypes);

    useEffect(() => {
        action({
            myProgram,
            filters: selectedFilterState,
            text: filterText,
        });
    }, []);

    const actionFilters = filterData => {
        setMyProgram(filterData.myProgram);
        setSelectedFilterState(filterData.filters);
        setFilterText(filterData.text);

        action({
            myProgram: filterData.myProgram,
            filters: filterData.filters,
            text: filterData.text,
        });
    };

    return (
        <Expanded
            filters={filters}
            selectedFilters={selectedFilterState}
            filterText={filterText}
            action={actionFilters}
            closeFilter={closeFilter}
            myProgram={myProgram}
        />
    );
};

export default Filter;
