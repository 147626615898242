import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

export const ProfileImageWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
`;

export const VirtualUserInfoWrapper = styled.div`
    position: absolute;
    bottom: 12px !important;
    left: 14px !important;
    height: 20px;
    width: 85%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    z-index: 20;
`;

export const VirtualUserTitle = styled.p`
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin: 0 0 0 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const ActionButtonsWrapper = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    ${props => !props.transparent && 'background-color: rgba(0, 0, 0, 0.6);'};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 15;
    border-radius: 8px;
`;

export const AccountCircleIconWrapper = styled.div`
  font-size: 42px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: rgba(255, 255, 255, 0.15);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('${props => props.imageUrl}');
  width: ${props => (props.width ? props.width : '56px')};
  height: ${props => (props.width ? props.width : '56px')};
  border-radius: 50%;
`;

export const LightTooltip = muiStyled(Tooltip)(() => ({
    tooltip: {
        borderRadius: '4px',
        backgroundColor: '#343434',
        fontFamily: 'Roboto, sans-serif',
        fontSize: '11px',
        textAlign: 'center',
        color: '#ffffff',
    },
}));

export const styles = {
    tooltipContainer: {
        padding: '6px 8px',
        borderRadius: '4px',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        fontFamily: 'Roboto, sans-serif',
        fontSize: '10px',
        lineHeight: '1.2',
        textAlign: 'center',
        color: '#ffffff',
    },
    buttonStyle: {
        display: 'flex',
        height: '40px',
        width: '40px',
        borderRadius: '20px',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

export const viewProfileTooltipMessage = 'View user profile';
