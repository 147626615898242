import React from 'react';
import styled from 'styled-components';

import entities from '../../constants/entities';
import PhoneLinkIcon from '@mui/icons-material/Phonelink';
import GroupWorkIcon from '@mui/icons-material/GroupWorkOutlined';
import strings from '../footer/constants';
import { useVMState } from '../../containers/main';

const WarningCardWrapper = styled.div`
    width: ${props => props.width}px;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    padding: 12px;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    position: relative;
    margin-right: 7px;
    margin-left: auto;
    cursor: default;

    &:after {
        content: '';
        width: 0;
        height: 0;
        bottom: 33.5%;
        right: -6px;
        position: absolute;
        border: 7px solid #fff;
        transform: rotate(135deg);
        transition: border 0.4s ease-in-out;
        box-shadow: -1px -2px 2px -1px rgba(0, 0, 0, 0.1);
    }
`;

const ActiveWrapper = styled.span`
    color: #d50000;
`;

const SpeakerAndSliderIcon = styled(PhoneLinkIcon)`
    color: #d50000 !important;
    width: 16px !important;
    height: 16px !important;
    margin-right: 4px;
    transform: translateY(4px);
`;

const PanelDiscussionIcon = styled(GroupWorkIcon)`
    color: #d50000 !important;
    width: 16px !important;
    height: 16px !important;
    margin-right: 4px;
    transform: translateY(4px);
`;

const { speakerAndSlider, panelDiscussion } = entities.virtualEventSessionEntity.mode;

export default ({ isMicrophoneOn, isVideoOn, isPresenter }) => {
    const stateCtx = useVMState();
    const { virtualEventSession, virtualEventUser } = stateCtx;
    const virtualEventSessionStatus = virtualEventSession.data.status;
    const virtualEventSessionMode = virtualEventSession.data.mode;
    const { status } = entities.virtualEventSessionEntity;
    const isActive = virtualEventUser.data.isActive;
    const isPrerecording = isPresenter && virtualEventUser.data.isPrerecording;

    let text;
    let width = 0;

    if (virtualEventSessionMode === speakerAndSlider) {
        !isActive &&
            virtualEventSessionStatus === status.broadcasting &&
            isMicrophoneOn &&
            (text = strings.moderator.TEXT_BROADCASTING_MICROPHONE_ON) &&
            (width = 152);
        !isActive &&
            virtualEventSessionStatus === status.broadcasting &&
            !isMicrophoneOn &&
            (text = strings.moderator.TEXT_BROADCASTING_MICROPHONE_OFF) &&
            (width = 222);
        isActive &&
            virtualEventSessionStatus === status.broadcasting &&
            !isVideoOn &&
            !isMicrophoneOn &&
            (text = strings.moderator.TEXT_ACTIVE_NO_STREAMS) &&
            (width = 255);
        isActive &&
            virtualEventSessionStatus === status.broadcasting &&
            isVideoOn &&
            !isMicrophoneOn &&
            (text = strings.moderator.TEXT_ACTIVE_NO_MICROPHONE) &&
            (width = 231);
        isActive &&
            virtualEventSessionStatus === status.broadcasting &&
            !isVideoOn &&
            isMicrophoneOn &&
            (text = strings.moderator.TEXT_ACTIVE_NO_CAMERA) &&
            (width = 207);
        isActive &&
            virtualEventSessionStatus === status.broadcasting &&
            isVideoOn &&
            isMicrophoneOn &&
            (text = ' ') &&
            (width = 152);
    }
    if (virtualEventSessionMode === panelDiscussion) {
        isVideoOn && isMicrophoneOn && (text = ' ') && (isActive ? (width = 188) : (width = 92));
        !isVideoOn &&
            isMicrophoneOn &&
            (text = strings.moderator.TEXT_ACTIVE_NO_CAMERA) &&
            (isActive ? (width = 247) : (width = 183));
        isVideoOn &&
            !isMicrophoneOn &&
            (text = strings.moderator.TEXT_ACTIVE_NO_MICROPHONE) &&
            (isActive ? (width = 251) : (width = 183));
        !isVideoOn &&
            !isMicrophoneOn &&
            (text = strings.moderator.panelDiscussion.TEXT_ACTIVE_NO_STREAMS) &&
            (isActive ? (width = 319) : (width = 271));
    }

    if (virtualEventSessionStatus !== status.broadcasting) {
        isMicrophoneOn &&
            (text = strings.moderator.TEXT_NOT_BROADCASTING_MICROPHONE_ON) &&
            (width = 150);
        !isMicrophoneOn &&
            (text = strings.moderator.TEXT_NOT_BROADCASTING_MICROPHONE_OFF) &&
            (width = 222);
    }

    if (isPrerecording && virtualEventSessionStatus === status.closed) {
        (text = strings.presenter.preRecording) && (width = 222);
    }

    if (!text && text !== ' ') {
        return null;
    }

    return (
        <WarningCardWrapper width={width}>
            {isActive &&
                virtualEventSessionStatus === status.broadcasting &&
                virtualEventSessionMode === speakerAndSlider && (
                    <div>
                        <SpeakerAndSliderIcon />
                        <ActiveWrapper>{strings.moderator.TEXT_ACTIVE}</ActiveWrapper>
                        {text}
                    </div>
                )}
            {!isActive &&
                virtualEventSessionStatus === status.broadcasting &&
                virtualEventSessionMode === speakerAndSlider && <div>{text}</div>}
            {isActive &&
                virtualEventSessionStatus === status.broadcasting &&
                virtualEventSessionMode === panelDiscussion && (
                    <div>
                        <PanelDiscussionIcon />
                        <ActiveWrapper>{`Panel - ${strings.moderator.TEXT_ACTIVE}`}</ActiveWrapper>
                        {text}
                    </div>
                )}
            {!isActive &&
                virtualEventSessionStatus === status.broadcasting &&
                virtualEventSessionMode === panelDiscussion && (
                    <div>
                        <PanelDiscussionIcon />
                        <ActiveWrapper>Panel</ActiveWrapper>
                        {text}
                    </div>
                )}
            {virtualEventSessionStatus !== status.broadcasting && <div>{text}</div>}
        </WarningCardWrapper>
    );
};
