import { create } from 'zustand';
import { getInstitutionProposals } from '../services/api/eureka';
import { makeItemAsync } from '../services/api/data';

export const useInstitutionProposalsStore = create((set, get) => ({
    proposals: [],
    institutions: [],
    fetchProposals: async () => {
        const proposals = await getInstitutionProposals();
        const institutionsPromises = [];
        proposals.forEach(proposal => {
            institutionsPromises.push(makeItemAsync(proposal.institutionId, 'institution'));
        });
        const institutions = await Promise.all(institutionsPromises);

        set({ proposals, institutions });
        return proposals;
    },
}));

export const useProposalByInstitutionId = institutionId =>
    useInstitutionProposalsStore(state =>
        state.proposals.find(proposal => proposal.institutionId === institutionId),
    );
