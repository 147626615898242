import React from 'react';
import { Stack, Typography, Card, Box, Divider } from '@mui/material';
import BasicInstitutionForm from './BasicInstitutionForm';
import FloqImageUploader from '../../../components/FloqImageUploader/';
import { useFormikContext } from 'formik';

const InstitutionInfoTab = () => {
    const formik = useFormikContext();

    return (
        <Stack spacing={3} maxWidth={1440} marginX="auto">
            <Card sx={{ boxShadow: 2 }}>
                <Stack>
                    <Typography variant="h3" marginBottom={0} paddingY={2} paddingX={3}>
                        General
                    </Typography>
                    <Divider />
                    <Box padding={3}>
                        <BasicInstitutionForm formik={formik} />
                    </Box>
                </Stack>
            </Card>
            <Card sx={{ boxShadow: 2, position: 'relative', overflow: 'visible' }}>
                <Stack>
                    <Typography variant="h3" marginBottom={0} paddingY={2} paddingX={3}>
                        Layout
                    </Typography>
                    <Divider />
                    <Box padding={3}>
                        <Typography variant="h4">Card image</Typography>
                        <Stack width={'393px'} height={'196px'} marginBottom={'24px'}>
                            <FloqImageUploader
                                label="Card image"
                                tag="institution-proposal"
                                maxMB={2}
                                initialFrameHeight={196}
                                imageUrl={formik.values.cardImageUrl}
                                outputDimensions={{ x: 1200, y: 600 }}
                                onChange={images => {
                                    if (images.length && images[0].imageUrl) {
                                        formik.setFieldValue('cardImageUrl', images[0].imageUrl);
                                    } else {
                                        formik.setFieldValue('cardImageUrl', '');
                                    }
                                }}
                            ></FloqImageUploader>
                        </Stack>
                    </Box>
                    <Divider />
                    <Box padding={3}>
                        <Typography variant="h4">Side panel background</Typography>
                        <Stack width={'393px'} height={'196px'} marginBottom={'24px'}>
                            <FloqImageUploader
                                label="Side panel background"
                                tag="institution-proposal"
                                maxMB={2}
                                initialFrameHeight={196}
                                imageUrl={formik.values.backgroundImageUrl}
                                outputDimensions={{ x: 1200, y: 600 }}
                                onChange={images => {
                                    if (images.length && images[0].imageUrl) {
                                        formik.setFieldValue(
                                            'backgroundImageUrl',
                                            images[0].imageUrl,
                                        );
                                    } else {
                                        formik.setFieldValue('backgroundImageUrl', '');
                                    }
                                }}
                            ></FloqImageUploader>
                        </Stack>
                    </Box>
                    <Box padding={3}>
                        <Typography variant="h4">Page banner</Typography>
                        <Stack width={'393px'} height={'78px'} marginBottom={'24px'}>
                            <FloqImageUploader
                                label="Page banner"
                                tag="institution-proposal"
                                maxMB={2}
                                initialFrameHeight={78}
                                hideInputIcon
                                imageUrl={formik.values.bannerImageUrl}
                                outputDimensions={{ x: 1920, y: 384 }}
                                onChange={images => {
                                    if (images.length && images[0].imageUrl) {
                                        formik.setFieldValue('bannerImageUrl', images[0].imageUrl);
                                    } else {
                                        formik.setFieldValue('bannerImageUrl', '');
                                    }
                                }}
                            ></FloqImageUploader>
                        </Stack>
                    </Box>
                </Stack>
            </Card>
        </Stack>
    );
};

export default InstitutionInfoTab;
