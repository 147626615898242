import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MicOffIcon from '@mui/icons-material/MicOff';

import StreamPlayer from '../../common/StreamPlayer';
import entities from '../../../constants/entities';
import { useVMState, useVMVolumeState } from '../../../containers/main';
import VoiceIndicator from '../../common/VoiceIndicator';
import { ShadowOverlay, VirtualUserTitle, VirtualUserInfoWrapper } from '../common/styles';

const ProfileImageWrapper = styled.div`
    background-color: #666666;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 0;
    ${props => props.bottomborder && 'border-bottom: 1px solid white;'};
    ${props => props.rightborder && 'border-right: 1px solid white;'};
    ${props => props.leftborder && 'border-left: 1px solid white;'};
    ${props => props.topborder && 'border-top: 1px solid white;'};
`;

const StyledMicOffIcon = styled(MicOffIcon)`
    color: #ffffff;
    width: 16px !important;
    height: 16px !important;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const IconsWrapper = styled.div`
    position: absolute;
    bottom: 0 !important;
    left: 0 !important;
    height: 20px;
    width: 85%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const AccountCircleIconWrapper = styled.div`
    font-size: 48px;
    color: #ffffff;
    opacity: ${props => (props.imageUrl ? 1 : 0.2)};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('${props => !props.video && props.imageUrl}');
    width: 48px;
    height: 48px;
    border-radius: 50%;
    ${props =>
        props.video &&
        css`
            opacity: 1;
            height: 100%;
            width: 100%;
            .stream-player {
                height: 100%;
                width: 100%;
            }
        `}
`;

const ExtraStyledShadowOverlay = styled(ShadowOverlay)`
    border-radius: 0;
`;

export default ({
    user,
    stream,
    isPanelDiscussion,
    isMicrophoneOn,
    isVideoOn,
    index,
    columnNumber,
}) => {
    const stateCtx = useVMState();
    const volumeStateCtx = useVMVolumeState();

    const { currentUser } = stateCtx;
    const { volumeIndicators } = volumeStateCtx;

    const isHost = user.role === entities.virtualEventUserEntity.role.roundTableHost;

    const shouldAddBorderRight = !((index + 1) % columnNumber === 0);
    const shouldAddBorderLeft = !(index % columnNumber === 0);
    const shouldAddBorderBottom = !(index / columnNumber === columnNumber - 1);
    const shouldAddBorderTop = !(index < columnNumber);
    const volumeLevel = useMemo(() => {
        const indicator = volumeIndicators.find(vInd => stream.getId() === vInd.uid);

        return indicator ? indicator.level : 0;
    }, [volumeIndicators]);

    return (
        <ProfileImageWrapper
            rightborder={shouldAddBorderRight ? 1 : 0}
            bottomborder={shouldAddBorderBottom ? 1 : 0}
            leftborder={shouldAddBorderLeft ? 1 : 0}
            topborder={shouldAddBorderTop ? 1 : 0}
        >
            <AccountCircleIconWrapper video={isVideoOn} imageUrl={user.imageUrl}>
                {!user.imageUrl && !isVideoOn && <AccountCircleIcon fontSize="inherit" />}
                {stream && (
                    <StreamPlayer
                        key={stream.getId()}
                        className={'main-stream-profile'}
                        isVideoOn={isVideoOn}
                        local={true}
                        muted={currentUser.id === stream.getId()}
                        stream={stream}
                        uid={stream.getId()}
                        domId={`stream-player-${stream.getId()}`}
                        isPanelDiscussion={isPanelDiscussion}
                    />
                )}
            </AccountCircleIconWrapper>
            <VirtualUserInfoWrapper data-qa={`panel-discussion-name-paragraph-${user.id}`}>
                <IconsWrapper>
                    {isMicrophoneOn && <VoiceIndicator level={volumeLevel} small />}
                    {!isMicrophoneOn && <StyledMicOffIcon />}
                    <VirtualUserTitle>
                        {user.firstName} {user.lastName} {isHost ? '(Host)' : ''}
                    </VirtualUserTitle>
                </IconsWrapper>
            </VirtualUserInfoWrapper>
            <ExtraStyledShadowOverlay />
        </ProfileImageWrapper>
    );
};
