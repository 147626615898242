import React, { useEffect } from 'react';
import Stack from '@mui/material/Stack';
import { useInstitutionProposalsStore } from '../../../stores/InstitutionProposalStore';
import InstitutionListItem from '../../../components/ObjectListItem/Institution';
import NavigationLink from '../../../components/Navigation/NavigationLink';

const InstitutionsList = () => {
    const institutionsFromProposals = useInstitutionProposalsStore(state => state.institutions);
    const fetchProposals = useInstitutionProposalsStore(state => state.fetchProposals);

    useEffect(() => {
        fetchProposals();
    }, []);

    return (
        <Stack>
            {institutionsFromProposals.map(institution => (
                <NavigationLink path={`/institution-proposal/${institution.id}`}>
                    <InstitutionListItem institution={institution} showBookmark={false} />
                </NavigationLink>
            ))}
        </Stack>
    );
};

export default InstitutionsList;
