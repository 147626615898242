import React, { useState, useEffect } from 'react';
import { Button, FontIcon } from 'react-md';
import styled from 'styled-components';
import MenuButton from 'react-md/lib/Menus/MenuButton';
import AgoraRTC from 'agora-rtc-sdk-ng';
import CheckFontIcon from '@mui/icons-material/Check';

import * as palette from '../../../../components/General/Variables';
import { useGlobalMutation, useGlobalState } from '../../../../utils/container';
import { PrimaryButton } from '../../../../components/General/Buttons';
import { isCameraAllowed, isMicrophoneAllowed } from '../../../../utils/useDevices';
import { getString } from '../../../../services/api/store';
import { TOAST_TYPES } from '../common/toasts';
import Auth from '../../../../services/api/auth';
import { TEN_MILL } from '../../constants/values';
import MutedBySystemIcon from '../../../../components/Icons/MutedBySystemIcon';

const Wrapper = styled.div`
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
    display: flex;
    flex-flow: row;
    height: 80px;
    margin-top: 80px;
    left: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 15;
    padding: 0 24px;
`;

const Presentation = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    img {
        width: 24px;
        height: 24px;
    }
`;

const CenterContainer = styled.div`
    display: flex;
`;

const SettingsWrapper = styled.div`
    #settings-list {
        display: flex;
        flex-direction: column;
    }

    [id*='-toggle'] {
        background: #fff !important;
        color: #000 !important;
        box-shadow: none !important;
    }

    #settings-toggle {
        min-width: 40px !important;
        box-sizing: border-box;
        max-width: 40px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
    }

    .md-list-tile {
        padding-left: 16px !important;
    }
`;

const OptionsButton = styled.i`
    cursor: pointer;
    margin: 0 0 0 10px;
`;

const styles = {
    buttonStyle: {
        height: '48px',
        width: '48px',
        border: 'solid 1px #cccccc',
    },
};

const Footer = props => {
    const mutationCtx = useGlobalMutation();
    const stateCtx = useGlobalState();
    const [isMicOn, setIsMicOn] = useState(true);
    const [isVidOn, setIsVidOn] = useState(true);
    const {
        mutedBySystem,
        screenSharing,
        screenShareStream,
        shareType,
        config,
        cameraList,
        microphoneList,
        streams,
    } = stateCtx;

    const { id, timeslotId } = props.currentVirtualEventUser;
    const isScreenSharing = screenShareStream && shareType === 'screen';

    const VIDEO_SOURCE_TEXT = getString('virtualRoomVideoSourceTip') || 'Video source';
    const AUDIO_SOURCE_TEXT = getString('virtualRoomAudioSourceTip') || 'Audio source';
    const LEAVE_MEETING_BUTTON_TEXT = getString('virtualRoomLeaveMeetingButton') || 'Leave meeting';
    const SHARE_SCREEN_BUTTON_TEXT = getString('virtualRoomShareScreenButton') || 'Share screen';
    const STOP_SHARE_SCREEN_BUTTON_TEXT =
        getString('virtualRoomStopShareScreenButton') || 'End screen sharing';

    const cameraOptions = cameraList.map(camera => ({
        primaryText: camera.label,
        onClick: () => {
            mutationCtx.changeCamera(camera.deviceId);
        },
        rightIcon: config.cameraId === camera.deviceId ? <CheckFontIcon /> : undefined,
    }));
    const microphoneOptions = microphoneList.map(microphone => ({
        primaryText: microphone.label,
        onClick: () => {
            mutationCtx.changeMicrophone(microphone.deviceId);
        },
        rightIcon: config.microphoneId === microphone.deviceId ? <CheckFontIcon /> : undefined,
    }));

    useEffect(() => {
        const { isMicrophoneOn, isVideoOn } = props.currentVirtualEventUser;
        setIsMicOn(isMicrophoneOn);
        setIsVidOn(isVideoOn);
    }, [props.currentVirtualEventUser]);

    useEffect(() => {
        const screenSharingStreams = streams.filter(stream => stream.streamId > TEN_MILL);

        // If both users are sharing their screen at the same time
        if (screenSharingStreams.length === 2) {
            const currentUser = Auth.getUser();
            const lastScreenSharingStream = screenSharingStreams[1];

            if (lastScreenSharingStream.streamId === currentUser.id + TEN_MILL) {
                toggleScreenSharing();
            }
        }
    }, [streams]);

    const toggleAudio = async () => {
        if (mutedBySystem) {
            mutationCtx.setMicMutedDialog(true);
            return;
        }

        const microphoneAllowed = await isMicrophoneAllowed();

        if (!microphoneAllowed) {
            mutationCtx.setShowToast(TOAST_TYPES.MICROPHONE);
            return;
        }

        const isNowMicOn = isMicOn;

        setIsMicOn(!isNowMicOn);

        await props.updateVirtualEventUser({
            id,
            isMicrophoneOn: !isNowMicOn,
        });

        if (props.socket) {
            props.socket.emit('updateData', { objectId: timeslotId });
        }
    };

    const toggleVideo = async () => {
        const cameraAllowed = await isCameraAllowed();

        if (!cameraAllowed) {
            mutationCtx.setShowToast(TOAST_TYPES.CAMERA);
            return;
        }

        const isNowVidOn = isVidOn;

        setIsVidOn(!isNowVidOn);

        await props.updateVirtualEventUser({
            id,
            isVideoOn: !isNowVidOn,
        });

        if (props.socket) {
            props.socket.emit('updateData', { objectId: timeslotId });
        }
    };

    const isOtherUserSharingScreen = () => {
        const { streams, virtualEventUser } = stateCtx;

        return !!streams.find(stream => stream.getId() === virtualEventUser.UserId + 10000000);
    };

    const toggleScreenSharing = async () => {
        if (!screenSharing && isOtherUserSharingScreen()) {
            return;
        }

        if (!stateCtx.useDevices) {
            mutationCtx.startUsingDevices();
        }

        if (screenSharing) {
            mutationCtx.stopScreenSharing();
            mutationCtx.setShareType(undefined);
        } else {
            mutationCtx.startScreenSharing();
        }
    };

    AgoraRTC.onMicrophoneChanged = deviceInfo => {
        mutationCtx.startUsingDevices(
            undefined,
            deviceInfo.state === 'ACTIVE' ? deviceInfo.device.deviceId : undefined,
            undefined,
            deviceInfo.state === 'INACTIVE',
        );
    };

    AgoraRTC.onCameraChanged = deviceInfo => {
        mutationCtx.startUsingDevices(
            undefined,
            undefined,
            deviceInfo.state === 'ACTIVE' ? deviceInfo.device.deviceId : undefined,
            deviceInfo.state === 'INACTIVE',
        );
    };

    return (
        <Wrapper>
            <PrimaryButton onClick={props.goBack}>{LEAVE_MEETING_BUTTON_TEXT}</PrimaryButton>

            <CenterContainer>
                <Button
                    style={{
                        ...styles.buttonStyle,
                        marginRight: '15px',
                        backgroundColor: !config.cameraId
                            ? 'gray'
                            : isVidOn
                            ? 'transparent'
                            : '#d50000',
                        overflow: 'hidden',
                    }}
                    icon
                    forceIconSize={32}
                    onClick={toggleVideo}
                >
                    <FontIcon
                        style={{
                            fontSize: '24px',
                            marginLeft: '1px',
                            color: isVidOn ? 'rgba(0,0,0,0.87)' : '#ffffff',
                        }}
                    >
                        {isVidOn ? palette.ICON_CAMERA : palette.ICON_CAMERA_OFF}
                    </FontIcon>
                </Button>
                <Button
                    style={{
                        ...styles.buttonStyle,
                        backgroundColor: !config.microphoneId
                            ? 'gray'
                            : isMicOn || mutedBySystem
                            ? 'transparent'
                            : '#d50000',
                    }}
                    icon
                    forceIconSize={32}
                    onClick={toggleAudio}
                >
                    {mutedBySystem && <MutedBySystemIcon>!</MutedBySystemIcon>}
                    <FontIcon
                        style={{
                            fontSize: '24px',
                            marginLeft: '-6px',
                            color: isMicOn || mutedBySystem ? 'rgba(0,0,0,0.87)' : '#ffffff',
                        }}
                    >
                        {isMicOn || mutedBySystem ? palette.ICON_MIC : palette.ICON_MIC_OFF}
                    </FontIcon>
                </Button>
                <SettingsWrapper>
                    <MenuButton
                        adjusted={0}
                        raised
                        primary
                        id="settings"
                        onClick={() => mutationCtx.startUsingDevices()}
                        menuItems={[
                            <MenuButton
                                adjusted={0}
                                raised
                                primary
                                key="video-settings"
                                id="video-settings"
                                menuItems={cameraOptions}
                                simplifiedMenu={false}
                                anchor={{
                                    x: MenuButton.HorizontalAnchors.RIGHT,
                                    y: MenuButton.VerticalAnchors.TOP,
                                }}
                                repositionOnScroll={false}
                            >
                                {VIDEO_SOURCE_TEXT}
                            </MenuButton>,
                            <MenuButton
                                adjusted={0}
                                raised
                                primary
                                key="audio-settings"
                                id="audio-settings"
                                menuItems={microphoneOptions}
                                simplifiedMenu={false}
                                anchor={{
                                    x: MenuButton.HorizontalAnchors.RIGHT,
                                    y: MenuButton.VerticalAnchors.TOP,
                                }}
                                repositionOnScroll={false}
                            >
                                {AUDIO_SOURCE_TEXT}
                            </MenuButton>,
                        ]}
                        simplifiedMenu={false}
                        anchor={{
                            x: MenuButton.HorizontalAnchors.RIGHT,
                            y: MenuButton.VerticalAnchors.TOP,
                        }}
                        repositionOnScroll={false}
                    >
                        <OptionsButton className="icon-caret-down" />
                    </MenuButton>
                </SettingsWrapper>
            </CenterContainer>

            {isScreenSharing ? (
                <Presentation onClick={toggleScreenSharing}>
                    <FontIcon>cancel_presentation</FontIcon>
                    {STOP_SHARE_SCREEN_BUTTON_TEXT}
                </Presentation>
            ) : (
                <Presentation onClick={toggleScreenSharing}>
                    <FontIcon>present_to_all</FontIcon>
                    {SHARE_SCREEN_BUTTON_TEXT}
                </Presentation>
            )}
        </Wrapper>
    );
};

export default Footer;
