import React, { useEffect, useState } from 'react';
import { getTabsElements } from './services/tabsPage';
import { withRouter } from 'react-router-dom';
import Loader from '../../components/General/Loader.js';
import ContentSelector from '../../components/Routes/ContentSelector';
import styled from 'styled-components';
import ThemeContext from '../../components/Theme/ThemeContext';
import queryString from 'query-string';
import {
    TabElement,
    TabsContainerStyled,
    TabsWrapper,
    ToolbarContainer,
} from '../Programme/style/style';
import BrandingBanner from '../../components/General/BrandingBanner';
import { usePagesStore } from '../../stores/PagesStore';
import AccessRestrictions from '../AccessRestrictions';
import Box from '@mui/material/Box';
import Slideshow from '../ExhibitorPage/containers/Slideshow';

const StyledLinkNotNav = styled.div`
    font-weight: 500;
`;

const Container = styled.div`
    min-height: 400px;
`;

const TabsPage = props => {
    const [data, setData] = useState({
        tabsData: [],
        selectedTab: 0,
        childrenId: '',
        childrenType: '',
        initialItem: 0,
        slideshow: [],
    });
    const [loading, setLoading] = useState(true);
    const [banner, setBanner] = useState(null);
    const [titleOnBanner, setTitleOnBanner] = useState(null);
    const [subtitleOnBanner, setSubtitleOnBanner] = useState(null);

    const pages = usePagesStore(state => state.pages);
    const getPageById = usePagesStore(state => state.getPageById);

    useEffect(() => {
        const getQueryParamsTab = () => {
            const parsed = queryString.parse(props.location.search);
            const activeTab = parseInt(parsed.tab);

            return activeTab;
        };
        const getTabsInfo = () => {
            getTabsElements(props.pageId, pages, (err, result) => {
                if (err) {
                    console.log(err);
                } else {
                    if (result?.bannerImage) {
                        setBanner(result.bannerImage);
                    }
                    const activeTab = getQueryParamsTab();
                    const initialItem = activeTab || result.initialItem;

                    const page = getPageById(props.pageId);

                    setData({
                        tabsData: result.items,
                        selectedTab: initialItem,
                        childrenId: result.items[initialItem].page,
                        childrenType: result.items[initialItem].screen,
                        initialItem: parseInt(initialItem, 10),
                        slideshow: page?.slideshow || [],
                    });

                    if (result?.showPageNameInBanner) {
                        setTitleOnBanner(result?.title);
                        setSubtitleOnBanner(result?.subtitle);
                        props.setTitle('');
                    } else {
                        props.setTitle();
                    }

                    setLoading(false);

                    if (props.updateTitle) {
                        props.updateTitle(result.title);
                    }
                }
            });
        };
        if (pages && pages.length && props.pageId) {
            getTabsInfo();
        }
    }, [props.pageId, pages]);

    const tabChanged = newActiveTabIndex => {
        const searchData = queryString.parse(props.location.search);
        let childrenId = data.tabsData[newActiveTabIndex].page;
        let childrenType = data.tabsData[newActiveTabIndex].screen;

        setData({
            ...data,
            childrenId: childrenId,
            childrenType: childrenType,
            selectedTab: newActiveTabIndex,
        });
        searchData.tab = newActiveTabIndex;
        const stringified = queryString.stringify(searchData);
        props.history.push({
            pathname: props.location.pathname,
            search: `?${stringified}`,
        });
    };

    const tabs = (data, theme) => {
        return data.tabsData.map((tab, i) => {
            const componentTab = StyledLinkNotNav;

            return (
                <TabElement
                    key={i}
                    params={{
                        tab: i,
                    }}
                    component={componentTab}
                    label={tab.commonTitle || tab.name}
                    active={data.selectedTab === i}
                    primary={theme.primary}
                />
            );
        });
    };

    return (
        <>
            {loading && <Loader />}
            {!loading && (
                <ThemeContext.Consumer>
                    {({ theme }) => (
                        <AccessRestrictions pageId={props.pageId}>
                            <BrandingBanner
                                banner={banner}
                                visibleFullSideMenu={props.visibleFullSideMenu}
                                navigationType={props.navigationType}
                                title={titleOnBanner}
                                subtitle={subtitleOnBanner}
                                showInContentContainer={!!props.navigationType}
                                setTitleOnHeader={props.setTitle}
                            >
                                {data.slideshow && data.slideshow?.length > 0 && (
                                    <Box paddingTop={2}>
                                        <Slideshow
                                            data={data.slideshow}
                                            itemDuration={3000}
                                            autoplay
                                            indicators
                                            style={{
                                                aspectRatio: '4',
                                                padding: 16,
                                                borderRadius: 16,
                                            }}
                                        />
                                    </Box>
                                )}
                                <ToolbarContainer>
                                    <TabsContainerStyled
                                        activeTabIndex={data.selectedTab}
                                        panelClassName="md-grid"
                                        onTabChange={tabChanged}
                                        colored
                                    >
                                        <TabsWrapper
                                            background={theme.primary}
                                            tabId="simple-tab"
                                            mobile
                                            alignToKeyline={false}
                                        >
                                            {tabs(data, theme)}
                                        </TabsWrapper>
                                    </TabsContainerStyled>
                                </ToolbarContainer>
                                <Container>
                                    <ContentSelector
                                        pageId={data.childrenId}
                                        type={data.childrenType}
                                        setTitle={() => {}}
                                        disableBanner
                                    />
                                </Container>
                            </BrandingBanner>
                        </AccessRestrictions>
                    )}
                </ThemeContext.Consumer>
            )}
        </>
    );
};

export default withRouter(TabsPage);
