import React, { useEffect, useState } from 'react';
import { useSocket } from '../../../../components/Session/SocketContext';
import Loader from '../../../../components/General/Loader';
import { ContainerProvider, useVMMutation, useVMState } from './containers/main';
import { populateDefaultState } from './reducers/main';
import AuthUserContext from '../../../../components/Session/AuthUserContext';
import Header from '../../../VirtualSession/components/common/Header';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getTimeslot } from '../../../VirtualSession/services/session';
import { useHistory, useRouteMatch } from 'react-router-dom';

import LeftContent from './components/qamoderator/LeftContent/LeftContent';
import RightContent from './components/qamoderator/RightContent/RightContent';
import styled from 'styled-components';

import get from 'lodash/get';
import Auth from '../../../../services/api/auth';

import { getPollSetByExternalObjectReference } from '../Votes/services/PollingService';

const MainWrapper = styled.div`
    margin-top: 56px;
    height: calc(100vh - 56px);
    width: 100%;
    border-left: 1px solid #80808047;
    overflow: hidden;
    background: #f1f1f3;
    box-sizing: border-box;
`;

const Wrapper = styled.div`
    display: flex;
    box-sizing: border-box;
    flex-flow: row nowrap;
    padding: 8px;
    height: 100%;
    gap: 16px;
`;

// Setting Material UI ThemeProvider
const materialUITheme = createTheme({
    palette: {
        type: 'light', // we can later use dark/light modes
        primary: {
            main: '#00A480',
        },
        secondary: {
            main: '#EFEFEF',
        },
    },
});

const QAModeration = () => {
    const [loading, setLoading] = useState(true);
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();
    const match = useRouteMatch();
    const history = useHistory();

    const { timeslotId } = match.params;

    useEffect(() => {
        setLoading(true);
        getTimeslot(timeslotId, async (err, timeslot) => {
            if (err || !timeslot) {
                return;
            }

            mutationCtx.setTimeslot(timeslot);
            setLoading(false);
        });
    }, [timeslotId]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const sessionReferenceId = timeslotId;

            mutationCtx.setSessionId(sessionReferenceId);

            const userResponse = Auth.getUser();

            const [externalObject] = await getPollSetByExternalObjectReference(sessionReferenceId);

            const externalObjectData = get(externalObject, 'ExternalObject', {});

            mutationCtx.setExtraState({
                externalObject: {
                    ...stateCtx.externalObject,
                    data: externalObjectData,
                },
                user: userResponse,
                currentUser: userResponse,
            });

            const joinRooms = () => {
                stateCtx.socket.emit('joinQuestions', {
                    objectId: externalObjectData.id,
                });
            };

            stateCtx.socket.on('connect', joinRooms);

            joinRooms();

            setLoading(false);
        })();
    }, []);

    const { timeslot, externalObject } = stateCtx;

    const goBack = () => {
        history.goBack();
    };

    if (
        !(
            timeslot &&
            timeslot.id &&
            externalObject &&
            externalObject.data &&
            externalObject.data.id
        )
    ) {
        return null;
    }

    return (
        <MainWrapper>
            <ThemeProvider theme={materialUITheme}>
                <AuthUserContext.Consumer>
                    {authUser => (
                        <>
                            {!loading && (
                                <>
                                    <Header
                                        goBack={goBack}
                                        timeslot={timeslot}
                                        authUser={authUser}
                                    />
                                    <Wrapper>
                                        <LeftContent />
                                        <RightContent />
                                    </Wrapper>
                                </>
                            )}
                            {loading && <Loader />}
                        </>
                    )}
                </AuthUserContext.Consumer>
            </ThemeProvider>
        </MainWrapper>
    );
};

const Container = () => {
    const { socket } = useSocket();
    populateDefaultState({
        socket,
    });

    return (
        <ContainerProvider>
            <QAModeration />
        </ContainerProvider>
    );
};

export default Container;
