import React, { useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import get from 'lodash/get';
import { FontIcon } from 'react-md';
import DeleteIcon from '@mui/icons-material/Delete';

import VideoContainer from '../../video/VideoContainer';
import UserInteraction from '../../moderator/userInteraction';
import * as palette from '../../../../../../../components/General/Variables';
import entities from '../../../constants/entities';
import MediaPlayer from '../../common/MediaPlayer';
import UploadVideoModal from '../../moderator/sessionView/preRecordingsAndVideos/UploadVideoModal';
import onAddVideoUrl from '../../../events/onAddVideoUrl';
import onDeletePrerecordingUrl from '../../../events/onDeletePrerecordingUrl';
import onDeleteUploadedVideoById from '../../../events/onDeleteUploadedVideo';
import ConfirmationDialog from '../../common/ConfirmatonDialog';
import { useVMState, useVMMutation } from '../../../containers/main';
import {
    CancelModalButton,
    ContentModalContainer,
    DestructiveModalButton,
} from '../../moderator/common/styles';
import Loader from '../../common/Loader';
import InfoCard from '../../roundTable/InfoCard';
import { getString } from '../../../../../../../services/api/store';

const Wrapper = styled.div`
    display: flex;
    flex: 1;
    flex-flow: column;
    justify-content: space-between;
    height: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: ${props => (props.padding ? props.padding : 0)};
    overflow-y: scroll;
    @media (max-height: 800px) and (orientation: landscape) {
        max-width: 400px;
    }
`;

const ModalWrapper = styled.div`
    flex: 1;
    overflow: scroll;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 16px;
`;

const InteractionWrapper = styled.div`
    flex: 3;
    height: 100%;
    min-height: 0;
    width: 100%;
    @media only screen and (max-width: ${palette.MAX_TABLET}) {
        bottom: 115px;
    }
`;

const TipsContainer = styled.div`
    flex: 3;
    height: 100%;
    min-height: 0;
    font-family: Roboto, sans-serif;
    color: rgb(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: normal;
    padding: 16px;
    p {
        margin: 0 !important;
        line-height: 20px !important;
        color: rgb(0, 0, 0, 0.87);
    }
`;

const Subtitle = styled.p`
    font-family: Cabin, sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0, 87);
    line-height: 1.25;
    padding: 16px 0 8px 0 !important;
`;

const UploadButton = styled.div`
    cursor: pointer;
    height: 40px;
    flex-grow: 0;
    align-items: center;
    display: flex;
    flex-flow: row;
    justify-content: center;
    padding: 0;
    border-radius: 6px;
    background-color: #efefef;
    width: 100%;
    margin: 16px 0 24px 0;

    span {
        font-family: Roboto, sans-serif;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000;
    }
`;

const EncodingWrapper = styled.div`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin: 12px 0 0;
    width: 100%;
    height: 64px;
    padding: 12px;
    border-radius: 8px;
    background-color: #f5f5f5;
`;

const ActionsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${props =>
        props.encoding &&
        css`
            justify-content: flex-end;
        `}
`;

const NameAndVideoInfo = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const Name = styled.div`
    color: rgba(0, 0, 0, 0.87);
    font-family: Cabin, sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    ${props =>
        props.isdisabled &&
        css`
            color: rgba(0, 0, 0, 0.38);
        `}
`;

const VideoInfo = styled.div`
    color: rgba(0, 0, 0, 0.54);
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
`;

const StyledIcon = styled(FontIcon)`
    color: ${props => (props.color ? props.color : '#fff')} !important;
    margin-right: 13px !important;
    font-size: 24px !important;
    width: ${props => (props.width ? props.width : 'initial')} !important;
`;

const ExtraStyledModalContentContainer = styled(ContentModalContainer)`
    padding: 0 25px;
    margin-bottom: -3px;
`;

const IconWrapper = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.1s linear;
    background-color: transparent;
    z-index: 1;
    :hover {
        background-color: rgba(0, 0, 0, 0.04);
        color: rgba(0, 0, 0, 0.87);
    }
    ${props =>
        props.disabled &&
        css`
            cursor: not-allowed;
        `}
`;

const videoTypeMap = {
    preRecordedPresentationUrl: 'Pre-recording',
    uploadedVideo: 'Uploaded video',
};

const CompressingLoader = styled(Loader)`
    box-shadow: unset !important;
    width: 25px;
    height: 25px;
    margin: 0 10px 0 0;
    background: transparent;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
}
`;
const RightContent = props => {
    const { setShowConversionModal } = props || {};
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();
    const [interactionWindowHeight, setInteractionWindowHeight] = useState(null);
    const [showUploadVideoModal, setShowUploadVideoModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [displayActions, setDisplayActions] = useState(false);
    const [encodingStatus, setEncodingStatus] = useState('complete');

    const wrapperRef = useRef();
    const handleResize = () => {
        const wrapperHeight =
            wrapperRef.current && wrapperRef.current.getBoundingClientRect().height;
        const interactionHeight = window.innerHeight - wrapperHeight;
        setInteractionWindowHeight(
            window.innerHeight < 900 ? interactionHeight : interactionHeight + 200,
        );
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const {
        sessionId,
        speakerViewMode,
        virtualEventUser,
        virtualEventSession,
        duration,
    } = stateCtx;

    const uploadedVideoUrl = get(virtualEventUser, 'data.recordingInfo.videoUrl', '');
    const uploadedShareUrl = get(virtualEventUser, 'data.recordingInfo.shareUrl', '');
    const shareVideoName = get(virtualEventUser, 'data.recordingInfo.shareVideoName', '');
    const videoUrls = get(virtualEventSession, 'data.videoUrls') || [];

    // shareVideoName.slice(0, -4): removing ".mp4" from the string
    const uploadedVideo = videoUrls.find(
        video => (get(video, 'shareUrl') || '').indexOf(shareVideoName.slice(0, -4)) >= 0,
    );

    const prerecordingMode = speakerViewMode === entities.speakerViewMode.prerecording;
    const preRecordedPresentationUrl = get(virtualEventUser, 'data.preRecordedPresentationUrl');
    const hasUploadedVideo = !!get(virtualEventUser, 'data.recordingInfo');
    const hasPreRecording = !!preRecordedPresentationUrl;
    const shouldRenderPlayer =
        prerecordingMode &&
        (hasPreRecording || (hasUploadedVideo && uploadedVideoUrl) || uploadedShareUrl);

    const convertSecondsToTimeString = seconds => {
        return new Date(seconds * 1000).toISOString().substr(14, 5);
    };

    useEffect(() => {
        if (get(uploadedVideo, 'name')) {
            setEncodingStatus(get(uploadedVideo, 'encoding'));
        } else {
            setEncodingStatus('complete');
        }
    }, [uploadedVideo]);

    const onAddVideo = async newVideo => {
        if (!newVideo) {
            return;
        }

        await onAddVideoUrl(stateCtx, mutationCtx, newVideo, virtualEventUser);

        setShowConversionModal(true);
    };

    const onDeleteUploadedVideo = async index => {
        const video = videoUrls[index];

        if (video && video.id) {
            await onDeleteUploadedVideoById(video.id, stateCtx, true);
        }
    };

    if (shouldRenderPlayer) {
        const isEncodingPending = encodingStatus === 'pending';
        const isEncodingRunning = encodingStatus === 'running';
        const isEncoding = isEncodingPending || isEncodingRunning;
        const user = get(virtualEventUser, 'data.User');
        const name = `${user.firstName} ${user.lastName}`;
        let videoType = 'preRecordedPresentationUrl';

        const list = get(virtualEventUser, 'data.recordingInfo.list', []);
        let videoUrl;
        if (list.length) {
            const shareInfo = list.find(info => info.type === 'video');
            videoUrl = `${preRecordedPresentationUrl}/video/${shareInfo.sid}_${sessionId}.m3u8`;
        } else {
            videoType = 'uploadedVideo';
            videoUrl = uploadedVideoUrl;
        }

        const onDelete = async () => {
            if (list.length) {
                mutationCtx.setExtraState({
                    primaryVideoDuration: 0,
                    secondaryVideoDuration: 0,
                    duration: null,
                    playing: false,
                    playedSeconds: 2,
                    primaryPlayerRef: null,
                    secondaryPlayerRef: null,
                });
                await onDeletePrerecordingUrl(stateCtx, mutationCtx);
            } else {
                const videoIndex = videoUrls.findIndex(
                    video =>
                        (get(video, 'shareUrl') || '').indexOf(shareVideoName.slice(0, -4)) >= 0,
                );
                await onDeleteUploadedVideo(videoIndex);
                stateCtx.socket.emit('updateData', {
                    objectId: sessionId,
                    virtualEventSession: {
                        VirtualEventUsers: [
                            {
                                id: virtualEventUser.data.id,
                                recordingInfo: null,
                            },
                        ],
                    },
                });
            }

            setShowDeleteModal(false);
        };

        return (
            <Wrapper padding={'16px'}>
                <MediaPlayer url={videoUrl} data-qa="pre-recording-camera-feed" />
                <TipsContainer data-qa="pre-recording-instructions">
                    You can view your pre-recording here. The moderator of the session also has
                    access to your recording and will be able to play it during the live session.
                    <br />
                    <br />
                    To start or to upload a new recording, delete the previous one first.
                    <EncodingWrapper
                        onMouseEnter={() => setDisplayActions(true)}
                        onMouseLeave={() => setDisplayActions(false)}
                    >
                        <NameAndVideoInfo>
                            <Name>{name}</Name>

                            <VideoInfo>
                                {!isEncoding && (
                                    <span>
                                        {`${videoTypeMap[videoType]} (${convertSecondsToTimeString(
                                            duration,
                                        )})`}
                                    </span>
                                )}
                                {isEncodingRunning && <span>Processing...</span>}
                                {isEncodingPending && <span>Waiting...</span>}
                            </VideoInfo>
                        </NameAndVideoInfo>

                        <ActionsWrapper>
                            {isEncoding && !displayActions && (
                                <CompressingLoader
                                    extraCircleStyle={{
                                        color: 'gray',
                                        marginLeft: '5px',
                                        height: '15px',
                                        width: '15px',
                                    }}
                                />
                            )}
                            {(!isEncoding || displayActions) && (
                                <IconWrapper onClick={() => setShowDeleteModal(true)}>
                                    <DeleteIcon />
                                </IconWrapper>
                            )}
                        </ActionsWrapper>
                    </EncodingWrapper>
                </TipsContainer>
                <div className="eureka-react">
                    <ConfirmationDialog
                        open={showDeleteModal}
                        title="Delete recording"
                        titleIcon="delete_forever"
                        iconColor="rgba(0, 0, 0, 0.87)"
                        iconWrapperColor="#EFEFEF"
                        withCloseButton
                        onClose={() => setShowDeleteModal(false)}
                        content={
                            <ExtraStyledModalContentContainer>
                                Are you sure you want to delete your recording? This cannot be
                                undone.
                            </ExtraStyledModalContentContainer>
                        }
                        buttons={[
                            <DestructiveModalButton
                                key="b1"
                                flat
                                onClick={() => {
                                    setShowDeleteModal(false);
                                    onDelete();
                                }}
                            >
                                Delete
                            </DestructiveModalButton>,
                            <CancelModalButton
                                key="b2"
                                flat
                                onClick={() => setShowDeleteModal(false)}
                            >
                                Cancel
                            </CancelModalButton>,
                        ]}
                    />
                </div>
            </Wrapper>
        );
    }

    return (
        <Container>
            <InfoCard
                cardText={getString('speakerInfoCardText')}
                buttonText={getString('speakerInfoCardButton')}
            />
            <Wrapper innerRef={wrapperRef}>
                <VideoContainer />
                <InteractionWrapper
                    interactionWindowHeight={interactionWindowHeight}
                    interactionInitialHeight={!interactionWindowHeight && window.innerHeight}
                >
                    {!prerecordingMode ? (
                        <UserInteraction controls={false} />
                    ) : (
                        <TipsContainer>
                            <Subtitle>Pre-record on this platform</Subtitle>
                            <p>
                                To pre-record your presentation on this platform, upload your slides
                                as PDF or use screen sharing. Click ‘Record’ at the bottom left and
                                confirm to start recording. When you’re finished, click ‘Stop
                                recording’.
                            </p>
                            <Subtitle>Upload video</Subtitle>
                            <p>
                                Recorded your presentation elsewhere? You can upload it through the
                                button below.
                            </p>

                            <UploadButton onClick={() => setShowUploadVideoModal(true)}>
                                <StyledIcon width="24px" color="#000">
                                    add_outline
                                </StyledIcon>
                                <span>Upload video</span>
                            </UploadButton>

                            <p>
                                You can review your presentation after creating or uploading it. The
                                moderator can play it during the session.
                            </p>
                        </TipsContainer>
                    )}
                </InteractionWrapper>
                {showUploadVideoModal && (
                    <ModalWrapper className="eureka-react">
                        <UploadVideoModal
                            onAdd={onAddVideo}
                            onClose={() => setShowUploadVideoModal(false)}
                            fileName={`${virtualEventUser.data.User.firstName} ${virtualEventUser.data.User.lastName}`}
                        />
                    </ModalWrapper>
                )}
            </Wrapper>
        </Container>
    );
};

RightContent.propTypes = {};

export default RightContent;
