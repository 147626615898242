import async from 'async';
import axios from 'axios';
import each from 'lodash/each';
import filter from 'lodash/filter';
import get from 'lodash/get';
import Auth from './auth';
import { Buffer } from 'buffer';
import { EUREKA_URL } from '../../config';

import {
    addFavoriteForEvent,
    removeFavorite,
    saveNoteForEvent,
    addRatingForEvent,
    addCheckin,
    removeCheckin,
} from './data';

import { findAllLocal, getLocalAppState, getLocalAppStateAsync } from './db';

const methods = {
    put: 'put',
    post: 'post',
    get: 'get',
    delete: 'delete',
    patch: 'patch',
};

const getSocietyInfo = async domain => {
    const url = `${EUREKA_URL}/api/entities/societies/domain/${domain}`;
    const response = await axios.get(url);
    if (response && response.data) {
        return {
            EUREKA_URL,
            data: response.data,
        };
    } else {
        throw new Error('Society not found');
    }
};

const logIn = (data, next) => {
    eurekaRequest('post', '/auth/local/login', data, false, (err, response) => {
        next(err, response);
    });
};

const register = (data, next) => {
    eurekaRequest('post', '/auth/local/register', data, false, (err, response) => {
        next(err, response);
    });
};

const checkEmail = (data, next) => {
    eurekaRequest('post', '/auth/local/getAccountWithEmail', data, false, (err, response) => {
        next(err, response);
    });
};

const syncExternalProfile = async organization => {
    return eurekaRequestAsync(methods.get, `/auth/oauth/${organization}/profileSync`, null, true);
};

const oAuthPasswordAuthenticate = (data, next) => {
    const { organization, state } = data;
    const url = `/auth/oauth/${organization}/authenticate?state=${state}`;
    eurekaRequest('post', url, data, false, (err, response) => {
        next(err, response);
    });
};

const resendConfirmation = (data, next) => {
    const { userId } = data;
    eurekaRequest(
        'get',
        `/auth/local/resendConfirmation/${userId}`,
        data,
        false,
        (err, response) => {
            next(err, response);
        },
    );
};

const isAccountValidated = (data, next) => {
    const { token } = data;
    eurekaRequest('get', `/auth/local/isValidated`, data, token, (err, response) => {
        next(err, response);
    });
};

const generatePKCEChallenge = () => {
    return new Promise((resolve, reject) => {
        eurekaRequest('get', '/auth/oauth/generatePKCEChallenge', null, false, (err, response) => {
            if (err) {
                reject(err);
            } else {
                resolve(response);
            }
        });
    });
};

const getVirtualEventToken = (data, next) => {
    return new Promise((resolve, reject) => {
        eurekaRequest('post', `/api/entities/virtualEventToken`, data, true, (err, response) => {
            if (err) {
                reject(err);
            } else {
                resolve(response);
            }
        });
    });
};

const updateVirtualEventToken = (data, next) => {
    return new Promise((resolve, reject) => {
        const { userId } = data;
        eurekaRequest(
            'patch',
            `/api/entities/virtualEventToken/${userId}`,
            data,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });
};

const removeVirtualEventToken = (data, next) => {
    return new Promise((resolve, reject) => {
        const { tokenId } = data;
        eurekaRequest(
            'delete',
            `/api/entities/virtualEventToken/${tokenId}`,
            null,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });
};

const registerVirtualEventUser = (data, next) => {
    return new Promise((resolve, reject) => {
        eurekaRequest('post', `/api/entities/virtualEventUser`, data, true, (err, response) => {
            if (err) {
                reject(err);
            } else {
                resolve(response);
            }
        });
    });
};

const updateVirtualEventUser = (data, next) => {
    return new Promise((resolve, reject) => {
        const { id } = data;
        delete data.id;
        eurekaRequest(
            'put',
            `/api/entities/virtualEventUser/${id}`,
            data,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });
};

const removeVirtualEventUser = (data, next) => {
    return new Promise((resolve, reject) => {
        const { id } = data;
        eurekaRequest(
            'delete',
            `/api/entities/virtualEventUser/${id}`,
            data,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });
};

const getVirtualEventSession = async sessionId => {
    return eurekaRequestAsync(
        methods.get,
        `/api/entities/virtualEventSession/session/${sessionId}`,
        null,
        true,
    );
};

const getVirtualEventCaptions = async VirtualEventSessionId => {
    return eurekaRequestAsync(
        methods.get,
        `/api/entities/VirtualEventCaption/VirtualEventSession/${VirtualEventSessionId}`,
        null,
        true,
    );
};

const deleteVirtualEventCaptions = async VirtualEventSessionId => {
    return eurekaRequestAsync(
        methods.delete,
        `/api/entities/VirtualEventCaption/VirtualEventSession/${VirtualEventSessionId}`,
        null,
        true,
    );
};

const getInstitutionProposals = async () => {
    return eurekaRequestAsync(methods.get, '/api/entities/institutionProposal/me', null, true);
};

const updateInstitutionProposalData = async (institutionId, data) => {
    return eurekaRequestAsync(
        methods.patch,
        `/api/entities/institutionProposal/${institutionId}`,
        data,
        true,
    );
};

const updateInstitutionProposalStatus = async (institutionId, data) => {
    return eurekaRequestAsync(
        methods.post,
        `/api/entities/institutionProposal/${institutionId}/status`,
        data,
        true,
    );
};

const getPeerList = async sessionId => {
    return eurekaRequestAsync(
        methods.get,
        `/api/entities/virtualEventSession/session/${sessionId}/getPeerList`,
        null,
        true,
    );
};

const canJoinRoundTable = async (sessionId, uid, afterJoining = false) => {
    return eurekaRequestAsyncNoHandling(
        methods.get,
        `/api/entities/virtualEventSession/session/${sessionId}/canJoinRoundTable/${uid}?afterJoining=${afterJoining}`,
        null,
        true,
    );
};

const getVirtualEventSessionByAppointmentId = async appointmentId => {
    return eurekaRequestAsync(
        methods.get,
        `/api/entities/virtualEventSession/appointment/${appointmentId}`,
        null,
        true,
    );
};

const getVirtualEventSessionByAppointmentReference = async reference => {
    return eurekaRequestAsync(
        methods.get,
        `/api/entities/virtualEventSession/appointment/reference/${reference}`,
        null,
        true,
    );
};

const getVirtualEventPollSets = async sessionId => {
    return eurekaRequestAsync(
        methods.get,
        `/api/entities/pollSets/public/externalObject/${sessionId}`,
        null,
        true,
    );
};

const getVirtualEventPollSetsByEventAndItemIds = async (eventId, itemId, withVotes = false) => {
    return eurekaRequestAsync(
        methods.get,
        `/api/entities/pollSets/externalObject/${eventId}/${itemId}${
            withVotes ? '/withVotes' : ''
        }`,
        null,
        true,
    );
};

const submitPollAnswer = async data => {
    return eurekaRequestAsync(methods.post, '/api/entities/pollAnswers/', data, true);
};

const resetPassword = (data, next) => {
    eurekaRequest('post', '/auth/local/forgot', data, false, (err, response) => {
        next(err, response);
    });
};

const googleSignIn = (data, next) => {
    eurekaRequest('post', '/auth/google/verify', data, false, (err, response) => {
        next(err, response);
    });
};

const updateUserEventInstallations = (data, next) => {
    eurekaRequest(
        'post',
        '/api/entities/events/updateInstallations',
        data,
        true,
        (err, response) => {
            next(err, response);
        },
    );
};

const getLatestPrivacyPolicy = async () => {
    return eurekaRequestAsync(methods.get, `/api/entities/privacyPolicies/latest`, null, true);
};

const updateUserPrivacyPolicy = async (version, token) => {
    const data = {
        version,
    };
    return eurekaRequestAsync(methods.put, `/api/entities/users/policy`, data, token);
};

const sync = next => {
    if (!Auth.isUserAuthenticated()) {
        return next();
    }

    getLocalAppState((err, config) => {
        if (err) {
            next(err);
        } else if (!config) {
            next('no config found');
        } else {
            let data = {
                favorites: [],
                deletedFavorites: [],
                notes: [],
                deletedNotes: [],
                ratings: [],
                deletedRatings: [],
                appointments: [],
                checkins: [],
                deletedCheckins: [],
            };

            data.eventId = data.eventReference = config.eventId;
            data.eventTitle = config.eventTitle;
            const user = Auth.getUser();
            if (user && user.nvvpToken) {
                data.nvvpToken = user.nvvpToken;
            }

            async.waterfall(
                [
                    function (callback) {
                        findAllLocal(
                            'favorites',
                            item => {
                                return item.action.length > 0 && item.event === config.eventId;
                            },
                            (err, result) => {
                                data.favorites = filter(result, function (i) {
                                    return i.action === 'Add';
                                });

                                data.deletedFavorites = filter(result, function (i) {
                                    return i.action === 'Delete';
                                });

                                callback();
                            },
                        );
                    },
                    function (callback) {
                        findAllLocal(
                            'ratings',
                            item => {
                                return (
                                    (item.id.length > 0 || item.action.length > 0) &&
                                    item.event === config.eventId
                                );
                            },
                            (err, result) => {
                                data.ratings = filter(result, function (i) {
                                    return i.action === 'Add';
                                });

                                data.deletedRatings = filter(result, function (i) {
                                    return i.action === 'Delete';
                                });

                                callback();
                            },
                        );
                    },
                    function (callback) {
                        findAllLocal(
                            'notes',
                            item => {
                                return item.action.length > 0 && item.event === config.eventId;
                            },
                            (err, result) => {
                                data.notes = filter(result, function (i) {
                                    return i.action === 'Add';
                                });

                                data.deletedNotes = filter(result, function (i) {
                                    return i.action === 'Delete';
                                });

                                callback();
                            },
                        );
                    },
                    function (callback) {
                        findAllLocal(
                            'checkins',
                            item => {
                                return item.action.length > 0;
                            },
                            (err, result) => {
                                data.checkins = filter(result, function (i) {
                                    return i.action === 'Add';
                                });

                                data.deletedCheckins = filter(result, function (i) {
                                    return i.action === 'Delete';
                                });

                                callback();
                            },
                        );
                    },
                ],
                function (err) {
                    eurekaRequest('post', '/api/services/sync/all', data, true, (err, response) => {
                        if (response && response.favorites) {
                            const favorites = response.favorites.filter(
                                (f, index, self) =>
                                    f &&
                                    f.ExternalObject &&
                                    f.ExternalObject.Event &&
                                    f.ExternalObject.Event.reference &&
                                    f.ExternalObject.Event.reference === config.eventId &&
                                    self.findIndex(
                                        t => t.ExternalObject.title === f.ExternalObject.title,
                                    ) === index,
                            );

                            each(favorites, function (favorite) {
                                const favoriteReference = {
                                    id: favorite.ExternalObject.reference,
                                    name: favorite.ExternalObject.title,
                                };
                                if (favorite.action === 'Add') {
                                    addFavoriteForEvent(favoriteReference, function () {});
                                } else if (favorite.action === 'Delete') {
                                    removeFavorite(favoriteReference, function () {});
                                }
                            });
                        }

                        if (response && response.notes) {
                            const notes = filter(response.notes, function (n) {
                                if (
                                    n &&
                                    n.ExternalObject &&
                                    n.ExternalObject.Event &&
                                    n.ExternalObject.Event.reference &&
                                    n.ExternalObject.Event.reference === config.eventId
                                ) {
                                    return true;
                                } else {
                                    return false;
                                }
                            });
                            each(notes, function (note) {
                                const obj = {
                                    id: note.ExternalObject.reference,
                                    title: note.ExternalObject.title,
                                    objectClass: null,
                                    notifyUI: () => {},
                                };
                                if (note.action === 'Add') {
                                    saveNoteForEvent(
                                        obj,
                                        note.text,
                                        () => {},
                                        err => {},
                                    );
                                } else if (note.action === 'Delete') {
                                    saveNoteForEvent(
                                        obj,
                                        null,
                                        () => {},
                                        err => {},
                                    );
                                }
                            });
                        }
                        if (response && response.ratings) {
                            const ratings = filter(response.ratings, function (r) {
                                if (
                                    r &&
                                    r.ExternalObject &&
                                    r.ExternalObject.Event &&
                                    r.ExternalObject.Event.reference &&
                                    r.ExternalObject.Event.reference === config.eventId
                                ) {
                                    return true;
                                } else {
                                    return false;
                                }
                            });
                            each(ratings, function (rating) {
                                const date = new Date();
                                const timestamp = date.toISOString();
                                const lastUpdate = date.getTime();
                                const rateObj = {
                                    objectId: rating.ExternalObject.reference,
                                    rate: parseInt(rating.rate, 10),
                                    title: rating.ExternalObject.title,
                                    lastUpdate,
                                    timestamp,
                                    deleted: rating.deleted ? 1 : 0,
                                };
                                if (rating.action === 'Add') {
                                    addRatingForEvent(rateObj, () => {});
                                } else if (rating.action === 'Delete') {
                                    addRatingForEvent(rateObj, () => {});
                                }
                            });
                        }

                        if (response && response.checkins) {
                            const checkins = response.checkins.filter(
                                c =>
                                    c &&
                                    c.ExternalObject &&
                                    c.ExternalObject.Event &&
                                    c.ExternalObject.Event.reference &&
                                    c.ExternalObject.Event.reference === config.eventId,
                            );

                            each(checkins, function (checkin) {
                                const checkinReference = {
                                    id: checkin.ExternalObject.reference,
                                    name: checkin.ExternalObject.title,
                                };

                                if (checkin.action === 'Add') {
                                    addCheckin(checkinReference);
                                } else if (checkin.action === 'Delete') {
                                    removeCheckin(checkinReference);
                                }
                            });
                        }

                        next();
                    });
                },
            );
        }
    });
};

const eurekaRequest = function (method, path, data, isAuthenticated, next) {
    let options = {
        method: method,
        data: data,
        headers: {
            'x-api-version': '3',
        },
    };

    if (isAuthenticated && typeof isAuthenticated === 'string') {
        options.headers['x-access-token'] = isAuthenticated;
    } else if (isAuthenticated && Auth.isUserAuthenticated()) {
        options.headers['x-access-token'] = Auth.getToken();
    }

    getLocalAppState((err, config) => {
        options.url = EUREKA_URL + path;
        options.headers['x-society-id'] = config && config.societyId ? config.societyId : null;
        axios(options)
            .then(function (response) {
                if (response) {
                    if (response.data) {
                        next(null, response.data);
                    } else {
                        next(null, response);
                    }
                } else {
                    next('no response');
                }
            })
            .catch(function (error) {
                console.log(error);
                console.log(error.response);
                if (error.response) {
                    if (error.response.status === 401) {
                        Auth.signOut();
                    }
                    if (error.response.data) {
                        const err = error.response.data.message
                            ? error.response.data
                            : { message: error.response.data };
                        next(err);
                    } else {
                        next(error);
                    }
                } else {
                    next(error);
                }
            });
    });
};

const asyncEurekaRequest = (method, path, data, isAuthenticated) => {
    return new Promise((resolve, reject) => {
        eurekaRequest(method, path, data, isAuthenticated, (err, res) => {
            if (err) {
                reject(err);
            } else {
                resolve(res);
            }
        });
    });
};

const eurekaRequestAsync = async (method, path, data, isAuthenticated) => {
    try {
        return eurekaRequestAsyncNoHandling(method, path, data, isAuthenticated);
    } catch (error) {
        const response = get(error, 'response') || {};

        if (response.status === 401) {
            Auth.signOut();
        }
        if (response.data) {
            return response.data.message ? response.data : { message: response.data };
        }
        return error;
    }
};

const eurekaRequestAsyncNoHandling = async (method, path, data, isAuthenticated) => {
    let options = {
        method,
        data,
        headers: {
            'x-api-version': '3',
        },
    };

    if (isAuthenticated && typeof isAuthenticated === 'string') {
        options.headers['x-access-token'] = isAuthenticated;
    } else if (isAuthenticated && Auth.isUserAuthenticated()) {
        options.headers['x-access-token'] = Auth.getToken();
    }

    const config = await getLocalAppStateAsync();

    options.url = EUREKA_URL + path;
    options.headers['x-society-id'] = config.societyId || null;
    let response;

    response = await axios(options);
    return response.data ? response.data : null;
};

const getAvailableAttendeesForEvent = (eventId, next) => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'get',
            `/api/entities/users?eventId=${eventId}`,
            null,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response.results);
                }
            },
        );
    });
};

const isUserAttending = eventId => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'post',
            `/api/entities/users/isUserAttending`,
            { eventId },
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });
};

const isUserAttendingByEmail = (eventId, email) => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'post',
            `/api/entities/users/isUserAttendingByEmail`,
            { eventId, email },
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response.isAttending);
                }
            },
        );
    });
};

const getFullProfile = () => {
    return new Promise((resolve, reject) => {
        eurekaRequest('get', '/api/entities/users/fullProfile/me', null, true, (err, response) => {
            if (err) {
                reject(err);
            } else {
                resolve(response);
            }
        });
    });
};

const getFullProfileById = id => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'get',
            `/api/entities/users/fullProfile/${id}`,
            null,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });
};

const getCountries = () => {
    return new Promise((resolve, reject) => {
        eurekaRequest('get', '/api/entities/countries', null, true, (err, response) => {
            if (err) {
                reject(err);
            } else {
                resolve(response);
            }
        });
    });
};

const updateFullProfile = data => {
    const Contact = data.Contact && {
        city: data.Contact.city,
        email: data.Contact.email,
        phone: data.Contact.phone,
        id: data.Contact.contactId,
        phoneCode: data.Contact.phoneCode,
        streetName: data.Contact.streetName,
        streetNumber: data.Contact.streetNumber,
        zip: data.Contact.zip,
    };
    const {
        imageUrl,
        coverUrl,
        companyName,
        firstName,
        headline,
        jobTitle,
        title,
        lastName,
        Links,
        Country,
    } = data;

    const dataParsed = {
        info: {
            companyName,
            firstName,
            headline,
            jobTitle,
            title,
            lastName,
            ...(data.tempAvatar && data.tempAvatar.url
                ? { imageUrl: data.tempAvatar.url }
                : { imageUrl }),
            ...(data.tempCover && data.tempCover.url
                ? { coverUrl: data.tempCover.url }
                : { coverUrl }),
        },
        ...(Country && Country.shouldUpdate ? { countryId: Country.id } : {}),
        links: Links.filter(link => link.shouldUpdate).map(({ id, url, type }) => ({
            url,
            type,
            ...(id ? { id } : {}),
        })),
        contact: {
            city: Contact && Contact.city,
            email: Contact && Contact.email,
            phone: Contact && Contact.phone,
            phoneCode: Contact && Contact.phoneCode,
            streetName: Contact && Contact.streetName,
            streetNumber: Contact && Contact.streetNumber,
            zip: Contact && Contact.zip,
            ...(Contact && Contact.contactId ? { id: Contact.contactId } : {}),
        },
    };

    return new Promise((resolve, reject) => {
        eurekaRequest('patch', '/api/entities/users/me', dataParsed, true, (err, response) => {
            if (err) {
                reject(err);
            } else {
                resolve(response);
            }
        });
    });
};

const convertFileToBase64 = file => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.replace(/^data:.+;base64,/, ''));
        reader.onerror = error => reject(error);
    });
};

const convertBase64ToFile = (dataurl, filename, type) => {
    var arr = dataurl.split(','),
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type });
};

const putFileToAmazonSignedUrl = async (signedUrl, file) => {
    try {
        let base64;
        let type;

        if (typeof file === 'string') {
            type = 'image/jpeg';
            base64 = file;
        } else {
            type = file.type;
            base64 = await convertFileToBase64(file);
        }

        const buffer = Buffer.from(base64, 'base64');

        return await axios({
            method: 'put',
            url: signedUrl,
            data: buffer,
            timeout: 20000,
            headers: {
                'Content-Type': `${type || 'image/jpeg'}; charset=utf-8`,
            },
        });
    } catch (e) {
        console.log('File parsing error:', e);
    }
};

const uploadFileToS3 = (file, tag) => {
    const { type, name } = file;
    const data = {
        filename: name || 'file.jpeg',
        type,
        ...(tag ? { tag } : {}),
    };

    return new Promise((resolve, reject) => {
        eurekaRequest(
            'post',
            '/api/entities/users/getUploadSignedLink',
            data,
            true,
            async (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    const { url: signedUrl, link: imageUrl, key } = response;
                    await putFileToAmazonSignedUrl(signedUrl, file);
                    resolve({
                        imageUrl,
                        key,
                    });
                }
            },
        );
    });
};

const getEventByReferenceId = id => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'get',
            `/api/entities/events/reference/${id}`,
            null,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });
};

const getUserSettings = eventId => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'get',
            `/api/entities/users/mySettings/${eventId || 'society'}`,
            null,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });
};

const updateUserSettings = data => {
    return new Promise((resolve, reject) => {
        eurekaRequest('put', '/api/entities/users/mySettings', data, true, (err, response) => {
            if (err) {
                reject(err);
            } else {
                resolve(response);
            }
        });
    });
};

export {
    eurekaRequest,
    asyncEurekaRequest,
    getVirtualEventToken,
    removeVirtualEventToken,
    updateVirtualEventToken,
    canJoinRoundTable,
    getVirtualEventSession,
    getVirtualEventSessionByAppointmentId,
    getVirtualEventSessionByAppointmentReference,
    logIn,
    register,
    checkEmail,
    syncExternalProfile,
    resetPassword,
    resendConfirmation,
    isAccountValidated,
    googleSignIn,
    sync,
    getAvailableAttendeesForEvent,
    updateUserEventInstallations,
    getFullProfile,
    getCountries,
    updateFullProfile,
    uploadFileToS3,
    getVirtualEventCaptions,
    deleteVirtualEventCaptions,
    getVirtualEventPollSets,
    getVirtualEventPollSetsByEventAndItemIds,
    submitPollAnswer,
    convertBase64ToFile,
    convertFileToBase64,
    putFileToAmazonSignedUrl,
    getFullProfileById,
    registerVirtualEventUser,
    updateVirtualEventUser,
    removeVirtualEventUser,
    getEventByReferenceId,
    isUserAttending,
    isUserAttendingByEmail,
    oAuthPasswordAuthenticate,
    getUserSettings,
    updateUserSettings,
    getLatestPrivacyPolicy,
    updateUserPrivacyPolicy,
    getPeerList,
    generatePKCEChallenge,
    getSocietyInfo,
    getInstitutionProposals,
    updateInstitutionProposalData,
    updateInstitutionProposalStatus,
};
