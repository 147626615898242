import React, { useState } from 'react';
import { CSVDownload } from 'react-csv';
import Button from '@mui/material/Button';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';

import { getVirtualEventCaptions } from '../../../../../../../../services/api/eureka';
import moment from 'moment/moment';

const EXPORT_CAPTIONS_BUTTON = 'Export captions';

const buttonStyle = {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    width: 'calc(100% - 56px)',
    marginLeft: '56px',
    textTransform: 'none',
    marginTop: '12px',
    marginBottom: '16px',
};

const ExportCaptions = ({ virtualEventSessionId }) => {
    const [csvData, setCsvData] = useState(null);
    const [chatExported, setChatExported] = useState(false);
    const [exportingCaptions, setExportingCaptions] = useState(false);

    const onExportCaptions = async () => {
        setExportingCaptions(true);
        setChatExported(false);
        setCsvData(null);

        const data = await getVirtualEventCaptions(virtualEventSessionId);
        if (!data || data.length === 0) {
            setExportingCaptions(false);
            return;
        }

        const parsedData = data.map(d => ({
            ...d,
            ...d.User,
            date: moment(d.timestamp).locale('en').format('YYYY-MM-DD HH:mm:ss'),
        }));

        // CSV header format
        const headers = [
            { label: 'Date', key: 'date' },
            { label: 'First Name', key: 'firstName' },
            { label: 'Last Name', key: 'lastName' },
            { label: 'Email', key: 'email' },
            { label: 'Message content', key: 'text' },
        ];
        setCsvData({ headers, data: parsedData });
        setChatExported(true);
        /*
        CSVDownload component will only download on mount
        so user will have to close and open this settingsModal to download it again.
        That's why exportingCaptions will remain true making the button look disabled
    */
        // setExportingChat(false);
    };

    return (
        <>
            <Button
                variant="contained"
                disabled={exportingCaptions}
                startIcon={<GetAppRoundedIcon />}
                disableElevation
                style={buttonStyle}
                onClick={onExportCaptions}
            >
                {EXPORT_CAPTIONS_BUTTON}
            </Button>
            {chatExported && <CSVDownload headers={csvData.headers} data={csvData.data} />}
        </>
    );
};

export default ExportCaptions;
