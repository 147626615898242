export const createImage = url => {
    if (!url) {
        return Promise.reject('url is miising');
    }
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', error => reject(error));
        image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
        image.src = url;
    });
};

function urltoFile(url, filename, mimeType) {
    mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1];
    return fetch(url)
        .then(function (res) {
            return res.arrayBuffer();
        })
        .then(function (buf) {
            return new File([buf], filename, { type: mimeType });
        });
}

export const getCroppedImg = async (originalImage, pixelCrop, outputDimensions) => {
    const image = await createImage(originalImage.dataURL);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
        return null;
    }

    /* setting canvas width & height allows us to
  resize from the original image resolution */
    canvas.width = outputDimensions.x;
    canvas.height = outputDimensions.y;

    ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        canvas.width,
        canvas.height,
    );

    return new Promise(async (resolve, reject) => {
        const dataUrl = canvas.toDataURL(originalImage?.file?.type || 'image/jpeg');
        const imageFile = await urltoFile(
            dataUrl,
            originalImage?.file?.name || 'eventImage',
            originalImage?.file?.type || 'image/jpeg',
        );
        const resizedImage = { dataURL: dataUrl, file: imageFile };
        resolve(resizedImage);
    });
};
