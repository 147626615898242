import React, { useState, useEffect } from 'react';
import FloqTabs from '../../../components/Tabs/FloqTabs';
import InstitutionInfoTab from '../components/InstitutionInfoTab';
import { default as MUIButton } from '@mui/material/Button/Button';
import { useHistory, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import {
    useInstitutionProposalsStore,
    useProposalByInstitutionId,
} from '../../../stores/InstitutionProposalStore';
import Loader from '../../../components/General/Loader';
import { Formik, useFormikContext } from 'formik';
import {
    updateInstitutionProposalData,
    updateInstitutionProposalStatus,
} from '../../../services/api/eureka';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import toaster from 'toasted-notes';
import NotificationToast from '../../../components/General/NotificationToast';
import Stack from '@mui/material/Stack';

export const tabs = [
    {
        label: 'Info',
        component: <InstitutionInfoTab />,
    },
    {
        label: 'Meetings',
        component: <div>Meetings</div>,
    },
    {
        label: 'Leads',
        component: <div>Leads</div>,
    },
    {
        label: 'Analytics',
        component: <div>Analytics</div>,
    },
];

const setFormikValues = data => ({
    name: data?.name || '',
    subNameList: data?.subNameList || '',
    imageUrl: data?.imageUrl || '',
    orderingName: data?.orderingName || '',
    subNameDetail: data?.subNameDetail || '',
    info: data?.info || '',
    cardImageUrl: data?.cardImageUrl || '',
    backgroundImageUrl: data?.backgroundImageUrl || '',
    bannerImageUrl: data?.bannerImageUrl || '',
    websiteUrl: data?.websiteUrl || '',
    websiteName: data?.websiteName || '',
});

const InstitutionProposal = () => {
    const [loading, setLoading] = useState(false);
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const history = useHistory();
    const { institutionId } = useParams();

    const proposal = useProposalByInstitutionId(institutionId);
    const fetchProposals = useInstitutionProposalsStore(state => state.fetchProposals);

    useEffect(() => {
        if (!institutionId) {
            history.goBack();
        }
        const fetchData = async () => {
            setLoading(true);
            await fetchProposals();
            setLoading(false);
        };
        fetchData();
    }, [institutionId]);

    const handleSubmit = async (values, submitProps) => {
        try {
            await updateInstitutionProposalStatus(proposal.id, {
                status: 'draft',
            });
            const data = pickBy(values, identity);
            const resp = await updateInstitutionProposalData(proposal.id, data);
            if (resp.data) {
                submitProps.resetForm({ values: setFormikValues(resp.data) });
            }
        } catch (error) {
            console.error('Error updating institution:', error);
        }
    };

    const handeStatusSubmitted = async () => {
        await updateInstitutionProposalStatus(proposal.id, {
            status: 'submitted',
        });
        toaster.notify(
            () => (
                <NotificationToast
                    title="Submitted for approval"
                    subtitle="Your changes are reviewed by the event organiser and will be published to attendees after they are approved."
                    icon="check"
                />
            ),
            {
                position: 'top-right',
                duration: 5000,
            },
        );
    };

    const onTabChange = index => {
        setActiveTabIndex(index);
    };

    if (!proposal || loading) {
        return <Loader />;
    }

    return (
        <Formik initialValues={setFormikValues(proposal.data)} onSubmit={handleSubmit}>
            {props => (
                <form onSubmit={props.handleSubmit}>
                    <FloqTabs
                        data={tabs}
                        activeIndex={activeTabIndex}
                        onChange={onTabChange}
                        TabBarRightComponent={
                            <SubmitSection
                                onSubmitButtonPress={handeStatusSubmitted}
                                proposal={proposal}
                            />
                        }
                    />
                </form>
            )}
        </Formik>
    );
};

const SubmitSection = ({ onSubmitButtonPress, proposal }) => {
    const formik = useFormikContext();
    const [saveLoading, setSaveLoading] = useState(false);
    const { handleSubmit: onSave } = formik;

    return (
        <Stack
            direction="row"
            alignItems="center"
            spacing={3}
            justifyContent="center"
            paddingTop={0.5}
        >
            <MUIButton
                type="submit"
                variant="contained"
                color="secondary"
                sx={{ height: 40, marginTop: 0.5 }}
                disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                onClick={async () => {
                    setSaveLoading(true);
                    await onSave();
                    setSaveLoading(false);
                }}
            >
                {saveLoading ? <CircularProgress size={20} /> : 'Save'}
            </MUIButton>
            <MUIButton
                variant="contained"
                sx={{ height: 40, marginTop: 0.5 }}
                onClick={onSubmitButtonPress}
                disabled={proposal.status !== 'draft'}
            >
                {formik.isSubmitting ? <CircularProgress size={20} /> : 'Submit for approval'}
            </MUIButton>
        </Stack>
    );
};

export default InstitutionProposal;
