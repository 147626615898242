import React, { useEffect, useState, useCallback, useRef } from 'react';
import ImageUploading from 'react-images-uploading';
import Cropper from 'react-easy-crop';
import Slider from '@mui/material/Slider';
import styled from 'styled-components';
import { styled as materialStyled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import { default as MUIButton } from '@mui/material/Button';
import { getCroppedImg } from './utils';
import { convertBase64ToFile, uploadFileToS3 } from '../../services/api/eureka';
import * as palette from '../General/Variables';
import { useTheme } from '../Theme/ThemeContext';
import Modal from '../Modal';

const DeleteIcon = styled(DeleteOutlineIcon)`
    position: absolute;
    top: 16px;
    right: 16px;
    color: ${palette.COLOR_WHITE};
`;

const IconFrame = styled(IconButton)`
    height: 80px;
    width: 80px;
    border-radius: 40px;
`;

const ImageFrame = styled(MUIButton)`
    width: 100%;
    flex-grow: 1;
    border-radius: 6px;
`;

const HelpText = styled(Typography)`
    color: ${palette.COLOR_TEXT_DISABLED};
    font-size: 13px;
    line-height: 20px;
`;

const ImageHelpText = styled(Typography)`
    color: ${palette.COLOR_TEXT_DISABLED};
    font-weight: 500;
    line-height: 24px;
`;

const SimplePopper = materialStyled(Popper)(({ theme }) => ({
    zIndex: theme.zIndex.modal,
}));

const FloqImageUploader = ({
    label,
    outputDimensions,
    icon,
    Icon = CloudUploadOutlinedIcon,
    borderType = 'dashed',
    boxStyle = {},
    imageUrl,
    maxMB = 2,
    initialImages = [],
    hasError = false,
    hideInputIcon = false,
    error = '',
    initialFrameHeight = 0,
    onChange: onParentChange,
    overlay = null,
    keepOriginalRatio = false,
    tag = '',
}) => {
    const { theme } = useTheme();
    const [images, setImages] = useState(initialImages);
    const [tempImages, setTempImages] = useState([]);
    const [loading, setLoading] = useState(false);

    const [hover, setHover] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [openCropper, setShowCropper] = useState(false);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [sliderValue, setSliderValue] = useState(0);

    const ratio = outputDimensions.x / outputDimensions.y;

    const [uploadedImageRatio, setUploadedImageRatio] = useState(ratio);

    const showEditor = Boolean(anchorEl);

    const imageFrameRef = useRef(null);
    const [imageFrameHeight, setImageFrameHeight] = useState(initialFrameHeight);
    const [keepRatioImageFrameRatio, setKeepRatioImageFrameRatio] = useState(0);

    useEffect(() => {
        const setImage = async () => {
            if (imageUrl === 'reset') {
                setImages([]);
            } else if (imageUrl) {
                setImages([
                    {
                        dataURL: imageUrl,
                    },
                ]);
            }
        };
        setImage();
    }, [imageUrl]);

    useEffect(() => {
        if (initialImages && initialImages.length) {
            setImages(initialImages);
        }
    }, [initialImages]);

    useEffect(() => {
        if (imageFrameRef?.current?.offsetWidth) {
            const height = imageFrameRef.current.offsetWidth / ratio;
            setImageFrameHeight(height);
        }
    }, [imageFrameRef.current]);

    const setCroppedImage = useCallback(async () => {
        setLoading(true);
        if (!tempImages[0]?.dataURL) {
            return;
        }

        try {
            const outputSize = keepOriginalRatio
                ? { x: outputDimensions.x, y: outputDimensions.x / uploadedImageRatio }
                : outputDimensions;
            const croppedImage = await getCroppedImg(tempImages[0], croppedAreaPixels, outputSize);
            if (!croppedImage) {
                return;
            }
            const file = await convertBase64ToFile(
                croppedImage.dataURL,
                croppedImage.file.name,
                croppedImage.file.type,
            );
            const s3data = await uploadFileToS3(file, tag);
            setImages([croppedImage]);
            setTempImages([]);
            onParentChange([s3data]);
            setLoading(false);
            closeCropper();
        } catch (e) {
            console.log('LOGGER e', e);
            setLoading(false);
        }
    }, [croppedAreaPixels, tempImages]);

    const openEditor = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseEditor = () => {
        setAnchorEl(null);
    };

    const closeCropper = () => {
        setShowCropper(false);
    };

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const onChange = (imageList, addUpdateIndex) => {
        setHover(false);
        if (imageList && imageList.length) {
            setShowCropper(true);
            setTempImages(imageList);
        } else {
            onParentChange([]);
            setTempImages([]);
            setImages([]);
        }
    };

    useEffect(() => {
        if (tempImages.length) {
            const img = new Image();
            img.src = tempImages?.[0].dataURL || '';
            img.onload = () => {
                setUploadedImageRatio(img.width / img.height);
                console.log(img.width / img.height);
            };
        }
    }, [tempImages]);

    useEffect(() => {
        if (images.length && keepOriginalRatio) {
            const img = new Image();
            img.src = images?.[0].dataURL || '';
            img.onload = () => {
                setKeepRatioImageFrameRatio(img.width / img.height);
            };
        }
    }, [images]);

    const getFrameBackgroundColor = (errors, isDragging) => {
        if (hasError || error) {
            return palette.COLOR_ERROR_SELECTED;
        }
        if (errors?.acceptType || errors?.maxFileSize) {
            return palette.COLOR_ERROR_SELECTED;
        } else {
            return isDragging ? palette.COLOR_SELECTED : 'transparent';
        }
    };

    const getFrameBorderColor = (errors, isDragging) => {
        if (hasError || error) {
            return 'red';
        }
        if (errors?.acceptType || errors?.maxFileSize) {
            return 'red';
        }
        return isDragging ? theme.primary : palette.SECONDARY_DARK;
    };
    const keepRatioIsVertical = keepRatioImageFrameRatio < 1;
    const keepRatioWidth = keepRatioIsVertical
        ? imageFrameHeight * keepRatioImageFrameRatio
        : imageFrameHeight;
    const keepRatioHeight = keepRatioIsVertical
        ? imageFrameHeight
        : imageFrameHeight / keepRatioImageFrameRatio;

    return (
        <ImageUploading
            acceptType={['jpg', 'png']}
            value={images}
            onChange={onChange}
            maxFileSize={maxMB * 1000000}
        >
            {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                isDragging,
                dragProps,
                errors,
            }) => (
                <div className="upload__image-wrapper">
                    <Box
                        sx={{
                            m: 0,
                            flexGrow: 1,
                            flexDirection: 'row',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        style={boxStyle}
                    >
                        {icon && (
                            <>
                                {!(imageList.length > 0) && (
                                    <>
                                        <Box
                                            sx={{
                                                m: '16px',
                                                ml: '0px',
                                            }}
                                        >
                                            <IconFrame
                                                sx={{
                                                    backgroundColor: getFrameBackgroundColor(
                                                        errors,
                                                        isDragging,
                                                    ),
                                                    border: `2px ${borderType} ${getFrameBorderColor(
                                                        errors,
                                                        isDragging,
                                                    )}`,
                                                    '&:hover': {
                                                        backgroundColor: isDragging
                                                            ? palette.COLOR_SELECTED
                                                            : palette.COLOR_HOVER,
                                                    },
                                                }}
                                                color="primary"
                                                aria-label="load icon"
                                                onClick={onImageUpload}
                                                {...dragProps}
                                            >
                                                <Icon
                                                    sx={{
                                                        color: isDragging
                                                            ? theme.primary
                                                            : palette.COLOR_TEXT_DISABLED,
                                                        fontSize: 25,
                                                    }}
                                                ></Icon>
                                            </IconFrame>
                                        </Box>
                                        <Box>
                                            <HelpText variant="body2">{`JPG or PNG, max ${maxMB}MB`}</HelpText>
                                            <HelpText variant="body2">{`${outputDimensions.x} x ${outputDimensions.y} px`}</HelpText>
                                        </Box>
                                    </>
                                )}
                                {imageList.length > 0 && (
                                    <Box
                                        sx={{
                                            m: '16px',
                                            ml: '0px',
                                        }}
                                    >
                                        <IconFrame
                                            sx={{
                                                backgroundImage: hover
                                                    ? `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("${imageList[0].dataURL}")`
                                                    : `url("${imageList[0].dataURL}")`,
                                                backgroundSize: 'contain',
                                                border: '0px solid white',
                                                backgroundRepeat: 'no-repeat',
                                            }}
                                            onMouseEnter={e => {
                                                if (!isDragging) {
                                                    setHover(true);
                                                }
                                            }}
                                            onMouseLeave={e => {
                                                setHover(false);
                                                handleCloseEditor();
                                            }}
                                            onClick={openEditor}
                                        >
                                            {hover && (
                                                <EditIcon
                                                    sx={{ color: palette.COLOR_WHITE }}
                                                ></EditIcon>
                                            )}
                                            <SimplePopper
                                                id="basic-menu"
                                                anchorEl={anchorEl}
                                                open={!showEditor}
                                                onResize={undefined}
                                                onResizeCapture={undefined}
                                                placeholder={undefined}
                                                onPointerEnterCapture={undefined}
                                                onPointerLeaveCapture={undefined}
                                            >
                                                <Paper elevation={3}>
                                                    <MenuItem
                                                        onClick={() => {
                                                            onImageUpdate(0);
                                                            handleCloseEditor();
                                                        }}
                                                    >
                                                        Change image
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() => {
                                                            onImageRemoveAll();
                                                            handleCloseEditor();
                                                        }}
                                                    >
                                                        Delete
                                                    </MenuItem>
                                                </Paper>
                                            </SimplePopper>
                                        </IconFrame>
                                    </Box>
                                )}
                            </>
                        )}
                        {!icon && (
                            <Box
                                sx={{
                                    m: '16px',
                                    ml: '0px',
                                    mr: '0px',
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                {!(imageList.length > 0) && (
                                    <ImageFrame
                                        sx={{
                                            backgroundColor: getFrameBackgroundColor(
                                                errors,
                                                isDragging,
                                            ),
                                            border: `2px ${borderType} ${getFrameBorderColor(
                                                errors,
                                                isDragging,
                                            )}`,
                                            height: imageFrameHeight || 0,
                                            '&:hover': {
                                                backgroundColor: isDragging
                                                    ? palette.COLOR_SELECTED
                                                    : palette.COLOR_HOVER,
                                            },
                                        }}
                                        ref={imageFrameRef}
                                        color="primary"
                                        aria-label="load icon"
                                        onClick={onImageUpload}
                                        {...dragProps}
                                    >
                                        <Box>
                                            {!hideInputIcon && (
                                                <CloudUploadOutlinedIcon
                                                    sx={{
                                                        color: isDragging
                                                            ? theme.primary
                                                            : palette.COLOR_TEXT_DISABLED,
                                                        fontSize: 30,
                                                    }}
                                                />
                                            )}

                                            <ImageHelpText variant="body2">
                                                Drag and drop or <Link>browse</Link>
                                            </ImageHelpText>
                                            <HelpText
                                                sx={{ lineHeight: '24px' }}
                                                variant="body2"
                                            >{`JPG or PNG, max ${maxMB}MB - ${
                                                keepOriginalRatio ? 'recommended ' : ''
                                            }${outputDimensions.x} x ${
                                                outputDimensions.y
                                            } px`}</HelpText>
                                        </Box>
                                    </ImageFrame>
                                )}
                                {imageList.length > 0 && (
                                    <Box sx={{ position: 'relative' }}>
                                        <Box
                                            sx={{
                                                backgroundImage: hover
                                                    ? `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("${imageList[0].dataURL}")`
                                                    : overlay
                                                    ? `linear-gradient(17deg, rgba(0, 0, 0, 0.6) 30%, rgba(0, 0, 0, 0) 73%), url("${imageList[0].dataURL}")`
                                                    : `url("${imageList[0].dataURL}")`,
                                                backgroundSize: 'contain',
                                                border: '0px solid white',
                                                backgroundRepeat: 'no-repeat',
                                                height: keepOriginalRatio
                                                    ? keepRatioHeight
                                                    : imageFrameHeight || 0,
                                                width: keepOriginalRatio ? keepRatioWidth : '100%',
                                                flexGrow: '1',
                                                borderRadius: '6px',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                display: 'flex',
                                                position: 'relative',
                                            }}
                                            onMouseEnter={e => {
                                                if (!isDragging) {
                                                    setHover(true);
                                                }
                                            }}
                                            onMouseLeave={e => {
                                                setHover(false);
                                                handleCloseEditor();
                                            }}
                                        >
                                            {!hover && overlay}
                                            {hover && (
                                                <DeleteIcon
                                                    onClick={e => {
                                                        onImageRemoveAll();
                                                    }}
                                                    sx={{ cursor: 'pointer' }}
                                                />
                                            )}
                                            {hover && (
                                                <MUIButton
                                                    onClick={() => {
                                                        onImageUpdate(0);
                                                    }}
                                                    variant="outlined"
                                                    color="secondary"
                                                    sx={{
                                                        color: palette.COLOR_WHITE,
                                                        backgroundColor: 'transparent',
                                                    }}
                                                >
                                                    Change image
                                                </MUIButton>
                                            )}
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        )}
                    </Box>
                    {errors?.acceptType && (
                        <FormHelperText error>Select a JPG or PNG image</FormHelperText>
                    )}
                    {errors?.maxFileSize && (
                        <FormHelperText error>{`Select an image of max ${maxMB}MB`}</FormHelperText>
                    )}
                    {error && !errors && <FormHelperText error>{error}</FormHelperText>}
                    <Modal
                        title={label}
                        open={openCropper && !errors?.acceptType && !errors?.maxFileSize}
                        onClose={closeCropper}
                        actions={[
                            {
                                label: 'Save',
                                onClick: () => {
                                    setCroppedImage();
                                },
                                variant: 'contained',
                                color: 'primary',
                                disabled: loading,
                                loading: false,
                            },
                            {
                                label: 'Cancel',
                                onClick: closeCropper,
                                disabled: loading,
                                variant: 'contained',
                                color: 'secondary',
                            },
                        ]}
                        size="xs"
                        loading={false}
                    >
                        <Box sx={{ flexGrow: 1, justifyContent: 'center' }}>
                            <Grid container spacing={8} sx={{ mb: '16px' }}>
                                <Grid flexDirection="column" item>
                                    <Typography variant="subtitle1">File format</Typography>
                                    <Typography variant="subtitle1">Dimensions</Typography>
                                    <Typography variant="subtitle1">File size</Typography>
                                </Grid>
                                <Grid flexDirection="column" item>
                                    <Typography variant="subtitle1">PNG or JPG</Typography>
                                    <Typography variant="subtitle1">{`${outputDimensions.x} x ${outputDimensions.y} px`}</Typography>
                                    <Typography variant="subtitle1">{`up to ${maxMB}MB`}</Typography>
                                </Grid>
                            </Grid>
                            <MUIButton
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    onImageUpdate(0);
                                }}
                            >
                                Select image
                            </MUIButton>
                            <Box flexDirection="row" flexGrow="1" justifyItems="center">
                                <Box
                                    sx={{
                                        mt: '40px',
                                        mb: '17px',
                                        alignSelf: 'center',
                                        justifySelf: 'center',
                                        display: 'flex',
                                        width: '100%',
                                        height: '212px',
                                        backgroundColor: palette.UI_GREY_3,
                                        borderRadius: '8px',
                                        border: '1px solid white',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            top: '0px',
                                            left: '0px',
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    >
                                        <Cropper
                                            image={tempImages[0]?.dataURL}
                                            crop={crop}
                                            zoom={zoom}
                                            aspect={keepOriginalRatio ? uploadedImageRatio : ratio}
                                            onCropChange={setCrop}
                                            onCropComplete={onCropComplete}
                                            onZoomChange={setZoom}
                                            cropShape={icon ? 'round' : 'rect'}
                                            showGrid={false}
                                            style={{
                                                containerStyle: {
                                                    borderRadius: '8px',
                                                    border: '1px solid white',
                                                },
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                {loading && (
                                    <Box sx={{ width: '100%' }}>
                                        <LinearProgress />
                                    </Box>
                                )}
                                {!loading && (
                                    <Slider
                                        aria-label="Volume"
                                        value={sliderValue}
                                        onChange={(e, value) => {
                                            setSliderValue(value);
                                            setZoom(value);
                                        }}
                                        min={1}
                                        max={3}
                                        step={0.01}
                                    />
                                )}
                            </Box>
                        </Box>
                    </Modal>
                </div>
            )}
        </ImageUploading>
    );
};

export default FloqImageUploader;
