import React from 'react';
import MicNoneIcon from '@mui/icons-material/MicNone';
import styled from 'styled-components';

import BasicDialog from './Dialog/BasicDialog';

export const MicMutedIcon = styled.div`
    background-color: #ffffff;
    border: solid 1px #cccccc;
    border-radius: 50%;
    cursor: pointer;
    height: 48px;
    margin: 0 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    position: relative;

    &:after {
        background: #ee1045;
        content: '!';
        display: block;
        position: absolute;
        height: 24px;
        width: 24px;
        top: -5px;
        right: -5px;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        font-weight: bold;
        font-family: Roboto, sans-serif;
        line-height: 24px;
    }
`;

const Container = styled.div`
    align-items: center;
    display: flex;
    flex-flow: column;
    text-align: center;
`;

const StyledParagraph = styled.p`
    font-family: Cabin, sans-serif;
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #000;
    margin-left: 24px;
    margin-right: 24px;
`;

const StyledInfo = styled.span`
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
`;

const MicMutedDialog = ({ onClose }) => {
    return (
        <BasicDialog
            visible={true}
            onClose={onClose}
            options={[
                {
                    title: 'Dismiss',
                    variant: 'contained',
                    color: 'inherit',
                    onClick: onClose,
                },
            ]}
        >
            <Container>
                <MicMutedIcon data-qa="microphone-button">
                    <MicNoneIcon />
                </MicMutedIcon>

                <StyledParagraph>Your mic is muted by your system settings</StyledParagraph>

                <StyledInfo>Go to your computer settings to unmute your mic.</StyledInfo>
            </Container>
        </BasicDialog>
    );
};

export default MicMutedDialog;
