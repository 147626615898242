import React, { useCallback } from 'react';
import {
    SPEECH_RECOGNITION_LANGUAGES,
    useTranslationsStore,
} from '../../../../../../../../stores/TranslationsStore';
import { FormControl, MenuItem, Select } from '@mui/material';

function LanguageSelect({
    value,
    onChange,
    id,
    useSpeechRecognitionLanguages = false,
    fullWidth = false,
}) {
    const { supportedLanguages, setSelectedLanguage, selectedLanguage } = useTranslationsStore();

    const onChangeLanguage = useCallback(
        event => {
            if (onChange) {
                return onChange(event.target.value);
            }

            setSelectedLanguage(event.target.value);
        },
        [setSelectedLanguage, onChange],
    );

    // This is a hack to make sure that we use the correct language for setting speech recognition language because the list is different from the list of supported languages
    const languagesToDisplay = useSpeechRecognitionLanguages
        ? SPEECH_RECOGNITION_LANGUAGES
        : supportedLanguages;

    return (
        <>
            <FormControl sx={{ width: fullWidth ? '100%' : 'auto' }}>
                <Select
                    labelId={`byrdhouse-language-select-label${id ? '-' + id : ''}`}
                    id={`byrdhouse-language-select-label${id ? '-' + id : ''}`}
                    value={value || selectedLanguage}
                    onChange={onChangeLanguage}
                    variant="filled"
                    disableUnderline
                    sx={{
                        padding: '12px',
                        borderRadius: '8px',
                        '& .MuiSelect-select': {
                            padding: '9px 14px',
                        },
                    }}
                >
                    {languagesToDisplay.map(language => (
                        <MenuItem key={language.value} value={language.value}>
                            {language.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
}
export default LanguageSelect;
