import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';
import { Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const StyledDialog = muiStyled(Dialog)(() => ({
    '& .MuiDialog-paper': {
        borderRadius: 16,
        width: 980,
    },
}));

const contentStyle = {
    padding: 24,
};

const Img = styled.img`
    height: 612px;
    width: 931px;
    border-radius: 8px;
    z-index: 1000;
`;

const FlexRow = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 24px;
`;

const EnlargedImageModal = ({ open, handleClose, imageUrl }) => (
    <StyledDialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogContent style={contentStyle}>
            <FlexRow>
                <IconButton onClick={handleClose} size="large">
                    <CloseIcon />
                </IconButton>
            </FlexRow>
            <Img src={imageUrl} />
        </DialogContent>
    </StyledDialog>
);

export default EnlargedImageModal;
