import React from 'react';
import styled from 'styled-components';

import DoneIcon from '@mui/icons-material/Done';

const FlexRow = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: ${props => (props.height ? props.height : '48px')};
    width: 100%;
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: ${props => (props.isrecording ? '8px' : props.small ? '8px' : '16px')};
`;

const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 100%;
    border-radius: ${props => (props.inverted ? '0 6px 6px 0' : '6px 0 0 6px')};
    background-color: ${props => props.color};
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    margin-right: 2px;
`;

const RightSide = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    border-radius: ${props => (props.shouldDisplayCheckmark ? '0' : '0 6px 6px 0')};
    background-color: #f1f1f3;
    color: rgba(0, 0, 0, 0.38);
    font-size: 15px;
    font-weight: normal;
    border: none !important;
    z-index: 9;
    overflow: hidden;
`;

const RightSideOverlay = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 12px;
    width: ${props => props.width};
    min-width: 40px;
    height: 100%;
    border-radius: 0 4px 4px 0;
    box-shadow: 2px 2px 16px 0 rgba(41, 41, 44, 0.25);
    background-color: ${props => props.color};
    z-index: 11;

    & > * {
        font-family: Roboto, sans-serif;
        font-size: ${props => (props.presentation ? '21px' : '13px')};
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #fff;
    }

    ${props =>
        props.morehorizontalpadding &&
        `
        & > * {
            position: absolute;
            right: 64px;
        }
    `}
`;

const Title = styled.span`
    height: 24px !important;
    font-family: Cabin, sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.85;
    letter-spacing: 0.2px;
    color: rgba(0, 0, 0, 0.54);
    margin-bottom: ${props => (props.isrecording ? '2px' : '8px')};
`;

const PresentationTitle = styled.p`
    font-family: Roboto;
    font-size: ${props => (props.small ? '20px' : '24px')};
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: ${props => (props.small ? '24px' : '28px')};
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: ${props => (props.small ? '8px' : '16px')};
`;

const PercentageContainer = styled.div`
    position: absolute;
    right: ${props => (props.isrecording ? '2px' : '8px')};
    top: ${props => (props.isrecording ? '2px' : '12px')};
    width: ${props => (props.isrecording ? '41px' : '48px')};
    height: ${props => (props.isrecording ? '20px' : '24px')};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: #fff;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
    z-index: 12;
`;

const PresentationPercentageContainer = styled.div`
    position: absolute;
    right: 8px;
    top: ${props => (props.small ? '4px' : '6px')};
    width: 48px;
    height: ${props => (props.small ? '24px' : '28px')};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: #fff;
    font-family: Roboto, sans-serif;
    font-size: 17px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
    z-index: 12;
`;

const DoneIconWrapper = styled.div`
    color: ${props => props.color};
    position: absolute;
    left: -40px;
    top: 12px;
`;

const VoteResult = ({
    label,
    option,
    color,
    displayMode,
    isCurrentUserActive,
    totalVotes,
    isForRecording,
    presentation,
    smallerFonts,
    hideVotesNumber,
}) => {
    const percentage = Math.floor((option.votes / totalVotes) * 100);
    const votePercentage = totalVotes !== 0 ? `${percentage}%` : '0%';
    let resultHeight = isForRecording ? '24px' : '48px';
    if (presentation) {
        resultHeight = smallerFonts ? '32px' : '40px';
    }

    return (
        <FlexColumn small={smallerFonts} isrecording={isForRecording ? 1 : 0}>
            {presentation && (
                <PresentationTitle small={smallerFonts}>{option.text}</PresentationTitle>
            )}
            {!presentation && <Title isrecording={isForRecording ? 1 : 0}>{option.text}</Title>}
            <FlexRow height={resultHeight}>
                {option.correct && isCurrentUserActive && (
                    <DoneIconWrapper color={color}>
                        <DoneIcon />
                    </DoneIconWrapper>
                )}
                <LabelWrapper color={color} notEmpty={option.text !== ''}>
                    {label}
                </LabelWrapper>
                <RightSide color={color} notEmpty={option.text !== ''} displayMode={displayMode}>
                    <RightSideOverlay
                        color={color}
                        width={votePercentage}
                        morehorizontalpadding={percentage > 90 ? percentage : 0}
                        presentation={presentation}
                    >
                        {!hideVotesNumber && <div>{option.votes}</div>}
                    </RightSideOverlay>
                    {!presentation && (
                        <PercentageContainer isrecording={isForRecording ? 1 : 0}>
                            {votePercentage}
                        </PercentageContainer>
                    )}
                    {presentation && (
                        <PresentationPercentageContainer small={smallerFonts}>
                            {votePercentage}
                        </PresentationPercentageContainer>
                    )}
                </RightSide>
            </FlexRow>
        </FlexColumn>
    );
};

export default VoteResult;
