import styled from 'styled-components';
import { Avatar, Button, DialogContainer, NavigationDrawer } from 'react-md';
import * as palette from '../../../components/General/Variables';
import { MenuItem, Popover } from '@mui/material';
import { Flex } from '../../../scenes/common/Flex';

export const ButtonStyled = styled(Button)`
    border: 1px solid ${props => props.primarycolor} !important;
    margin-left: 16px !important;
    margin-top: 27px !important;
    margin-bottom: 27px !important;
    border-radius: ${props => (props.theming === 'inversed' ? '2px' : '4px')} !important;
    color: ${props =>
        props.theming === 'inversed' ? props.primarycolor : palette.COLOR_WHITE} !important;
    background-color: ${props =>
        props.theming === 'inversed' ? palette.COLOR_WHITE : props.primarycolor} !important;
    display: block;

    &.md-btn--dialog,
    &.md-btn--text {
        text-transform: none;
        font-size: 16px;
        height: 36px;
        font-weight: 500;
        padding: 8px 16px;
    }

    &:hover {
        background-color: ${props =>
            props.theming === 'inversed' ? palette.COLOR_WHITE : props.primarycolor};
    }
`;

export const DialogContainerStyled = styled(DialogContainer)`
    .md-dialog-content--padded {
        padding-bottom: 0;
    }
    .md-dialog-footer--inline {
        padding: 0 15px;
    }
`;

export const Base = styled.div`
    min-height: auto;
    overflow-y: auto;
    height: 100vh;
`;
export const Hidden = styled.p`
    display: none;
    height: 0px;
`;
export const LoaderContainer = styled.div`
    text-align: center;
    width: 100%;
    padding: 40px;
    padding-top: 200px;
    border-top: 1px solid #e5e5e5;
    @media only screen and (min-width: ${palette.MIN_TABLET}) {
        padding: 60px;
        padding-top: 200px;
    }
    @media only screen and (min-width: ${palette.MIN_DESKTOP}) {
        padding: 25%;
        padding-top: 200px;
    }

    h4 {
        font-size: 18px;
        font-weight: 500;
        color: ${palette.COLOR_TEXT};
    }
    p {
        font-size: 14px;
        color: ${palette.COLOR_TEXT};
    }
`;

export const Margin = styled.div`
    height: 72px;
`;

export const EmptyState = styled.div`
    text-align: center;
    padding-top: 100px;

    h4 {
        font-size: 18px;
        font-weight: 500;
        color: ${palette.COLOR_TEXT};
    }
    p {
        font-size: 14px;
        color: ${palette.COLOR_TEXT};
    }
`;

export const PlaceholderImage = styled.div`
    background-image: url('/images/not_available.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100%;
    max-width: 500px;
    height: 500px;
    margin: auto;
`;

export const EventNotFoundImage = styled.div`
    background-image: url('/images/event-not-found.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100%;
    max-width: 500px;
    height: 500px;
    margin: auto;
`;

export const toolbarStyle = {
    position: 'absolute',
    backgroundColor: palette.COLOR_WHITE,
    display: 'flex',
    alignItems: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
    borderBottom: '1px solid #efefef',
    height: '56px !important',
};

export const toolbarTitleStyle = {
    fontFamily: 'Cabin, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '21px !important',
    lineHeight: '24px',
    transition: 'all 0.2s linear',
};

export const Title = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: 21px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);
    overflow: hidden;
    max-width: fit-content;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
`;

export const StyledNavigationDrawer = styled(NavigationDrawer)`
    .md-toolbar {
        border: unset;
        height: 56px !important;
        border-bottom: none !important;
        background: ${props =>
            props.hasSolidTopBackground
                ? props.primary
                : 'linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.3) 30%,rgba(0, 0, 0, 0.1) 70%,rgba(0, 0, 0, 0) 100% )'} !important;
    }

    .md-toolbar-relative {
        margin-top: ${props => (props.hasTopMargin ? `56px` : '0px')} !important;
    }
    .md-drawer--left {
        width: ${props => (props.railnavigation ? '72px' : '260px')} !important;
        transition: width 0.2s linear;
    }
    .md-toolbar--action-right:first-of-type {
        width: 100%;
        margin-left: 16px !important;
    }
    .md-title {
        color: ${props => props.contrastBlackOrWhite} !important;
        margin-left: ${props => (props.railnavigation ? '40px' : '230px')} !important;
    }
`;

export const DrawerHeader = styled(Flex)`
    padding: 8px 0 8px 16px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.6);
    div {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
`;

export const EventIcon = styled(Avatar)`
    border: unset !important;
    margin-right: 16px;
    ${props => props.marginLeft && `margin-left: ${props.marginLeft}`}
    min-width: 40px;
    cursor: pointer;
`;

export const ExpandButton = styled.div`
    width: 32px;
    height: 32px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 2px 8px 0 rgba(0, 0, 0, 0.05),
        0 0 1px 0 rgba(0, 0, 0, 0.15);
    z-index: 9999;
    ${props => props.expanded && `transform: matrix(-1, 0, 0, 1, 0, 0)`};
    border-radius: 50%;
    position: absolute;
    top: 110px;
    left: ${props => (props.isMobile ? 'unset' : !props.expanded ? '58px' : '243px')};
    cursor: pointer;
    transition: all 0.2s linear;
    i {
        color: ${props => props.primary};
    }

    &:hover {
        background: ${props => props.primary};
        i {
            color: #fff;
        }
    }
`;

export const Paragraph = styled.p`
    line-height: 1.43;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
`;

export const AppContent = styled.div`
    height: ${props => (props.noHeightCalc ? '100vh' : 'calc(100vh - 57px)')};
    min-height: auto;
    overflow-y: auto;
    position: relative !important;
    ${props => props.isSidePanelOpened && `width: calc(100vw - 497px)`};
`;

export const TooltipContainer = styled.div`
    width: ${props => props.width};
    left: ${props => props.left};
    top: ${props => props.top};
    position: absolute;
    display: flex;
    height: 50px;
    justify-content: center;

    div {
        width: fit-content;
        padding: 4px 8px;
        height: 28px;
        background: rgba(0, 0, 0, 0.87);
        border-radius: 4px;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        color: rgba(255, 255, 255, 0.87);
        font-stretch: normal;
        letter-spacing: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 21;
        white-space: nowrap;
    }
`;

export const StyledPopover = styled(Popover)`
    .MuiPopover-paper {
        border-radius: 8px !important;
        box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2), 0px 0px 4px rgba(0, 0, 0, 0.1) !important;
    }

    li {
        margin: 0 8px !important;
        border-radius: 8px !important;
        font-family: 'Roboto', sans-serif !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 15px !important;
        line-height: 24px !important;
        color: #000000;
        &:first-of-type {
            margin-top: 8px !important;
        }
        &:last-of-type {
            margin-bottom: 8px !important;
        }
        &:hover {
            background: #ebedf0 !important;
        }
    }
`;

export const MenuItemMobile = styled(MenuItem)`
    color: ${props => (props.active ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.6)')} !important;
    i {
        color: inherit;
        margin-right: 12px;
    }

    i:last-of-type {
        ${props =>
            props.active &&
            `
        margin-right: 0;
        margin-left: auto;
        `};
    }
`;

export const TabsContainer = styled.div`
    height: 100%;
    width: 60%;
    margin: auto;
    @media only screen and (max-width: ${palette.MAX_DESKTOP}) {
        width: 50%;
    }
    @media only screen and (max-width: ${palette.MAX_TABLET}) {
        width: 100%;
    }
`;
