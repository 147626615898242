import styled from 'styled-components';
import { FontIcon } from 'react-md';

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 8px;
    justify-content: space-between;
`;

export const TitleIcon = styled(FontIcon)`
    color: rgba(0, 0, 0, 0.87);
    cursor: pointer;

    ${props => props.hidden && 'opacity: 0;'}
`;

export const TitleText = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
`;

export const ContentContainer = styled.div`
    padding: ${props => (props.noPadding ? '0' : '12px 24px 24px 24px')};
    display: flex;
    flex-direction: column;
    align-items: center;
`;
