import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import PhonelinkOffIcon from '@mui/icons-material/PhonelinkOff';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import VideocamOffIcon from '@mui/icons-material/VideocamOffOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { useVMMutation, useVMState, useVMVolumeState } from '../../../containers/main';
import StreamPlayer from '../../common/StreamPlayer';
import entities from '../../../constants/entities';
import { LightTooltip, RedStyledChip, VideoSwitchOutlinedWrapper } from '../common/styles';
import VoiceIndicator from '../../common/VoiceIndicator';
import { stopPreRecordings } from '../../../services/VirtualEventSession';
import Stack from '@mui/material/Stack';

const { broadcasting } = entities.virtualEventSessionEntity.status;

const VirtualUserWrapper = styled.div`
    display: flex;
    position: relative;
    border-radius: 8px;
    background-color: ${props => props.backgroundColor};
    box-shadow: ${props => (props.isHovered ? '0 2px 6px 0 rgba(0, 0, 0, 0.2)' : '')};
    margin-bottom: 8px;
    z-index: 1;
`;

const ProfileImageWrapper = styled.div`
    align-items: center;
    background-color: ${props => (props.isoffline ? '#b7b8bf' : '#5a5b61')};
    color: white;
    display: flex;
    font-size: 48px;
    height: 90px;
    min-width: 160px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    justify-content: center;

    .stream-player.video-on {
        height: 100%;
        width: 100%;

        [id*='player'] {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }

        video {
            background: #fff;
            object-fit: cover !important;
            /* This used to work for the parent element of button divs */
            /* But it does not work with newer browsers, the below doesn't hide the play button parent div */

            &::-webkit-media-controls-panel {
                display: none !important;
                -webkit-appearance: none;
            }

            /* Old shadow dom for play button */

            &::-webkit-media-controls-play-button {
                display: none !important;
                -webkit-appearance: none;
            }

            /* New shadow dom for play button */

            /* This one works! */

            &::-webkit-media-controls-start-playback-button {
                display: none !important;
                -webkit-appearance: none;
            }
        }
    }
`;

const VirtualUserTextWrapper = styled.div`
    flex: 1;
    margin: 0 8px;
    padding: 0 20px 0 0;
    width: 100%;
`;

const VirtualUserTitle = styled.p`
    margin: 0;
    color: rgba(0, 0, 0, 0.87);
    height: fit-content;
    font-size: 16px;
    font-family: Cabin, sans-serif;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const VirtualUserTextDetails = styled.p`
    color: rgba(0, 0, 0, 0.6) !important;
    height: fit-content;
    font-family: Cabin, sans-serif !important;
    font-size: 10px !important;
    letter-spacing: 0.5px !important;
    line-height: 1.6 !important;
    margin: 0;
    font-weight: 500 !important;
`;

const VirtualUserOccupationText = styled.p`
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px !important;
    line-height: 1.43 !important;
    font-weight: normal;
    margin: 0 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const VirtualUserContentWrapper = styled.div`
    align-items: center;
    display: flex;
    height: fit-content;
    justify-content: space-between;
    margin: 8px 16px 8px 16px;
    min-width: 50%;
    height: 76px;
    width: 100%;
`;

const VirtualUserTitleWrapper = styled.div`
    display: inline-flex;
    align-items: start;
    width: 100%;
`;

const MicSwitchWrapper = styled.div`
    position: absolute;
    left: 6px;
    bottom: 8px;
    z-index: 1;
    font-size: 18px;
    align-items: center;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    transition: all 0.1s linear;
    width: 24px;
    height: 24px;
    color: #fff;
    background: ${props => (props.isMicrophoneOn ? 'transparent' : '#d50000')};
`;

const AccountCircleIconWrapper = styled.div`
    font-size: 48px;
    opacity: ${props => (props.imageUrl ? 1 : 0.2)};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('${props => props.imageUrl}');
    width: 48px;
    height: 48px;
    border-radius: 50%;
`;

const StyledIconOutlinedWrapper = styled(VideoSwitchOutlinedWrapper)`
    margin-right: 8px;
`;

const ActionButtonsContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    background-color: #f1f1f3;
    border-radius: 48px;
    right: 8px;
`;

const VirtualSessionUser = props => {
    const mutationCtx = useVMMutation();
    const stateCtx = useVMState();
    const volumeStateCtx = useVMVolumeState();

    const { localStream, virtualUser, virtualUserIndex, isPanelDiscussion } = props;
    const { isActive, isMicrophoneOn, isVideoOn, User: user, isPrerecording } = virtualUser;
    const { currentUser, sessionId, socket, streams, virtualEventSession, peers } = stateCtx;
    const { volumeIndicators } = volumeStateCtx;

    const [displayActions, setDisplayActions] = useState(false);
    const isCurrentUser = useMemo(() => {
        return currentUser && user.id === currentUser.id;
    }, [get(currentUser, 'id'), get(user, 'id')]);

    const videoSwitchWrapperColor = 'rgba(0, 0, 0, 0.54)';
    const videoSwitchWrapperColorHover = 'rgba(0, 0, 0, 0.04)';
    const switchIconColorHover = 'rgba(0, 0, 0, 0.87)';
    const onSwitchIsActiveClick = () => onSwitchIsActive(virtualUserIndex);
    const tooltipVideoMessage = isActive
        ? 'Unselect as the presenting speaker'
        : 'Select as the presenting speaker';
    const isOffline = peers && !peers.find(peerId => peerId === user.id);
    const userRole =
        virtualUser.role === 'presenter'
            ? 'Speaker'
            : virtualUser.role[0].toUpperCase() + virtualUser.role.slice(1);
    const virtualEventUsers = get(virtualEventSession.data, 'VirtualEventUsers') || [];

    const streamToUse = useMemo(() => {
        const isBroadcasting = get(virtualEventSession.data, 'status') === broadcasting;
        const userId = get(user, 'id');
        const virtualEventUser = virtualEventUsers.find(vUser => vUser.UserId === userId);
        let streamId = isCurrentUser ? get(localStream, 'current.streamId') : get(user, 'id');

        if (virtualEventUser && virtualEventUser.rtmpInfo) {
            streamId = virtualEventUser.rtmpInfo.uid;
        }

        if (isPrerecording && !isBroadcasting) {
            return null;
        }

        return streams.find(st => st.streamId === streamId);
    }, [isCurrentUser, stateCtx, localStream.current, streams, isPrerecording]);
    const isActiveStreamRtmp =
        streamToUse && streamToUse.streamId < 100 && streamToUse.streamId > 0;

    const muteStream = async e => {
        e.stopPropagation();
        if (streamToUse) {
            socket.emit('updateData', {
                objectId: sessionId,
                virtualEventSession: {
                    VirtualEventUsers: [
                        {
                            id: virtualUser.id,
                            isMicrophoneOn: !isMicrophoneOn,
                        },
                    ],
                },
            });
        }
    };

    const turnCameraOff = async e => {
        e.stopPropagation();
        if (streamToUse) {
            socket.emit('updateData', {
                objectId: sessionId,
                virtualEventSession: {
                    VirtualEventUsers: [
                        {
                            id: virtualUser.id,
                            isVideoOn: false,
                        },
                    ],
                },
            });
        }
    };

    const onSwitchIsActive = async virtualUserIndex => {
        const { externalObject, videoIsPlaying } = stateCtx;
        const doSwitch = async () => {
            const cloneVirtualEventSession = cloneDeep(virtualEventSession);
            const virtualEventUsers = cloneVirtualEventSession.data.VirtualEventUsers;
            const isActiveNew = !virtualEventUsers[virtualUserIndex].isActive; // switch active state;

            socket.emit('updateData', {
                objectId: sessionId,
                virtualEventSession: {
                    VirtualEventUsers: [
                        {
                            id: virtualEventUsers[virtualUserIndex].id,
                            isActive: isActiveNew,
                            hasVideoActive: false,
                        },
                    ],
                    lastSpeakerSwitchTimestamp: Date.now(),
                    activeVideoId: null,
                    showVoteResults: false,
                },
                updateCall: true,
            });

            stateCtx.socket.emit('updatePollSet', {
                externalObjectId: externalObject.data.id,
                state: 'closed',
                deActivatePoll: true,
            });

            await stopPreRecordings(sessionId);
        };

        if (videoIsPlaying) {
            mutationCtx.setExtraState({
                showPreventStopDialogWithCallback: yes => yes && doSwitch(),
            });
            return;
        }

        await doSwitch();
    };

    const volumeLevel = useMemo(() => {
        const indicator = volumeIndicators.find(
            vInd => `${get(streamToUse, 'streamId')}` === `${vInd.uid}`,
        );

        return indicator ? indicator.level : 0;
    }, [volumeIndicators, streamToUse]);

    return (
        <VirtualUserWrapper
            onMouseEnter={() => setDisplayActions(true)}
            onMouseLeave={() => setDisplayActions(false)}
            backgroundColor={'#f1f1f3'}
            isHovered={displayActions}
        >
            {streamToUse && (
                <MicSwitchWrapper isMicrophoneOn={isMicrophoneOn}>
                    {isMicrophoneOn && <VoiceIndicator level={volumeLevel} wrapper />}
                    {!isMicrophoneOn && <MicOffOutlinedIcon fontSize={'inherit'} />}
                </MicSwitchWrapper>
            )}
            <ProfileImageWrapper isoffline={isOffline}>
                {!streamToUse && (
                    <AccountCircleIconWrapper imageUrl={user.imageUrl}>
                        {!user.imageUrl && <AccountCircleIcon fontSize="inherit" />}
                    </AccountCircleIconWrapper>
                )}
                {!isPanelDiscussion && streamToUse && (
                    <StreamPlayer
                        key={streamToUse.getId()}
                        muted={isCurrentUser}
                        isVideoOn={isVideoOn || isActiveStreamRtmp}
                        className={'main-stream-profile'}
                        stream={streamToUse}
                        uid={streamToUse.getId()}
                        domId={`stream-player-${streamToUse.getId()}`}
                        imageUrl={user.imageUrl}
                        isActiveSpeaker={isActive}
                        displayImagePlaceholder
                        noPositionInitial
                    />
                )}
            </ProfileImageWrapper>
            <VirtualUserContentWrapper>
                <VirtualUserTextWrapper>
                    {isActive && <RedStyledChip>PRESENTING</RedStyledChip>}
                    <VirtualUserTextDetails>
                        {isOffline
                            ? `${userRole} (offline)`.toLocaleUpperCase()
                            : userRole.toLocaleUpperCase()}
                    </VirtualUserTextDetails>
                    <VirtualUserTitleWrapper>
                        <VirtualUserTitle>{`${user.firstName} ${user.lastName} ${
                            isCurrentUser ? '(you)' : ''
                        }`}</VirtualUserTitle>
                    </VirtualUserTitleWrapper>
                    {user.companyName && (
                        <VirtualUserOccupationText>{user.companyName}</VirtualUserOccupationText>
                    )}
                </VirtualUserTextWrapper>
                {displayActions && (
                    <ActionButtonsContainer>
                        {isMicrophoneOn && !isOffline && (
                            <LightTooltip title={'Mute this user'}>
                                <Stack>
                                    <StyledIconOutlinedWrapper
                                        backgroundColor={videoSwitchWrapperColor}
                                        backgroundColorHover={videoSwitchWrapperColorHover}
                                        colorHover={switchIconColorHover}
                                        onClick={e => muteStream(e)}
                                        isThirdElement
                                    >
                                        <MicOffOutlinedIcon />
                                    </StyledIconOutlinedWrapper>
                                </Stack>
                            </LightTooltip>
                        )}
                        {isVideoOn && !isOffline && (
                            <LightTooltip title="Turn off this user's camera">
                                <Stack>
                                    <StyledIconOutlinedWrapper
                                        backgroundColor={videoSwitchWrapperColor}
                                        backgroundColorHover={videoSwitchWrapperColorHover}
                                        colorHover={switchIconColorHover}
                                        onClick={e => turnCameraOff(e)}
                                        isSecondElement
                                    >
                                        <VideocamOffIcon />
                                    </StyledIconOutlinedWrapper>
                                </Stack>
                            </LightTooltip>
                        )}
                        {!isOffline && (
                            <LightTooltip title={tooltipVideoMessage}>
                                <Stack>
                                    <StyledIconOutlinedWrapper
                                        backgroundColor={videoSwitchWrapperColor}
                                        backgroundColorHover={videoSwitchWrapperColorHover}
                                        colorHover={switchIconColorHover}
                                        onClick={onSwitchIsActiveClick}
                                        data-qa={`${
                                            isActive ? 'disable' : 'enable'
                                        }-active-speaker-${user.id}`}
                                    >
                                        {!isActive ? <PhonelinkIcon /> : <PhonelinkOffIcon />}
                                    </StyledIconOutlinedWrapper>
                                </Stack>
                            </LightTooltip>
                        )}
                    </ActionButtonsContainer>
                )}
            </VirtualUserContentWrapper>
        </VirtualUserWrapper>
    );
};

export default VirtualSessionUser;
