import React from 'react';
import { withRouter } from 'react-router-dom';
import useAnalyticsTracker from '../../features/analytics/hooks/useAnalyticsTracker';
import DetailPage from '../../scenes/DetailPage';
import FloorplanPage from '../../scenes/Floorplan';
import ListPage from '../../scenes/ListPage';
import MenuPage from '../../scenes/MenuPage';
import MyNotesPage from '../../scenes/MyNotesPage';
import ProgrammePage from '../../scenes/Programme';
import Feed from '../../scenes/Feed';
import LandingPage from '../../scenes/LandingPage/containers/LandingPage';
import SearchPage from '../../scenes/SearchPage';
import TalkRoom from '../../scenes/TalkRoom';
import PrivateChat from '../../scenes/Talk/containers/PrivateChat';
import Sponsor from '../../scenes/Sponsor';
import TabsPage from '../../scenes/TabsPage';
import ModeratedSession from '../../scenes/VirtualSession/components/moderatedSession/ModeratedSession';
import RoundTableSession from '../../scenes/VirtualSession/components/roundTable/VirtualRoundTableSession';
import WebPage from '../../scenes/WebPage';
import MyConversationsPage from '../../scenes/MyConversationsPage';
import MyContactsPage from '../../scenes/MyContactsPage';
import UserProfile from '../../scenes/UserProfile/containers/UserProfile';
import VideoPage from '../../scenes/VideoPage';
import AppointmentDetailPage from '../../scenes/Appointments/containers/AppointmentDetailPage';
import SocialFeed from '../../scenes/SocialFeed/containers/SocialFeed';
import AttendeeList from '../../scenes/Attendees';
import MyBookmarksPage from '../../scenes/MyBookmarksPage';
import AuthenticationProtection from '../ProtectedPages/AuthenticationProtection';
import PostEditor from '../../scenes/SocialFeed/containers/PostEditor';
import ExhibitorPage from '../../scenes/ExhibitorPage/containers/ExhibitorPage';
import VirtualRoom from '../../scenes/VirtualSession/components/virtualRoom/VirtualRoom';
import ObjectPage from '../../scenes/ObjectPage';
import UpcomingSessionsDetailPage from '../../scenes/LandingPage/containers/UpcomingSessionsDetailPage';
import AchievementsPage from '../../scenes/Achievements/containers';
import QAModeration from '../../scenes/VirtualModeration/features/virtualFeature/QAModeration';
import QAPresentation from '../../scenes/VirtualModeration/features/Votes/containers/QAPresentation';
import VirtualModeration from '../../scenes/VirtualModeration/features/virtualFeature/VirtualFeature';
import MyCheckinsPage from '../../scenes/MyCheckinsPage';
import MySessionsPage from '../../scenes/Society/components/EventSessionsList';
import BeamerSession from '../../scenes/VirtualSession/components/virtualBeamerSession/BeamerSession';
import VoteSession from '../../scenes/VirtualSession/components/voteSession/VoteSession';
import ChatSession from '../../scenes/VirtualSession/components/chatSession/ChatSession';
import OnDemandMetaFusionSession from '../../scenes/VirtualSession/components/onDemand/onDemandMetaFusionSession';
import InstitutionsList from '../../scenes/Institutions/containers/InstitutionsList';
import InstitutionProposal from '../../scenes/Institutions/containers/InstitutionProposal';

const ContentSelector = props => {
    const {
        installationId,
        type,
        objectClass,
        objectId,
        userId,
        personLinkedId,
        conversationId,
        pageName,
        pagePath,
    } = props;
    useAnalyticsTracker(installationId, {
        type,
        objectClass,
        objectId,
        userId,
        personLinkedId,
        conversationId,
        pageName,
        pagePath,
    });
    let authenticated = false;
    let RenderComponent = () => null;
    switch (type) {
        case 'floorplan': {
            RenderComponent = FloorplanPage;
            break;
        }
        case 'search': {
            RenderComponent = SearchPage;
            break;
        }
        case 'list': {
            RenderComponent = ListPage;
            break;
        }
        case 'menu': {
            RenderComponent = MenuPage;
            break;
        }
        case 'programme': {
            RenderComponent = ProgrammePage;
            break;
        }
        case 'dailyprogramme': {
            RenderComponent = ProgrammePage;
            break;
        }
        case 'tabs': {
            RenderComponent = TabsPage;
            break;
        }
        case 'webpage': {
            RenderComponent = WebPage;
            break;
        }
        case 'sponsor': {
            RenderComponent = Sponsor;
            break;
        }
        case 'Feed': {
            RenderComponent = Feed;
            break;
        }
        case 'home':
        case 'Landing': {
            RenderComponent = LandingPage;
            break;
        }
        case 'socialFeed': {
            RenderComponent = SocialFeed;
            break;
        }
        case 'networking': {
            RenderComponent = pagePath.toLowerCase().includes('attendee')
                ? AttendeeList
                : SocialFeed;
            break;
        }
        case 'eventwall': {
            RenderComponent = SocialFeed;
            break;
        }
        case 'attendees': {
            RenderComponent = AttendeeList;
            authenticated = true;
            break;
        }
        case 'mybookmarks': {
            RenderComponent = MyBookmarksPage;
            authenticated = true;
            break;
        }
        case 'myconversations': {
            RenderComponent = MyConversationsPage;
            authenticated = true;
            break;
        }
        case 'mycontacts': {
            RenderComponent = MyContactsPage;
            authenticated = true;
            break;
        }
        case 'mynotes': {
            RenderComponent = MyNotesPage;
            authenticated = true;
            break;
        }
        case 'video': {
            RenderComponent = VideoPage;
            authenticated = true;
            break;
        }
        case 'virtual-session-round': {
            RenderComponent = RoundTableSession;
            authenticated = true;
            break;
        }
        case 'on-demand-poster-room': {
            RenderComponent = RoundTableSession;
            authenticated = true;
            break;
        }
        case 'on-demand-metafusion-room': {
            RenderComponent = OnDemandMetaFusionSession;
            authenticated = true;
            break;
        }
        case 'virtual-session': {
            RenderComponent = ModeratedSession;
            authenticated = true;
            break;
        }
        case 'virtual-session-room': {
            RenderComponent = VirtualRoom;
            authenticated = true;
            break;
        }
        case 'virtual-beamer-view': {
            RenderComponent = BeamerSession;
            authenticated = true;
            break;
        }
        case 'virtual-moderation': {
            RenderComponent = VirtualModeration;
            authenticated = true;
            break;
        }
        case 'qa-moderation': {
            RenderComponent = QAModeration;
            authenticated = true;
            break;
        }
        case 'qa-presentation': {
            RenderComponent = QAPresentation;
            authenticated = true;
            break;
        }
        case 'vote-session': {
            RenderComponent = VoteSession;
            authenticated = false;
            break;
        }
        case 'chat-presentation': {
            RenderComponent = ChatSession;
            authenticated = false;
            break;
        }
        case 'exhibitorbooth': {
            RenderComponent = ExhibitorPage;
            break;
        }
        case 'institution': {
            RenderComponent = InstitutionsList;
            authenticated = true;
            break;
        }
        case 'institution-proposal': {
            RenderComponent = InstitutionProposal;
            authenticated = true;
            break;
        }
        case 'object': {
            RenderComponent = ObjectPage;
            break;
        }
        case 'achievements': {
            RenderComponent = AchievementsPage;
            authenticated = true;
            break;
        }
        case 'detail': {
            RenderComponent = DetailPage;
            break;
        }
        case 'talkRoom': {
            RenderComponent = TalkRoom;
            authenticated = true;
            break;
        }
        case 'privateChat': {
            RenderComponent = PrivateChat;
            authenticated = true;
            break;
        }
        case 'profile': {
            RenderComponent = UserProfile;
            authenticated = true;
            break;
        }
        case 'appointment': {
            RenderComponent = AppointmentDetailPage;
            break;
        }
        case 'next':
        case 'now':
        case 'upcomingSessions': {
            RenderComponent = UpcomingSessionsDetailPage;
            break;
        }
        case 'addPost': {
            RenderComponent = PostEditor;
            authenticated = true;
            break;
        }
        case 'checkins': {
            RenderComponent = MyCheckinsPage;
            authenticated = true;
            break;
        }
        case 'mysessions': {
            RenderComponent = MySessionsPage;
            authenticated = true;
            break;
        }

        default: {
            break;
        }
    }

    // TODO Replace setTitle with event store title

    return (
        <>
            {authenticated && (
                <AuthenticationProtection routeProtection>
                    <RenderComponent {...props} type={type} setTitle={() => {}}></RenderComponent>
                </AuthenticationProtection>
            )}
            {!authenticated && (
                <RenderComponent {...props} type={type} setTitle={() => {}}></RenderComponent>
            )}
        </>
    );
};

export default withRouter(ContentSelector);
