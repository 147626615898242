import React, { useState } from 'react';
import styled from 'styled-components';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import HowToVoteOutlinedIcon from '@mui/icons-material/HowToVoteOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { styled as muiStyled } from '@mui/material/styles';
import { LightTooltip } from '../../virtualFeature/components/moderator/common/styles';

const DropdownWrapper = styled.div`
    position: relative;
`;

const StyledMoreVertIcon = styled(MoreVertIcon)`
    position: absolute;
    top: -10px;
    right: 0;
    max-height: 40px;
    height: 40px;
    max-width: 40px;
    width: 40px;
    padding: 8px;
    cursor: pointer;
    border-radius: 50%;

    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }
`;

const StyledList = styled(List)`
    position: absolute;
    top: 32px;
    right: 0;
    box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
    border-radius: 8px;
    z-index: 100;
`;

const StyledListItem = muiStyled(ListItem)(({ theme }) => ({
    padding: '0 16px !important',
    margin: '0 4px !important',
    height: '48px !important',
    borderRadius: '4px !important',
    width: '232px !important',

    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },

    '&.Mui-disabled': {
        cursor: 'default',
    },

    '& .MuiListItemText-primary': {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 500,
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: '15px',
    },
}));

const iconStyle = {
    marginRight: 16,
    color: 'rgba(0, 0, 0, 0.6)',
};

const NarrowedLightTooltip = muiStyled(LightTooltip)({
    maxWidth: '100px',
});

const Dropdown = ({
    id,
    onMoveUp,
    onMoveDown,
    onViewResults,
    onResetVotes,
    onEdit,
    onDelete,
    active,
    resultsNumber,
    index,
    isLastInList,
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isDeleteButtonHovered, setIsDeleteButtonHovered] = useState(false);
    const [isEditButtonHovered, setIsEditButtonHovered] = useState(false);

    const handleClick = e => {
        // Hide the dropdown menu when you click outside it
        const { id: targetId } = e.target;

        if (targetId !== id && targetId !== `${id}-icon`) {
            setIsExpanded(false);
        }
    };

    const addClickEventListener = () => window.addEventListener('click', handleClick, false);

    const removeClickEventListener = () => window.removeEventListener('click', handleClick, false);

    const toggleIsExpanded = e => {
        e.stopPropagation();
        if (!isExpanded) {
            addClickEventListener();
        } else {
            removeClickEventListener();
        }
        setIsExpanded(!isExpanded);
    };

    const clearHoverStates = () => {
        setIsDeleteButtonHovered(false);
        setIsEditButtonHovered(false);
    };

    return (
        <DropdownWrapper id={id} onClick={toggleIsExpanded}>
            <StyledMoreVertIcon id={`${id}-icon`} />
            {isExpanded && (
                <StyledList>
                    <NarrowedLightTooltip
                        open={active && isEditButtonHovered}
                        title="Active question cannot be edited."
                    >
                        <StyledListItem
                            onClick={onEdit}
                            disabled={active}
                            onMouseEnter={() => {
                                setIsEditButtonHovered(true);
                                setIsDeleteButtonHovered(false);
                            }}
                            onMouseLeave={() => setIsEditButtonHovered(false)}
                        >
                            <ListItemIcon>
                                <CreateOutlinedIcon style={iconStyle} />
                            </ListItemIcon>
                            <ListItemText primary="Edit" />
                        </StyledListItem>
                    </NarrowedLightTooltip>
                    {resultsNumber > 0 && (
                        <StyledListItem onClick={onViewResults} onMouseEnter={clearHoverStates}>
                            <ListItemIcon>
                                <HowToVoteOutlinedIcon style={iconStyle} />
                            </ListItemIcon>
                            <ListItemText primary="Show results" />
                        </StyledListItem>
                    )}
                    {resultsNumber > 0 && (
                        <StyledListItem onClick={onResetVotes} onMouseEnter={clearHoverStates}>
                            <ListItemIcon>
                                <RefreshOutlinedIcon style={iconStyle} />
                            </ListItemIcon>
                            <ListItemText primary="Clear results" />
                        </StyledListItem>
                    )}
                    {index > 0 && (
                        <StyledListItem onClick={onMoveUp} onMouseEnter={clearHoverStates}>
                            <ListItemIcon>
                                <ArrowUpwardOutlinedIcon style={iconStyle} />
                            </ListItemIcon>
                            <ListItemText primary="Move up" />
                        </StyledListItem>
                    )}
                    {!isLastInList && (
                        <StyledListItem onClick={onMoveDown} onMouseEnter={clearHoverStates}>
                            <ListItemIcon>
                                <ArrowDownwardOutlinedIcon style={iconStyle} />
                            </ListItemIcon>
                            <ListItemText primary="Move down" />
                        </StyledListItem>
                    )}
                    <Divider />
                    <NarrowedLightTooltip
                        open={active && isDeleteButtonHovered}
                        title="Active question cannot be deleted."
                    >
                        <StyledListItem
                            onClick={onDelete}
                            disabled={active}
                            onMouseEnter={() => {
                                setIsDeleteButtonHovered(true);
                                setIsEditButtonHovered(false);
                            }}
                            onMouseLeave={() => setIsDeleteButtonHovered(false)}
                        >
                            <ListItemIcon>
                                <DeleteOutlinedIcon style={iconStyle} />
                            </ListItemIcon>
                            <ListItemText primary="Delete" />
                        </StyledListItem>
                    </NarrowedLightTooltip>
                </StyledList>
            )}
        </DropdownWrapper>
    );
};

export default Dropdown;
