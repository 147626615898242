import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import ContentSelector from './ContentSelector';
import { getString } from '../../services/api/store';
import RightPanelView from './RightPanelView';
import { usePagesStore } from '../../stores/PagesStore';

const routeConfig = {
    eventwall: {
        subRoutes: [
            {
                path: '/add',
                type: 'addPost',
            },
            {
                path: '/edit/:id',
                type: 'addPost',
            },
        ],
    },
    floorplan: {
        addition: '/:floorplan?/:place?',
    },
    'institution-proposal': {
        addition: '/:institutionId',
    },
};

const TITLE_MY_CONVERSATIONS = getString('talkWindowTitle') || 'My Conversations';
const TITLE_MY_CONTACTS = getString('contactsWindowTitle') || 'My Contacts';
const TITLE_MY_NOTES = getString('myNotes') || 'My Notes';
const TITLE_MY_BOOKMARKS = getString('myFavorites', 'Bookmarks');
const TITLE_VIRTUAL_SESSION = getString('virtualSession') || 'Virtual Session';
const TITLE_MY_CHECKINS = getString('myCheckinsTitle', 'My check ins');

export const predefinedNavigationPages = [
    {
        name: 'mybookmarks',
        title: TITLE_MY_BOOKMARKS,
    },
    {
        name: 'myconversations',
        title: TITLE_MY_CONVERSATIONS,
    },
    {
        name: 'mycontacts',
        title: TITLE_MY_CONTACTS,
    },
    {
        name: 'mynotes',
        title: TITLE_MY_NOTES,
    },
    {
        name: 'mysessions',
        title: 'My sessions',
    },
    {
        name: 'video',
        addition: '/:objectClass/:objectId',
    },
    {
        name: 'search',
        title: 'Search',
        pageId: '5db0358bf4d01d313c664587', //TODO remove this hardoced pageId
    },
    {
        name: 'attendees',
        title: 'Attendees',
    },
    {
        name: 'institution',
        title: 'Institutions',
    },
    {
        name: 'institution-proposal',
        title: 'Institution Proposal',
    },
    {
        name: 'attendee_list',
        title: 'Attendees',
    },
    {
        name: 'eventwall',
        title: 'Social feed',
    },
    {
        name: 'social_feed',
        title: 'Social feed',
    },
    {
        name: 'on-demand-metafusion-room',
        title: TITLE_VIRTUAL_SESSION,
        addition: '/:timeslotId',
    },
    {
        name: 'virtual-session-round',
        title: TITLE_VIRTUAL_SESSION,
        addition: '/:timeslotId',
    },
    {
        name: 'on-demand-poster-room',
        title: TITLE_VIRTUAL_SESSION,
        addition: '/:timeslotId',
    },
    {
        name: 'virtual-beamer-view',
        title: TITLE_VIRTUAL_SESSION,
        addition: '/:timeslotId',
    },
    {
        name: 'virtual-session-test',
        title: TITLE_VIRTUAL_SESSION,
        addition: '/:timeslotId',
    },
    {
        name: 'virtual-session',
        title: TITLE_VIRTUAL_SESSION,
        addition: '/:timeslotId',
    },
    {
        name: 'virtual-session-room',
        title: TITLE_VIRTUAL_SESSION,
        addition: '/:timeslotId',
    },
    {
        name: 'vote-session',
        title: TITLE_VIRTUAL_SESSION,
        addition: '/:timeslotId',
    },
    {
        name: 'chat-presentation',
        title: TITLE_VIRTUAL_SESSION,
        addition: '/:timeslotId',
    },
    {
        name: 'virtual-moderation',
        title: 'virtual moderation',
        addition: '/:timeslotId/:eventId?',
    },
    {
        name: 'qa-moderation',
        title: 'Q&A moderation',
        addition: '/:timeslotId/:eventId?',
    },
    {
        name: 'qa-presentation',
        title: 'Q&A moderation',
        addition: '/:externalObjectId',
    },
    {
        name: 'exhibitorbooth',
        addition: '/:exhibitorId',
    },
    {
        name: 'achievements',
        title: 'Achievements',
    },
    {
        name: 'checkins',
        title: TITLE_MY_CHECKINS,
    },
    {
        name: 'object',
        title: 'Object',
        type: 'object',
        addition: '/:objectId',
    },
    {
        name: 'objectpage',
        title: 'Object',
        type: 'object',
        addition: '/:objectId',
    },
    {
        name: 'Landing',
        title: 'Home',
        type: 'home',
    },
    {
        name: 'home',
        title: 'Home',
        type: 'home',
    },
];

const Routes = props => {
    const socialFeedNames = ['eventwall', 'social_feed'];
    const attendessListNames = ['attendee_list', 'attendees'];
    const [allRoutes, setAllRoutes] = useState([]);
    let { url } = useRouteMatch();
    const { nav, eventId } = props;

    const pages = usePagesStore(state => state.pages);

    useEffect(() => {
        (async () => {
            const routes = [];

            if (pages && pages.length) {
                pages.forEach(item => {
                    const navObject = {
                        to: `/${item.name}`,
                        type: item.kind === 'networking' ? item.name : item.kind,
                        title: item.params && item.params.title,
                        pageId: item.id,
                        extraParams: item.extraParams,
                        hidden: true,
                    };
                    routes.push(navObject);
                });
            }

            predefinedNavigationPages.forEach(item => {
                let type = item.type || item.name;

                if (socialFeedNames.includes(item.name)) {
                    type = 'eventwall';
                }
                if (attendessListNames.includes(item.name)) {
                    type = 'attendees';
                }
                const navObject = {
                    to: `/${item.name}`,
                    type,
                    title: item.title || item.name,
                    pageId: item.pageId,
                    addition: item.addition,
                    hidden: true,
                };
                routes.push(navObject);
            });

            setAllRoutes(routes);
        })();
    }, [eventId, pages]);

    const renderSubroutes = elem => {
        if (
            routeConfig[elem.type] &&
            routeConfig[elem.type].subRoutes &&
            routeConfig[elem.type].subRoutes.length
        ) {
            return (
                <Switch>
                    {routeConfig[elem.type].subRoutes.map((subRoute, index) => (
                        <Route
                            key={'subRoute' + index}
                            path={`${url}${elem.to}${subRoute.path}`}
                            render={() => (
                                <ContentSelector key={'subRoute' + index} type={subRoute.type} />
                            )}
                        />
                    ))}
                </Switch>
            );
        }
        return null;
    };

    const removeFirstSlash = text => text.replace(/^\//, '');

    const initialPath = `${nav[0]?.to || ''}`;

    return (
        <>
            {allRoutes.length && (
                <>
                    <Switch>
                        <Route exact path={`${url}`}>
                            {<Redirect to={`${url}${initialPath}`} />}
                        </Route>
                        {allRoutes.map((elem, index) => (
                            <Route
                                key={index}
                                path={`${url}${elem.to}${
                                    elem.addition ||
                                    (routeConfig[elem.type] && routeConfig[elem.type].addition)
                                        ? elem.addition || routeConfig[elem.type].addition
                                        : ''
                                }`}
                                render={() => (
                                    <>
                                        <ContentSelector
                                            key={index}
                                            type={elem.type}
                                            pageId={elem.pageId}
                                            event={{ id: eventId }}
                                            setTitle={title =>
                                                props.setTitle(
                                                    title || title === '' ? '' : elem.title,
                                                )
                                            }
                                            pageName={elem.title}
                                            pagePath={removeFirstSlash(elem.to)}
                                            disableScroll={props.disableScroll}
                                            handleSidepanel={props.handleSidepanel}
                                            isSidepanelOpened={props.isSidepanelOpened}
                                            installationId={props.installationId}
                                            navigationType={props.navigationType}
                                            visibleFullSideMenu={props.visibleFullSideMenu}
                                        />
                                        {renderSubroutes(elem)}
                                    </>
                                )}
                            />
                        ))}
                    </Switch>
                    <RightPanelView installationId={props.installationId} />
                </>
            )}
        </>
    );
};
export default Routes;
