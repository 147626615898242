import React, { useEffect, useMemo } from 'react';
import { Provider } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import Society from './scenes/Society/containers';
import Event from './scenes/Event/containers';

import reduxStore from './reduxStore';
import withSocket from './components/Session/withSocket';
import SocketContext from './components/Session/SocketContext';
import withAuthentication from './components/Session/withAuthentication';
import withTheme from './components/Theme/withTheme';
import NetworkDetector from './NetworkDetector';
import withPlatformInfoUpdates from './scenes/User/components/platformInfo/PlatformInfoContextProvider';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ContainerProvider } from './scenes/VirtualModeration/features/virtualFeature/containers/main';
import CookieNoticeWrapper from './components/Session/CookieNoticeWrapper';
import { useTheme } from './components/Theme/ThemeContext';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import FloqLiveStoreProviders from './stores/FloqLiveStoreProvider';
import * as palette from './components/General/Variables';

export const CONNECTION_ERROR_MESSAGE = 'Could not connect to server';
export const EVENT_NOT_FOUND_MESSAGE = 'AppState not found';
export const EVENT_NOT_CONFIGURED_MESSAGE = 'Navigation not configured';
export const USER_NOT_ATTENDING_PERMISSIONS = 'User not in attendees';

const sendHeightToParent = () => {
    const baseHeight = document.documentElement.scrollHeight;
    let height = 0;
    const scrollableContainerListElement = document.getElementById(
        `timetable-scrollable-container-horizontal`,
    );
    if (scrollableContainerListElement) {
        const firstChild = scrollableContainerListElement?.firstElementChild;
        const listElements = firstChild.getElementsByTagName('ul');
        const listElementsHeight = Array.from(listElements).reduce(
            (acc, el) => acc + el?.clientHeight || 0,
            0,
        );

        height += listElementsHeight;
    }

    const objectItemListElement = document.getElementById('object-item-list');
    if (objectItemListElement) {
        height += objectItemListElement.clientHeight + 128;
    }
    const finalHeight = (height || baseHeight) + 256;
    window.parent.postMessage({ type: 'UPDATE_HEIGHT', payload: finalHeight }, '*');
};

const handleSendHeightToParent = () => {
    sendHeightToParent();
    setTimeout(() => {
        sendHeightToParent();
    }, 3000);
};

function App() {
    const { theme } = useTheme();

    const history = useHistory();

    useEffect(() => {
        if (!history) {
            return;
        }
        handleSendHeightToParent();
        const unlisten = history.listen(location => {
            window.parent.postMessage({ type: 'ROUTE_CHANGE', payload: location.pathname }, '*');
            handleSendHeightToParent();
        });

        return () => {
            unlisten();
        };
    }, [history]);

    // Setting Material UI ThemeProvider
    const materialUITheme = useMemo(() => {
        return createTheme({
            palette: {
                type: 'light', // we can later use dark/light modes
                primary: {
                    main: theme.primary || '#00A480',
                },
                secondary: {
                    main: '#EFEFEF',
                },
            },
            typography: {
                h1: {
                    fontFamily: 'Cabin',
                    fontWeight: 700,
                    fontSize: '48px',
                    lineHeight: '48px',
                    color: palette.COLOR_TEXT,
                    marginBottom: 8,
                },
                h2: {
                    fontFamily: 'Cabin',
                    fontWeight: 700,
                    fontSize: '24px',
                    lineHeight: '28px',
                    color: palette.COLOR_TEXT,
                    marginBottom: 8,
                },
                h3: {
                    fontFamily: 'Cabin',
                    fontWeight: 700,
                    fontSize: '21px',
                    lineHeight: '24px',
                    color: palette.COLOR_TEXT,
                    marginBottom: 8,
                },
                h4: {
                    fontFamily: 'Cabin',
                    fontWeight: 700,
                    fontSize: '17px',
                    lineHeight: '24px',
                    color: palette.COLOR_TEXT,
                    marginBottom: 8,
                },
                h5: {
                    fontFamily: 'Cabin',
                    fontWeight: 700,
                    fontSize: '15px',
                    lineHeight: '20px',
                    color: palette.COLOR_TEXT,
                    marginBottom: 8,
                },
                body1: {
                    fontFamily: 'Roboto',
                    fontWeight: 400,
                    fontSize: '15px',
                    lineHeight: '24px',
                    color: palette.COLOR_TEXT,
                },
                body2: {
                    fontFamily: 'Roboto',
                    fontWeight: 400,
                    fontSize: '15px',
                    lineHeight: '20px',
                    color: palette.COLOR_TEXT_LIGHT,
                },
                subtitle1: {
                    fontFamily: 'Roboto',
                    fontSize: '13px',
                    lineHeight: 1.54,
                    color: palette.COLOR_TEXT_LIGHT,
                },
                subtitle2: {
                    fontFamily: 'Roboto',
                    fontSize: '13px',
                    fontWeight: 500,
                    lineHeight: 1.54,
                    color: palette.COLOR_TEXT_DISABLED,
                },
                button: {
                    textTransform: 'none',
                    fontFamily: 'Roboto',
                    fontSize: '15px',
                },
            },
            components: {
                MuiTab: {
                    styleOverrides: {
                        root: {
                            textTransform: 'none',
                            fontFamily: 'Roboto',
                        },
                    },
                },
                MuiTableContainer: {
                    styleOverrides: {
                        root: {
                            boxShadow: 'none',
                        },
                    },
                },
                MuiTableHead: {
                    styleOverrides: {
                        root: {
                            borderTop: `1px solid ${palette.UI_GREY_1}`,
                            borderBottom: `1px solid ${palette.UI_GREY_1}`,
                        },
                    },
                },
                MuiFilledInput: {
                    styleOverrides: {
                        root: {
                            borderRadius: '6px',
                            overflow: 'hidden',
                            border: `2px solid ${palette.COLOR_WHITE}`,
                            backgroundColor: palette.COLOR_SECONDARY_MEDIUM,
                            '&.Mui-focused': {
                                backgroundColor: palette.COLOR_WHITE,
                                border: `2px solid ${theme.primary}`,
                                borderRadius: '6px',
                            },
                            '&.Mui-error': {
                                border: `2px solid ${palette.COLOR_ERROR}`,
                                borderRadius: '6px',
                                backgroundColor: palette.COLOR_WHITE,
                            },
                        },
                    },
                },
                MuiFormHelperText: {
                    styleOverrides: {
                        root: {
                            fontFamily: 'Roboto',
                        },
                    },
                },
                MuiOutlinedInput: {
                    styleOverrides: {
                        root: {
                            '& fieldset': { border: 'none' },
                            borderRadius: '6px',
                            overflow: 'hidden',
                            border: `2px solid ${palette.COLOR_WHITE}`,
                            backgroundColor: palette.COLOR_SECONDARY_MEDIUM,
                            '&.Mui-focused': {
                                backgroundColor: palette.COLOR_WHITE,
                                border: `2px solid ${theme.primary}`,
                                borderRadius: '6px',
                            },
                            '&.Mui-error': {
                                border: `2px solid ${palette.COLOR_ERROR}`,
                                borderRadius: '6px',
                                backgroundColor: palette.COLOR_WHITE,
                            },
                        },
                    },
                },
                MuiInputLabel: {
                    styleOverrides: {
                        root: {
                            color: 'rgba(0, 0, 0, 0.6)',
                            padding: '4px',
                            '&.MuiInputLabel-shrink': {
                                padding: '2px',
                            },
                        },
                    },
                },
                MuiDialog: {
                    styleOverrides: {
                        paper: {
                            borderRadius: '8px',
                        },
                    },
                },
                MuiDialogContent: {
                    styleOverrides: {
                        root: {
                            padding: '0 24px',
                        },
                    },
                },
                MuiDialogActions: {
                    styleOverrides: {
                        root: {
                            padding: '24px',
                        },
                    },
                },
                MuiButtonBase: {
                    styleOverrides: {
                        root: {
                            boxShadow: 'none',
                            textTransform: 'none',
                            borderRadius: 6,
                            height: '48px',
                            padding: '0 12px',
                        },
                    },
                },
                MuiButton: {
                    styleOverrides: {
                        root: {
                            boxShadow: 'none',
                            textTransform: 'none',
                            backgroundColor: theme.primary || '#00A480',
                            borderRadius: 8,
                            color: palette.COLOR_WHITE,
                        },
                        containedSecondary: {
                            backgroundColor: palette.UI_GREY_1,
                            color: palette.COLOR_TEXT,
                        },
                    },
                },
            },
            overrides: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            boxShadow: 'none',
                            textTransform: 'none',
                            backgroundColor: theme.primary || '#00A480',
                        },
                    },
                },
            },
        });
    }, [theme]);

    return (
        <ContainerProvider>
            <Provider store={reduxStore}>
                <FloqLiveStoreProviders>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={materialUITheme}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Switch>
                                    <Route path="/event/:eventName">
                                        <Event />
                                    </Route>
                                    <Route path="/kiosk/:eventName">
                                        <Event kiosk />
                                    </Route>
                                    <Route path="/">
                                        <Society />
                                    </Route>
                                </Switch>
                            </LocalizationProvider>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </FloqLiveStoreProviders>
            </Provider>
        </ContainerProvider>
    );
}

//Pass set theme function in the app component
const AppWithSocketAndUpdates = props => {
    useEffect(() => {
        if (!props.waitingWorker) {
            serviceWorkerRegistration.register({
                onUpdate: reg => props.onServiceWorkerUpdate(reg),
                onSuccess: () => {},
            });
        }
    }, [props.newVersionAvailable]);

    return (
        <SocketContext.Consumer>
            {({ socket }) => socket && <App {...props} socket={socket} />}
        </SocketContext.Consumer>
    );
};

const AppWithRouter = withPlatformInfoUpdates(AppWithSocketAndUpdates);

const AppWithCookiesEnabled = props => {
    return (
        <CookieNoticeWrapper>
            <AppWithRouter {...props} />
        </CookieNoticeWrapper>
    );
};

//Add Authentication context
export default NetworkDetector(withSocket(withTheme(withAuthentication(AppWithCookiesEnabled))));
