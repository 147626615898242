import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Button from '@mui/material/Button';

import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useTheme } from '../../../../../components/Theme/ThemeContext';

const ErrorMessage = styled.div`
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #d50000;
    margin-top: 8px;
`;

const SlidesWrapper = styled.div`
    flex: 7;
    width: 334px;
    height: 188px;
    margin: 0 0 0 36px;
    position: relative;
    border-radius: 8px;

    ${props =>
        props.placeHolderWrapper &&
        css`
            overflow: hidden;
        `}
`;

const Img = styled.img`
    height: 100%;
    width: 100%;
    z-index: -1;
    border-radius: 8px;
    object-fit: cover;
`;

const NoImageContainer = styled.img`
    height: 100%;
    width: 100%;
    z-index: -1;
    border-radius: 8px;
    background: #f1f1f3;
`;

const FileInputContainer = styled(Button)`
    height: 188px;
    width: 408px;
    cursor: pointer;
    background-color: red;
    border-radius: 8px;
`;

const ImageTextWrapper = styled.div`
    position: absolute;
    bottom: 16px;
    right: 16px;
    height: 48px;
    width: 48px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 2px 8px 0 rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const IMAGE_ERROR = 'Select a PNG or JPG file of less than 2 MB.';
const fileInputContainerStyle = {
    backgroundColor: 'transparent',
    transform: 'translateY(-192px)',
    zIndex: 100,
};
const iconStyle = {
    height: 24,
    width: 24,
    color: '#00a480',
};

const ImageUpload = ({ placeHolderUrl, onImageChange }) => {
    const [showImageError, setShowImageError] = useState(false);
    const { theme } = useTheme();

    const onChange = async event => {
        if (!event.target.files) {
            return;
        }

        const file = event.target.files[0];

        if (file.size > 2043634) {
            setShowImageError(true);
        } else {
            onImageChange(file);
        }
    };

    return (
        <>
            <SlidesWrapper>
                {placeHolderUrl && <Img alt="" src={placeHolderUrl} />}
                {!placeHolderUrl && <NoImageContainer />}
                <FileInputContainer
                    variant="contained"
                    disableElevation
                    style={fileInputContainerStyle}
                    component="label"
                >
                    <input type="file" accept="image/*" onChange={onChange} hidden />
                </FileInputContainer>
                <ImageTextWrapper lightBackground={!placeHolderUrl}>
                    <AddPhotoAlternateIcon style={{ ...iconStyle, color: theme.primary }} />
                </ImageTextWrapper>
            </SlidesWrapper>
            {showImageError && <ErrorMessage>{IMAGE_ERROR}</ErrorMessage>}
        </>
    );
};

export default ImageUpload;
