import React, { useState } from 'react';
import styled from 'styled-components';
import Check from '@mui/icons-material/Check';
import { copyTextToClipboard } from '../../../../utils/copyUtils';

const Container = styled.div`
    margin-bottom: 16px;
    margin-left: 56px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 12px;
    border-radius: 6px;
    border: solid 1px #ddd;
    background-color: #fff;
`;

const TitleContainer = styled.div`
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-content: space-between;
`;

const StyledTitle = styled.p`
    display: flex;
    font-family: Cabin;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
`;

const StyledText = styled.p`
    display: flex;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    cursor: pointer;
    flex-direction: row;
    color: rgba(0, 0, 0, 0.6);
`;

const SectionSubTitle = styled.div`
    align-self: stretch;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.6);
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const LinkCard = ({ title, url }) => {
    const [copied, setCopied] = useState(false);
    const copyLink = () => {
        copyTextToClipboard(url);
        setCopied(true);
    };
    return (
        <Container>
            <TitleContainer>
                <StyledTitle>{title}</StyledTitle>
                <StyledText onClick={copyLink}>
                    {copied && (
                        <React.Fragment>
                            <Check fontSize="small" />
                            Copied
                        </React.Fragment>
                    )}
                    {!copied && 'Copy'}
                </StyledText>
            </TitleContainer>
            <SectionSubTitle>{url}</SectionSubTitle>
        </Container>
    );
};

export default LinkCard;
