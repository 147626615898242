import React from 'react';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import * as palette from '../General/Variables';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

const actionsPositionMap = {
    stretch: {
        '& .MuiButton-root': {
            flex: 1,
        },
    },
    left: { justifyContent: 'flex-start' },
    right: { justifyContent: 'flex-end' },
};

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
`;

const Modal = ({
    title,
    open = true,
    onClose,
    children,
    actions,
    size = 'sm',
    loading = false,
    actionsPosition = 'stretch',
    style,
    zeroContentPadding = false,
    headerContainerStyle = {},
    contentStyle = {},
    goBack,
    canGoBack,
}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth={size}
            style={style}
        >
            <HeaderContainer style={headerContainerStyle}>
                {canGoBack && (
                    <Stack direction={'row'} alignItems={'center'}>
                        <IconButton
                            onClick={() => {
                                if (goBack && typeof goBack === 'function') {
                                    goBack();
                                }
                            }}
                        >
                            <ArrowBackRoundedIcon style={{ color: palette.COLOR_TEXT }} />
                        </IconButton>
                        <Typography variant="h3" sx={{ margin: 0, marginLeft: '8px' }}>
                            {title}
                        </Typography>
                    </Stack>
                )}
                {!canGoBack && (
                    <Typography variant="h3" sx={{ margin: 0 }}>
                        {title}
                    </Typography>
                )}
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </HeaderContainer>
            <DialogContent
                sx={{
                    padding: zeroContentPadding ? '0px' : undefined,
                }}
                style={contentStyle}
            >
                {loading && <CircularProgress />}
                {!loading && children}
            </DialogContent>
            <DialogActions sx={actionsPositionMap[actionsPosition]}>
                {actions.map((actionButton, index) => (
                    <>
                        {actionButton.label && (
                            <Button
                                key={`modal-button-${index}`}
                                onClick={actionButton.onClick}
                                variant={actionButton.variant}
                                color={actionButton.color}
                                disabled={actionButton.disabled || actionButton.loading}
                                startIcon={actionButton.startIcon}
                                sx={actionButton.sx}
                            >
                                {actionButton.loading && (
                                    <Box sx={{ display: 'flex' }}>
                                        <CircularProgress
                                            size="24px"
                                            sx={{ color: palette.COLOR_WHITE }}
                                        />
                                    </Box>
                                )}
                                {!actionButton.loading && actionButton.label}
                            </Button>
                        )}
                    </>
                ))}
            </DialogActions>
        </Dialog>
    );
};

export default Modal;
