import React from 'react';
import { useVMState } from '../../containers/main';
import styled from 'styled-components';
import entities from '../../constants/entities';
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';

const SessionWarningWrapper = styled.div`
    height: 32px;
    background-color: ${props => (props.ispresenter ? '#d3d3d3' : '#d50000')};
    display: flex;
    align-items: center;
    padding-left: 16px;
    position: relative;
    width: calc(100% + 5px);

    ${props =>
        props.isModerator &&
        `
    &:after {
        content: '';
        width: 0;
        height: 0;
        bottom: 17px;
        left: ${props.isInitial === true ? '200px' : '45px'};
        position: absolute;
        border: 6px solid #d50000;
        transform: rotate(135deg);
        transition: border 0.4s ease-in-out;
    }
  `}
`;

const SessionWarningTextWrapper = styled.div`
    margin: 0;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: ${props => (props.ispresenter ? 'rgba(0, 0, 0, 0.87)' : '#fff')};
`;

const WarningIcon = styled(PriorityHighOutlinedIcon)`
    width: 13px !important;
    height: 13px !important;
    border-radius: 50%;
    border: 2px solid ${props => (props.ispresenter ? 'rgba(0, 0, 0, 0.87)' : '#fff')};
    margin-right: 8px;
    color: ${props => (props.ispresenter ? 'rgba(0, 0, 0, 0.87)' : '#fff')};
`;

const textConstants = {
    moderator: {
        TEXT_CLOSED_ROOM: 'Virtual room is closed. Attendees cannot enter.',
        TEXT_NOT_BROADCASTING: 'Broadcasting is off. Attendees cannot see the slides or speaker.',
    },
    presenter: {
        TEXT_CLOSED_ROOM: 'Moderator has not yet opened the virtual room. Attendees cannot enter.',
    },
};

export default ({ isModerator, isPresenter }) => {
    const stateCtx = useVMState();
    const { virtualEventSession } = stateCtx;
    const virtualEventSessionStatus = virtualEventSession.data.status;

    let text;

    const { status } = entities.virtualEventSessionEntity;

    virtualEventSessionStatus === status.closed &&
        (text = textConstants.moderator.TEXT_CLOSED_ROOM);
    virtualEventSessionStatus === status.initial &&
        (text = textConstants.moderator.TEXT_NOT_BROADCASTING);
    virtualEventSessionStatus === status.closed &&
        isPresenter &&
        (text = textConstants.presenter.TEXT_CLOSED_ROOM);

    if (virtualEventSessionStatus === status.initial && isPresenter) {
        return null;
    }

    if (!text) {
        return null;
    }
    return (
        <SessionWarningWrapper
            isModerator={isModerator}
            ispresenter={isPresenter ? 1 : 0}
            isInitial={virtualEventSessionStatus === status.initial}
        >
            <WarningIcon ispresenter={isPresenter ? 1 : 0} />
            <SessionWarningTextWrapper ispresenter={isPresenter}>{text}</SessionWarningTextWrapper>
        </SessionWarningWrapper>
    );
};
