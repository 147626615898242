import { useEffect, useState } from 'react';
import { executeQuery } from '../services/api/graphQlRepository';
import orderBy from 'lodash/orderBy';

const useTagFilters = filterTypes => {
    const [filters, setFilters] = useState([]);

    useEffect(() => {
        const loadFilters = async () => {
            let newFilters = [];

            if (filterTypes && filterTypes.length) {
                let classifiersa = await executeQuery('getClassifiersWithType', {
                    type: filterTypes[0].type,
                });
                classifiersa = orderBy(classifiersa, 'name', 'asc');

                newFilters.push({
                    id: filterTypes[0].type,
                    name: filterTypes[0].name,
                    options: classifiersa,
                });

                if (filterTypes.length > 1) {
                    let classifiersb = await executeQuery('getClassifiersWithType', {
                        type: filterTypes[1].type,
                    });
                    classifiersb = orderBy(classifiersb, 'name', 'asc');
                    newFilters.push({
                        id: filterTypes[1].type,
                        name: filterTypes[1].name,
                        options: classifiersb,
                    });
                }

                if (filterTypes.length > 2) {
                    let classifiersc = await executeQuery('getClassifiersWithType', {
                        type: filterTypes[2].type,
                    });
                    classifiersc = orderBy(classifiersc, 'name', 'asc');

                    newFilters.push({
                        id: filterTypes[2].type,
                        name: filterTypes[2].name,
                        options: classifiersc,
                    });
                }
                setFilters(newFilters);
            }
        };

        loadFilters();
    }, [filterTypes]);

    return filters;
};

export default useTagFilters;
