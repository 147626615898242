import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import VideoCamera from '@mui/icons-material/VideocamOutlined';

import CommonButton from '../common/Button';
import { ButtonClassType } from './Button';
import copyUtils from '../../utils/copyUtils';

const ButtonWrapper = styled.div`
    margin-left: 10px;
`;

const StyledDivider = styled.div`
    border: 1px solid #eee;
    width: 100%;
    margin: 20px 0 0 0;
`;

const DialogTitleWrapper = styled(DialogTitle)`
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
        font-family: Cabin, sans-serif;
        font-size: 21px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.87);
    }
`;

const CameraIconWrapper = styled(VideoCamera)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px !important;
    height: 32px !important;
    padding: 14px;
    color: #fff;
    border-radius: 50%;
    font-size: 24px;
    font-weight: bold;
    background-color: #1fa294;
`;

const SettingsLink = styled.span`
    color: blue;
    cursor: pointer;
    text-decoration: underline;
`;

export function AlertCameraMicrophoneDialog(props) {
    const { open, handleClose } = props;
    const url = `chrome://settings/content/siteDetails?site=${encodeURIComponent(
        window.location.origin,
    )}`;

    return (
        <Dialog
            open={!!open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{'Use Camera and Microphone?'}</DialogTitle>
            <DialogContent>
                <div id="alert-dialog-description">
                    <div>
                        In order to use the camera and microphone please click{' '}
                        <SettingsLink onClick={() => copyUtils.copyTextToClipboard(url)}>
                            here
                        </SettingsLink>
                        , open a new tab, paste and go to enable camera and microphone access.
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export function AlertPreRecordDialog(props) {
    const { open, handleClose, handleSubmit, title, submitText, content } = props;

    return (
        <Dialog
            open={!!open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={'xs'}
        >
            <DialogTitleWrapper id="alert-dialog-title">
                <CameraIconWrapper />
                {title}
            </DialogTitleWrapper>
            <DialogContent>
                <div id="alert-dialog-description">{content}</div>
            </DialogContent>
            <StyledDivider />
            <DialogActions>
                <CommonButton
                    callback={handleClose}
                    content="Cancel"
                    classType={ButtonClassType.WHITE_BORDERS}
                />
                <ButtonWrapper>
                    <CommonButton
                        callback={handleSubmit}
                        content={submitText}
                        classType={ButtonClassType.BLUE_BOLD}
                    />
                </ButtonWrapper>
            </DialogActions>
        </Dialog>
    );
}
