import React, { useEffect, useRef, useState } from 'react';
import get from 'lodash/get';
import { Button as ButtonMD } from 'react-md';
import styled from 'styled-components';

import { useVMMutation, useVMState } from '../../containers/main';
import entities from '../../constants/entities';

import ConfirmationDialog from '../common/ConfirmatonDialog';
import SwitchLiveOrPreRecording from '../header/SwitchLiveOrPreRecording';
import Button, { ButtonClassType } from '../common/Button';
import FiberManualRecordOutlined from '@mui/icons-material/FiberManualRecordOutlined';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { ContentModalContainer } from '../moderator/common/styles';
import { LightTooltip } from '../moderator/common/styles';
import { getTimer } from '../../../../utils';
import { startRecording, stopRecording } from '../../services/VirtualEventSession';
import Stack from '@mui/material/Stack';

const ButtonContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: Roboto, sans-serif;
    line-height: 1.14;
`;

const UpperCase = styled.div`
    text-transform: uppercase;
    font-family: Cabin, sans-serif;
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 1.14; !important;
    letter-spacing: 0.5px !important;
`;

const iconsStyle = {
    color: 'inherit',
    width: '16px',
    height: '16px',
    marginRight: '8px',
};

const ButtonsWrapper = styled.div`
    align-items: center;
    display: flex;

    & > *:not(:last-child) {
        margin-right: 8px;
    }
`;

const PrerecordingButtonsContainer = styled.div`
    padding: 0 8px;
`;

const CancelButton = styled.div`
    color: #1fa294;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 0 0 0 10px;
    text-align: right;
    text-decoration: underline;
`;

const StyledButton = styled(ButtonMD)`
    padding: 8px 38px 9px !important;
    border-radius: 4px !important;
    background-color: #44bb90 !important;
    color: #fff !important;
    width: 159px;
    text-transform: capitalize;
`;

const StyledModalButton = styled(ButtonMD)`
    border-radius: 6px !important;
    background-color: #1fa294 !important;
    color: #fff !important;
    font-weight: bold !important;
    font-size: 15px !important;
    width: 159px;
    white-space: nowrap;
    text-transform: capitalize !important;
`;

const StyledButtonCancel = styled(ButtonMD)`
    width: 159px;
    padding: 8px 38px 9px !important;
    border-radius: 4px !important;
    background-color: #f0f1f3 !important;
    color: #000000 !important;
    font-weight: bold !important;
    font-size: 15px !important;
    text-transform: capitalize !important;
`;

const FooterModerator = () => {
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();

    const [showRecordingModal, setShowRecordingModal] = useState(false);
    const [disableRecording, setDisableRecording] = useState(false);
    const [startsSoon, setStartsSoon] = useState(false);
    const [isRecordingHovered, setIsRecordingHovered] = useState(false);
    const [isRecordingStartedHovered, setIsRecordingStartedHovered] = useState(false);

    const [modalOpened, setModalOpened] = useState({
        noPrerecording: false,
    });
    const handleModalChange = (type, value) =>
        setModalOpened({
            ...modalOpened,
            [type]: value,
        });

    const { speakerViewMode, virtualEventUser, sessionId, prerecording, socket } = stateCtx;

    const isUserRecording = get(virtualEventUser, 'data.recordingInfo.recording', false);
    const startTimerMoment = get(virtualEventUser, 'data.recordingInfo.startTimerMoment', null);
    const isPrerecording = get(virtualEventUser, 'data.isPrerecording', false);

    const startCountDown = async callback => {
        if (!stateCtx.useDevices) {
            mutationCtx.startUsingDevices();
        }

        setShowRecordingModal(false);
        setStartsSoon(true);
        mutationCtx.setTimer({
            callback,
            time: 5,
        });

        socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                VirtualEventUsers: [
                    {
                        id: virtualEventUser.data.id,
                        isVideoOn: true,
                        isMicrophoneOn: true,
                    },
                ],
            },
        });
    };

    const startPreRecording = async () => {
        const userId = get(virtualEventUser, 'data.UserId');

        mutationCtx.setPublishShareStream(true);
        setDisableRecording(true);

        try {
            mutationCtx.startPreRecording();
            await startRecording(sessionId, {
                preRecording: true,
                userId,
            });
            setStartsSoon(false);
            setDisableRecording(false);
            socket.emit('updateData', { objectId: sessionId });
        } catch (err) {
            setStartsSoon(false);
            setDisableRecording(false);
            mutationCtx.stopPreRecording();

            handleModalChange('noPrerecording', true);
        }
    };
    const stopPreRecording = async () => {
        const userId = get(virtualEventUser, 'data.UserId');

        mutationCtx.stopPreRecording();
        mutationCtx.setPublishShareStream(false);

        setShowRecordingModal(false);
        setDisableRecording(true);
        secondsPassedRef.current = 0;
        setTime('00:00');

        await stopRecording(sessionId, { preRecording: true, userId });

        socket.emit('updateData', { objectId: sessionId });

        setDisableRecording(false);
    };

    const intervalRef = useRef(0);
    const [time, setTime] = useState('00:00');
    const secondsPassedRef = useRef(0);

    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        if (!isPrerecording || !prerecording || !isUserRecording) {
            setTime('00:00');
            secondsPassedRef.current = 0;
            return;
        }

        if (startTimerMoment) {
            intervalRef.current = setInterval(() => {
                secondsPassedRef.current = secondsPassedRef.current + 1;
                setTime(getTimer(secondsPassedRef.current * 1000));
            }, 1000);
        }

        return () => clearInterval(intervalRef.current);
    }, [prerecording, isPrerecording, isUserRecording, startTimerMoment]);

    if (
        prerecording &&
        isUserRecording &&
        speakerViewMode === entities.speakerViewMode.prerecording
    ) {
        return (
            <PrerecordingButtonsContainer>
                <ButtonsWrapper>
                    <Button
                        width={'200px'}
                        content={
                            <ButtonContent onClick={stopPreRecording}>
                                {!isRecordingStartedHovered && (
                                    <FiberManualRecordOutlined style={iconsStyle} />
                                )}
                                <UpperCase>
                                    {!isRecordingStartedHovered
                                        ? `RECORDING - ${time}`
                                        : 'Stop recording'}
                                </UpperCase>
                            </ButtonContent>
                        }
                        classType={
                            isRecordingStartedHovered
                                ? ButtonClassType.WHITE_RED_BORDERS_ROUND
                                : ButtonClassType.RED_ROUND
                        }
                        data-qa="recording-timer"
                        onMouseEnter={() => setIsRecordingStartedHovered(true)}
                        onMouseLeave={() => setIsRecordingStartedHovered(false)}
                    />
                </ButtonsWrapper>
            </PrerecordingButtonsContainer>
        );
    }

    const prerecordingMode = speakerViewMode === entities.speakerViewMode.prerecording;
    const preRecordedPresentationUrl = get(virtualEventUser, 'data.preRecordedPresentationUrl');
    const hasPreRecording = !!preRecordedPresentationUrl;
    const hasUploadedVideo = get(virtualEventUser, 'data.recordingInfo');
    const shouldDisableStopPrerecording =
        disableRecording || (prerecordingMode && (hasPreRecording || hasUploadedVideo));

    return (
        <React.Fragment>
            {!startsSoon && <SwitchLiveOrPreRecording />}
            {speakerViewMode === entities.speakerViewMode.prerecording && (
                <PrerecordingButtonsContainer>
                    {startsSoon && (
                        <ButtonsWrapper>
                            <Button
                                content={
                                    <ButtonContent>
                                        <UpperCase>Recording starts soon</UpperCase>
                                    </ButtonContent>
                                }
                                classType={ButtonClassType.RED_ROUND}
                            />
                            <CancelButton
                                deleteBtn
                                onClick={() => {
                                    mutationCtx.setTimer(null);
                                    setStartsSoon(false);
                                }}
                            >
                                Cancel
                            </CancelButton>
                        </ButtonsWrapper>
                    )}
                    {!startsSoon && (
                        <Button
                            callback={() => setShowRecordingModal(true)}
                            disabled={shouldDisableStopPrerecording}
                            width={'110px'}
                            content={
                                <LightTooltip
                                    title={
                                        'To create a new recording, delete the current one first.'
                                    }
                                    sx={{
                                        maxWidth: '163px',
                                        fontSize: '13px',
                                    }}
                                    disableHoverListener={!shouldDisableStopPrerecording}
                                >
                                    <Stack>
                                        <ButtonContent>
                                            <FiberManualRecordOutlined style={iconsStyle} />
                                            <div>Record</div>
                                        </ButtonContent>
                                    </Stack>
                                </LightTooltip>
                            }
                            classType={
                                isRecordingHovered
                                    ? ButtonClassType.WHITE_RED_BORDERS_ROUND
                                    : ButtonClassType.WHITE_ROUND
                            }
                            data-qa="start-pre-recording-button"
                            onMouseEnter={() => setIsRecordingHovered(true)}
                            onMouseLeave={() => setIsRecordingHovered(false)}
                        />
                    )}
                    <div className="eureka-react">
                        <ConfirmationDialog
                            open={modalOpened.noPrerecording}
                            title="Not able to pre-record"
                            extraStyles="padding-top: 0;"
                            content={
                                <ContentModalContainer>
                                    <ErrorOutline />
                                    <div>
                                        Something went wrong, please close the page and wait for
                                        about 1 minute before trying again.
                                    </div>
                                </ContentModalContainer>
                            }
                            buttons={[
                                <StyledButton
                                    key="PRBCO"
                                    flat
                                    onClick={() => handleModalChange('noPrerecording', false)}
                                >
                                    Got it
                                </StyledButton>,
                            ]}
                        />
                    </div>
                    {showRecordingModal && (
                        <ConfirmationDialog
                            open={showRecordingModal}
                            title="Start pre-recording"
                            titleIcon="videocam"
                            withCloseButton={true}
                            onClose={() => setShowRecordingModal(false)}
                            content={
                                <ContentModalContainer>
                                    <div>
                                        When clicking &nbsp;Start recording&nbsp;, recording will
                                        start in 5 seconds.
                                        <br />
                                        <br />
                                        Pre-recording records both your camera and your slides.
                                        Audio and camera are turned on, if they were off. You can
                                        view your recording afterwards.
                                    </div>
                                </ContentModalContainer>
                            }
                            buttons={[
                                <StyledModalButton
                                    key="PRBS"
                                    flat
                                    onClick={() => startCountDown(startPreRecording)}
                                >
                                    Start recording
                                </StyledModalButton>,
                                <StyledButtonCancel
                                    key="PRBC"
                                    flat
                                    onClick={() => setShowRecordingModal(false)}
                                >
                                    Cancel
                                </StyledButtonCancel>,
                            ]}
                        />
                    )}
                </PrerecordingButtonsContainer>
            )}
        </React.Fragment>
    );
};

export default FooterModerator;
