import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import get from 'lodash/get';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { CSSTransition } from 'react-transition-group';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import CheckIcon from '@mui/icons-material/Check';
import RestoreIcon from '@mui/icons-material/Restore';
import {
    LikesContainer,
    QuestionAndTimeContainer,
    QuestionSenderName,
    StyledFavoriteIcon,
} from '../../../../../../../VirtualSession/components/userInteraction/questionsAndAnswers/QuestionsAndAnswers';
import { calcTimeDifference } from '../../../../services/QuestionsAndAnswersService';
import Dropdown from '../../../common/Dropdown';
import { FontIcon } from 'react-md';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '../../../../../../../../components/Theme/ThemeContext';
import { useVMState } from '../../../../containers/main';
import {
    ActionButtonContainer,
    ActionsWrapper,
    AnimatedQuestionWrapper,
    LikeIconContainer,
    QuestionItemDetailsWrapper,
    StyledChip,
    Time,
    VisibilityIconWrapper,
} from './QuestionsAndAnswers';

const QuestionList = ({
    questions,
    handleDragEnd,
    getQuestionLikes,
    controls,
    onQuestionLike,
    toggleVisibility,
    markAsAnswered,
    markAsUnanswered,
    displayActions,
    onDeleteClick,
    onEditClick,
    setDisplayActions,
    onGetInfoClick,
    notDragable,
    handleMoveQuestion,
}) => {
    const { theme } = useTheme();

    const stateCtx = useVMState();
    const { virtualEventUser: currentUser } = stateCtx;

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        <List component={Stack}>
                            {questions.map((question, index) => {
                                if (!question) {
                                    return null;
                                }

                                const senderFirstName = get(question, 'User.firstName', '');
                                const senderLastName = get(question, 'User.lastName', '');
                                const senderFullName = `${senderFirstName} ${senderLastName}`;

                                const questionDate = new Date(
                                    question && question.createdAt ? question.createdAt : null,
                                );
                                const iconToDisplay = question.visible ? (
                                    displayActions === question.id ? (
                                        <VisibilityOffIcon />
                                    ) : (
                                        <VisibilityIcon />
                                    )
                                ) : displayActions === question.id ? (
                                    <VisibilityOutlinedIcon />
                                ) : (
                                    <VisibilityOffOutlinedIcon />
                                );
                                let isQuestionLikedByCurrentUser = false;
                                const questionLikes = get(question, 'QuestionLikes', []);
                                const questionLikeIndex = questionLikes.findIndex(
                                    ql => ql.UserId === currentUser.data.UserId,
                                );

                                const answeredStyle = question.answered ? { opacity: 0.5 } : {};

                                if (
                                    questionLikeIndex !== -1 &&
                                    questionLikes[questionLikeIndex].active
                                ) {
                                    isQuestionLikedByCurrentUser = true;
                                }

                                return (
                                    <Draggable
                                        key={question.id}
                                        draggableId={`draggable-${question.id}`}
                                        index={index}
                                    >
                                        {(provided, snapshot) => {
                                            const draggableProps = provided.draggableProps;
                                            const dragHandleProps = provided.dragHandleProps;

                                            return (
                                                <div
                                                    ref={provided.innerRef}
                                                    onMouseEnter={() =>
                                                        setDisplayActions(question.id)
                                                    }
                                                    onMouseLeave={() => setDisplayActions(null)}
                                                    {...draggableProps}
                                                >
                                                    <CSSTransition
                                                        key={`question-${question.id}`}
                                                        timeout={400}
                                                    >
                                                        <React.Fragment
                                                            key={`question-${question.id}`}
                                                        >
                                                            <AnimatedQuestionWrapper
                                                                key={`question-${question.id}`}
                                                                data-qa={`qa-item-${question.id}`}
                                                                backgroundColor={'#f1f1f3'}
                                                                ishovered={
                                                                    displayActions === question.id
                                                                }
                                                            >
                                                                <QuestionItemDetailsWrapper>
                                                                    {!notDragable &&
                                                                        !question.answered && (
                                                                            <IconButton
                                                                                {...dragHandleProps}
                                                                            >
                                                                                <DragIndicatorIcon
                                                                                    style={
                                                                                        answeredStyle
                                                                                    }
                                                                                />
                                                                            </IconButton>
                                                                        )}
                                                                    <LikesContainer
                                                                        data-qa={`qa-item-likes-${question.id}`}
                                                                        style={answeredStyle}
                                                                    >
                                                                        <StyledFavoriteIcon
                                                                            color={
                                                                                (question.like &&
                                                                                    question.like
                                                                                        .active &&
                                                                                    '#000000') ||
                                                                                'rgba(0, 0, 0, 0.87)'
                                                                            }
                                                                        >
                                                                            {'favorite'}
                                                                        </StyledFavoriteIcon>
                                                                        {getQuestionLikes(question)}
                                                                    </LikesContainer>
                                                                    <QuestionAndTimeContainer>
                                                                        <QuestionItemDetailsWrapper>
                                                                            <QuestionSenderName
                                                                                style={
                                                                                    answeredStyle
                                                                                }
                                                                            >
                                                                                {senderFullName}
                                                                            </QuestionSenderName>
                                                                            <Time
                                                                                style={
                                                                                    answeredStyle
                                                                                }
                                                                            >
                                                                                {calcTimeDifference(
                                                                                    questionDate,
                                                                                )}
                                                                            </Time>
                                                                            {question.new &&
                                                                                displayActions !==
                                                                                    question.id && (
                                                                                    <StyledChip
                                                                                        primary={
                                                                                            theme.contrast
                                                                                        }
                                                                                        color="#e98b35"
                                                                                    >
                                                                                        New
                                                                                    </StyledChip>
                                                                                )}
                                                                            {question.answered &&
                                                                                displayActions !==
                                                                                    question.id && (
                                                                                    <StyledChip
                                                                                        primary={
                                                                                            theme.contrast
                                                                                        }
                                                                                        color="#0cb464"
                                                                                    >
                                                                                        Answered
                                                                                    </StyledChip>
                                                                                )}
                                                                            {question.visible &&
                                                                                !question.answered &&
                                                                                !question.new &&
                                                                                displayActions !==
                                                                                    question.id && (
                                                                                    <StyledChip
                                                                                        primary={
                                                                                            theme.contrast
                                                                                        }
                                                                                        color="#8341ef"
                                                                                    >
                                                                                        Visible
                                                                                    </StyledChip>
                                                                                )}
                                                                        </QuestionItemDetailsWrapper>
                                                                        <div style={answeredStyle}>
                                                                            {question.question}
                                                                        </div>
                                                                    </QuestionAndTimeContainer>
                                                                    {controls &&
                                                                        displayActions ===
                                                                            question.id && (
                                                                            <ActionButtonContainer
                                                                                new={
                                                                                    question.newQuestion
                                                                                }
                                                                            >
                                                                                {!(
                                                                                    question.answered &&
                                                                                    !question.visible
                                                                                ) && (
                                                                                    <Tooltip
                                                                                        title={
                                                                                            question.visible
                                                                                                ? 'Click to make invisible'
                                                                                                : 'Click to make visible'
                                                                                        }
                                                                                        arrow
                                                                                    >
                                                                                        <Stack
                                                                                            style={
                                                                                                answeredStyle
                                                                                            }
                                                                                        >
                                                                                            <VisibilityIconWrapper
                                                                                                onClick={() =>
                                                                                                    toggleVisibility(
                                                                                                        question,
                                                                                                    )
                                                                                                }
                                                                                                data-qa={`${
                                                                                                    question.visible
                                                                                                        ? 'disable'
                                                                                                        : 'enable'
                                                                                                }-qa-item-visibility-${
                                                                                                    question.id
                                                                                                }`}
                                                                                                color={
                                                                                                    displayActions ===
                                                                                                        question.id ||
                                                                                                    question.visible
                                                                                                        ? 'rgba(0, 0, 0, 0.87)'
                                                                                                        : 'rgba(0, 0, 0, 0.54)'
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    iconToDisplay
                                                                                                }
                                                                                            </VisibilityIconWrapper>
                                                                                        </Stack>
                                                                                    </Tooltip>
                                                                                )}
                                                                                {!question.answered && (
                                                                                    <Tooltip
                                                                                        title="Mark as answered"
                                                                                        arrow
                                                                                    >
                                                                                        <Stack
                                                                                            style={
                                                                                                answeredStyle
                                                                                            }
                                                                                        >
                                                                                            <VisibilityIconWrapper
                                                                                                onClick={() =>
                                                                                                    markAsAnswered(
                                                                                                        question,
                                                                                                    )
                                                                                                }
                                                                                                data-qa={`${
                                                                                                    question.answered
                                                                                                        ? 'answered'
                                                                                                        : 'un-answered'
                                                                                                }-qa-item-${
                                                                                                    question.id
                                                                                                }`}
                                                                                            >
                                                                                                <CheckIcon />
                                                                                            </VisibilityIconWrapper>
                                                                                        </Stack>
                                                                                    </Tooltip>
                                                                                )}
                                                                                {question.answered && (
                                                                                    <Tooltip
                                                                                        title="Mark as unanswered"
                                                                                        arrow
                                                                                    >
                                                                                        <Stack
                                                                                            style={
                                                                                                answeredStyle
                                                                                            }
                                                                                        >
                                                                                            <VisibilityIconWrapper
                                                                                                onClick={() =>
                                                                                                    markAsUnanswered(
                                                                                                        question,
                                                                                                    )
                                                                                                }
                                                                                                data-qa={`${
                                                                                                    question.answered
                                                                                                        ? 'answered'
                                                                                                        : 'un-answered'
                                                                                                }-qa-item-${
                                                                                                    question.id
                                                                                                }`}
                                                                                            >
                                                                                                <RestoreIcon />
                                                                                            </VisibilityIconWrapper>
                                                                                        </Stack>
                                                                                    </Tooltip>
                                                                                )}
                                                                                <ActionsWrapper>
                                                                                    <Dropdown
                                                                                        customPosition={
                                                                                            'top'
                                                                                        }
                                                                                        zIndex={10}
                                                                                        items={[
                                                                                            {
                                                                                                text:
                                                                                                    'Edit',
                                                                                                icon:
                                                                                                    'edit',
                                                                                                onClick: e =>
                                                                                                    onEditClick(
                                                                                                        e,
                                                                                                        question,
                                                                                                    ),
                                                                                            },
                                                                                            question.visible &&
                                                                                            !question.answered
                                                                                                ? {
                                                                                                      text:
                                                                                                          'Move up',
                                                                                                      icon:
                                                                                                          'arrow_upward',
                                                                                                      onClick: () =>
                                                                                                          handleMoveQuestion(
                                                                                                              question,
                                                                                                              false,
                                                                                                          ),
                                                                                                      disabled:
                                                                                                          index ===
                                                                                                          0,
                                                                                                  }
                                                                                                : null,
                                                                                            question.visible &&
                                                                                            !question.answered
                                                                                                ? {
                                                                                                      text:
                                                                                                          'Move down',
                                                                                                      icon:
                                                                                                          'arrow_downward',
                                                                                                      onClick: () =>
                                                                                                          handleMoveQuestion(
                                                                                                              question,
                                                                                                              true,
                                                                                                          ),
                                                                                                      disabled:
                                                                                                          index ===
                                                                                                              questions.length -
                                                                                                                  1 ||
                                                                                                          (questions[
                                                                                                              index +
                                                                                                                  1
                                                                                                          ] &&
                                                                                                              questions[
                                                                                                                  index +
                                                                                                                      1
                                                                                                              ]
                                                                                                                  .answered),
                                                                                                  }
                                                                                                : null,
                                                                                            {
                                                                                                text:
                                                                                                    'Get info',
                                                                                                icon:
                                                                                                    'info',
                                                                                                onClick: e =>
                                                                                                    onGetInfoClick(
                                                                                                        e,
                                                                                                        question,
                                                                                                    ),
                                                                                            },
                                                                                            {
                                                                                                divider: true,
                                                                                            },

                                                                                            {
                                                                                                text:
                                                                                                    'Delete',
                                                                                                icon:
                                                                                                    'delete',
                                                                                                onClick: e =>
                                                                                                    onDeleteClick(
                                                                                                        e,
                                                                                                        question,
                                                                                                    ),
                                                                                            },
                                                                                        ].filter(
                                                                                            o =>
                                                                                                !!o,
                                                                                        )}
                                                                                    />
                                                                                </ActionsWrapper>
                                                                            </ActionButtonContainer>
                                                                        )}
                                                                    {!controls &&
                                                                        displayActions ===
                                                                            question.id && (
                                                                            <ActionButtonContainer>
                                                                                <LikeIconContainer
                                                                                    onClick={() =>
                                                                                        controls
                                                                                            ? null
                                                                                            : onQuestionLike(
                                                                                                  question,
                                                                                              )
                                                                                    }
                                                                                >
                                                                                    <FontIcon>
                                                                                        {isQuestionLikedByCurrentUser
                                                                                            ? 'favorite'
                                                                                            : 'favorite_border'}
                                                                                    </FontIcon>
                                                                                </LikeIconContainer>
                                                                            </ActionButtonContainer>
                                                                        )}
                                                                </QuestionItemDetailsWrapper>
                                                            </AnimatedQuestionWrapper>
                                                        </React.Fragment>
                                                    </CSSTransition>
                                                </div>
                                            );
                                        }}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </List>
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default QuestionList;
