import React, { useState } from 'react';
import styled from 'styled-components';
import CheckIcon from '@mui/icons-material/Check';
import { Flex } from '../../../../../scenes/common/Flex';
import { useVMState } from '../../virtualFeature/containers/main';
import { CircularProgress } from '@mui/material';
import { useTheme } from '../../../../../components/Theme/ThemeContext';

const QuestionWrapper = styled.div`
    padding: 18px;
    background-color: #ffffff;
    position: relative;
    cursor: ${props => (props.active ? 'default' : 'pointer')};
    border-radius: 8px;

    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }
`;

const StyledCheckmarkIcon = styled(CheckIcon)`
    color: #fff;
    font-size: 16px !important;
`;

const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const QuestionText = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
`;

const NameText = styled.div`
    height: 15px;
    font-family: 'Cabin', sans-serif;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    margin-right: 12px;
`;

const List = styled.ol`
    margin: 16px 0 0 80px;
    padding: 0;
`;

const ListItem = styled.li`
    height: 16px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.54);
`;

const ActionWrapper = styled.div`
    position: relative;
    top: -10px;
`;

const IndexContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    margin-right: 26px;
    border-radius: 50%;
    font-family: Cabin, sans-serif;
    font-size: 15px !important;
    font-weight: 500; !important;
    background-color: ${props => (props.active ? props.color ?? '#1fa294' : '#fff')};
    color: ${props => (props.active ? 'rgba(255, 255, 255, 0.87)' : 'rgba(0, 0, 0, 0.87)')};
    border: 2px solid ${props => (props.active ? props.color ?? '#1fa294' : 'rgba(0, 0, 0, 0.6)')};
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

const ResultsText = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 13px;
    line-height: 1.54;
    color: rgba(0, 0, 0, 0.6);
`;

const Question = ({ onClick, question, actionComponent }) => {
    const stateCtx = useVMState();
    const { active: remoteActive, isExpanded, options, user, text, resultsNumber, id } = question;
    const { activePoll } = stateCtx;
    const optionsList = options.map(op => <ListItem key={op}>{op}</ListItem>);
    const [isHovered, setIsHovered] = useState(false);
    const localActive = activePoll && activePoll.id && activePoll.id === id;
    const active = remoteActive && localActive;

    const { theme } = useTheme();

    const onQuestionClick = () => {
        if (active) {
            return;
        }

        onClick();
    };

    return (
        <QuestionWrapper
            onClick={onQuestionClick}
            active={active}
            onMouseEnter={() => {
                setIsHovered(true);
            }}
            onMouseLeave={() => {
                setIsHovered(false);
            }}
        >
            <FlexContainer>
                <FlexRow>
                    <IndexContainer active={active} color={theme.primary}>
                        {active && <StyledCheckmarkIcon />}
                        {!localActive && remoteActive && <CircularProgress size={10} />}
                    </IndexContainer>
                    <TextContainer>
                        <Flex>
                            {user && <NameText>{`${user.firstName} ${user.lastName}`}</NameText>}
                            {resultsNumber === 0 && <ResultsText>No results</ResultsText>}
                            {resultsNumber === 1 && <ResultsText>{resultsNumber} vote</ResultsText>}
                            {resultsNumber > 1 && <ResultsText>{resultsNumber} votes</ResultsText>}
                        </Flex>
                        <QuestionText>{text}</QuestionText>
                    </TextContainer>
                </FlexRow>
                {isHovered && <ActionWrapper>{actionComponent}</ActionWrapper>}
            </FlexContainer>
            {isExpanded && <List>{optionsList}</List>}
        </QuestionWrapper>
    );
};

export default Question;
