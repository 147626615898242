import React, { useState } from 'react';
import { CSVDownload } from 'react-csv';
import Button from '@mui/material/Button';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';

import { downloadRoomHistory } from '../../../../../Talk/api/eureka';

const EXPORT_CHAT_BUTTON = 'Export chat';

const buttonStyle = {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    width: 'calc(100% - 56px)',
    marginLeft: '56px',
    textTransform: 'none',
    marginTop: '12px',
    marginBottom: '16px',
};

const ExportChat = ({ sessionId }) => {
    const [csvData, setCsvData] = useState(null);
    const [chatExported, setChatExported] = useState(false);
    const [exportingChat, setExportingChat] = useState(false);

    const onExportChat = async () => {
        setExportingChat(true);
        setChatExported(false);
        setCsvData(null);

        const data = await downloadRoomHistory(sessionId);
        if (!data || data.length === 0) {
            setExportingChat(false);
            return;
        }

        // CSV header format
        const headers = [
            { label: 'Date', key: 'date' },
            { label: 'First Name', key: 'firstName' },
            { label: 'Last Name', key: 'lastName' },
            { label: 'Email', key: 'email' },
            { label: 'Message content', key: 'text' },
        ];
        setCsvData({ headers, data });
        setChatExported(true);
        /*
            CSVDownload component will only download on mount
            so user will have to close and open this settingsModal to download it again.
            That's why setExportingChat will remain true making the button look disabled
        */
        // setExportingChat(false);
    };

    return (
        <>
            <Button
                variant="contained"
                disabled={exportingChat}
                startIcon={<GetAppRoundedIcon />}
                disableElevation
                style={buttonStyle}
                onClick={onExportChat}
            >
                {EXPORT_CHAT_BUTTON}
            </Button>
            {chatExported && <CSVDownload headers={csvData.headers} data={csvData.data} />}
        </>
    );
};

export default ExportChat;
