import React from 'react';
import Switch from '@mui/material/Switch';
import { createTheme, FormControlLabel, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { useTheme } from '../../../../../../components/Theme/ThemeContext';

const MaterialSwitchButton = ({ label, checked, onChange, name }) => {
    const { theme } = useTheme();
    const switchTheme = createTheme({
        overrides: {
            MuiSwitch: {
                switchBase: {
                    // Controls default (unchecked) color for the thumb
                    color: '#ccc',
                },
                colorSecondary: {
                    '&$checked': {
                        // Controls checked color for the thumb
                        color: theme.primary || '#1fa294',
                    },
                },
                track: {
                    // Controls default (unchecked) color for the track
                    backgroundColor: '#b3b3b3',
                    '$checked$checked + &': {
                        // Controls checked color for the track
                        opacity: 0.5,
                        backgroundColor: theme.primary || '#1fa294',
                    },
                },
            },
        },
    });

    return (
        <MuiThemeProvider theme={switchTheme}>
            <FormControlLabel
                label={label}
                control={
                    <Switch
                        id={`switch-for-${Date.now()}`}
                        checked={checked}
                        onChange={onChange}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                onChange(e);
                            }
                        }}
                        name={name}
                        edge="start"
                    />
                }
            ></FormControlLabel>
        </MuiThemeProvider>
    );
};

export default MaterialSwitchButton;
